import { of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  IAuthenticationLogoutResponse,
  IAuthenticationResponse,
  IDeviceSetting,
  IGlobalSetting,
  IAuthenticationData,
} from './authentication.response.interface';
import {
  EFreeTierFlow,
  IClientConfiguration,
  IHttpRequestConfig,
  IProviderDescriptor,
} from '../index';
import { addProvider } from '../service';
import { StandardAuth } from './standard.auth';
import { StandardAlternateAuth } from './standard-alternate.auth';
import { Logger } from '../logger';
import { ApiCodes } from '../service/consts';
import { HttpProvider, ModuleRequest } from '../http';
import { ServiceEndpointConstants } from '../service/consts';
import { NuDetectPayload } from './authentication.constants';

export class AuthenticationResponse implements IAuthenticationResponse {
  deviceSettingList: Array<IDeviceSetting>;
  globalSettingList: Array<IGlobalSetting>;
  authenticationData: IAuthenticationData;
  clientConfiguration: IClientConfiguration;

  constructor(response: any) {
    this.deviceSettingList = response.deviceSettingList
      ? response.deviceSettingList.deviceSettings
      : [];
    this.globalSettingList = response.globalSettingList
      ? response.globalSettingList.globalSettings
      : [];
    this.authenticationData = response.authenticationData;
    this.clientConfiguration = response.clientConfiguration;
  }
}

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  AuthenticationDelegate interacts with HttpProvider to get API responses.
 */
export class AuthenticationDelegate {
  /**
   *
   * @type logger- Gets logger instance for AuthenticationDelegate
   */
  private static logger: Logger = Logger.getLogger('AuthenticationDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AuthenticationDelegate, AuthenticationDelegate, [
      HttpProvider,
    ]);
  })();

  /**
   * Constructor
   * @param  http - Used to make API calls
   */
  constructor(private http: HttpProvider) {}

  /**
   * Make an api call to login the user the supplied credentials
   * @param username
   * @param password
   * @returns An observable that allows subscription to get response for the login call
   */
  public login(
    username: string,
    password: string,
    screenFlow: EFreeTierFlow,
    nuDetectPayload?: NuDetectPayload,
  ): Observable<IAuthenticationResponse> {
    AuthenticationDelegate.logger.debug(`login( username = ${username} )`);

    const request = new StandardAuth(
      username,
      password,
      screenFlow,
      nuDetectPayload,
    );

    return this.http
      .postModuleRequest(
        ServiceEndpointConstants.endpoints.AUTHENTICATION.V2_LOGIN_PARTNER,
        request,
      )
      .pipe(
        map((response: any) => {
          AuthenticationDelegate.logger.debug(
            `loginResult( username = ${username} )`,
          );
          return new AuthenticationResponse(response);
        }),
      );
  }

  /**
   * Make an api call to logout the user with supplied credentials
   * @param appExit used to indicate the application exited or not
   * @returns An observable that allows subscription to get response for the logout call
   */
  public logout(appExit: boolean): Observable<IAuthenticationLogoutResponse> {
    AuthenticationDelegate.logger.debug(`logout`);

    const config: IHttpRequestConfig = {
      params: {
        logout: appExit === true ? false : true,
        time: new Date().getTime(),
      },
    } as IHttpRequestConfig;

    return this.http
      .get(
        ServiceEndpointConstants.endpoints.AUTHENTICATION.V2_LOGOUT,
        null,
        config,
      )
      .pipe(
        map((response: any) => {
          AuthenticationDelegate.logger.debug(`logoutResult`);
          response.isLogout = true;
          return response;
        }),
        catchError(response => {
          if (response && response.code === ApiCodes.AUTH_REQUIRED) {
            response.isLogout = true;
            return observableOf(response);
          }

          throw response;
        }),
      );
  }

  /**
   * Makes an api call to create an activation code for the alternate login flow
   * @returns An observable that allows subscription to get response for the activation code creation call
   */
  public createAlternateLogin(): Observable<IAuthenticationResponse> {
    const request = new ModuleRequest();

    return this.http
      .postModuleRequest(
        ServiceEndpointConstants.endpoints.AUTHENTICATION
          .V4_CREATE_ALTERNATE_LOGIN,
        request,
      )
      .pipe(
        map((response: any) => {
          return new AuthenticationResponse(response);
        }),
      );
  }

  /**
   * Makes an api call to verify if the authentication process associated with the activation code provided has completed
   * @returns An observable that allows subscription to get response for the activation code completion call
   */
  public completeAlternateLogin(
    regCode: string,
  ): Observable<IAuthenticationResponse> {
    const request = new StandardAlternateAuth(regCode);

    return this.http
      .postModuleRequest(
        ServiceEndpointConstants.endpoints.AUTHENTICATION
          .V4_COMPLETE_ALTERNATE_LOGIN,
        request,
      )
      .pipe(
        map((response: any) => {
          return new AuthenticationResponse(response);
        }),
      );
  }
}
