import React, { useEffect, useState } from 'react';
import { combineLatest, timer, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { useHistory } from 'react-router-dom';
import xdk, { device } from '@accedo/xdk-core';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import {
  ServiceFactory,
  InitializationService,
  InitializationStatusCodes,
  ResumeService,
  AppMonitorService,
  AuthenticationService,
  ConfigService,
  SessionMonitorService,
  Logger,
  StorageService,
  StorageKeyConstant,
} from '../../servicelib';
import './splash-page.component.scss';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import { SXMServiceLayerModule } from '../../sxmservicelayer/sxm.service.layer.module';
import { BrowserUtil } from '../../utils/browser.util';
import { zoneCategory } from '../../components/navigation-menu/navigation-menu-utils';
import { NeriticLinkService } from '../../sxmservicelayer/neritic-links';
import { storageManager } from '../../config/storage-manager';
import { systemManager } from '../../config/system-manager';
import axios from 'axios';

// declare const $badger: any;
const OA_TRIAL_EXPIRED = 'Authentication Required-OpenAccess-PreviouslyOA';
const payload = {
  moduleList: {
    modules: [
      {
        moduleType: 'PartnerDevices',
        moduleRequest: {
          deviceInfo: {
            osVersion: 'Vizio',
            sxmAppVersion: process.env.REACT_APP_VERSION,
            oem: 'Vizio',
            clientDeviceId: '',
            deviceModel: 'xxxx',
            platform: 'Home',
            mobileCarrier: '',
            deviceMake: 'vizioeverest',
          },
        },
        moduleArea: 'Routing',
      },
    ],
  },
};
const DEEPLINKING_PARAMETERS = {
  LAUNCHPOINT: 'launchpoint',
  QUERY: 'query',
  ASSET_ID: 'asstedId',
  ASSET_TYPE: 'assetType',
  ENTITY_ID: 'entityId',
  SECTION_NAME: 'sectionName',
  LIMITED_AD_TRACKING: 'lmt',
  US_PRIVACY: 'us_privacy',
};
const LAUNCHPOINT_VALUES = {
  HOME: 'home',
  DETAIL: 'detail',
  PLAYBACK: 'playback',
  SEARCH: 'search',
  SECTION: 'section',
};

const useSplashPageComponent = shouldDismissSplashScreen => {
  const history = useHistory();

  const [isStorageManagerLoaded, setIsStorageManagerLoaded] = useState(false);
  useEffect(() => {
    xdk.load().then(response => {
      const platform = response.getId();
      device.storageManager
        .getStorage(platform === 'lg-webos' ? 'local' : 'cookie')
        .then(storage => {
          systemManager.system = xdk.system;
          payload.moduleList.modules[0].moduleRequest.deviceInfo.osVersion =
            'Vizio ' +
            (xdk.system.getFirmwareVersion && xdk.system.getFirmwareVersion());
          payload.moduleList.modules[0].moduleRequest.deviceInfo.deviceModel =
            xdk.system.getModel && xdk.system.getModel();

          storageManager.cloudCookieManager = storage;
          setIsStorageManagerLoaded(true);
        });
    });
  }, []);

  //TODO: The default device id must be set as '' to ensure it is retrieved first once app can be tested on devices
  const [clientDeviceId, setClientDeviceId] = useState('');
  useEffect(() => {
    if (isStorageManagerLoaded) {
      if (!!navigator && !!navigator.userAgent) {
        const language = navigator.language || '';
        let [, appRegion] = language.split('-');
        appRegion = (appRegion || '').toUpperCase();
        storageManager.cloudCookieManager.set(
          StorageKeyConstant.APP_REGION,
          appRegion === 'CA' ? appRegion : 'US',
        );

        if ((window as any).VIZIO) {
          (window as any).VIZIO.getDeviceId(deviceId => {
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.CLIENT_DEVICE_ID,
              deviceId,
            );

            setClientDeviceId(deviceId);
          });
        } else {
          setClientDeviceId('THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie');
        }
      } else {
        setClientDeviceId('THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie');
      }
    }
  }, [isStorageManagerLoaded]);

  const [isHostRoutingComplete, setIsHostRoutingComplete] = useState(false);
  useEffect(() => {
    if (clientDeviceId) {
      payload.moduleList.modules[0].moduleRequest.deviceInfo.clientDeviceId = clientDeviceId;

      axios(`${BrowserUtil.hostname}/rest/v2/experience/modules/get/partner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(payload),
      })
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(response => {
          let routingUrl;
          try {
            routingUrl =
              response['ModuleListResponse'].moduleList.modules[0]
                .moduleResponse.partnerDeviceList.partnerDevices[0].routingUrl;
          } catch (error) {
            routingUrl = BrowserUtil.getApiEndpoint();
          } finally {
            BrowserUtil.hostname = routingUrl;
            SXMServiceLayerModule.injectAppConfig();
            setIsHostRoutingComplete(true);
          }
        });
    }
  }, [clientDeviceId]);

  //Imports the services that are used at application boot so their instances can be created
  //and the initialization process gets triggered.
  let initializationService;
  let resumeService;
  let appMonitorService;
  let authenticationService;
  let configService;
  let sessionMonitorService;
  let carouselTileService;
  if (isHostRoutingComplete) {
    Logger.isEnabled = true;
    Logger.level = Logger.LEVEL_ALL;
    initializationService = ServiceFactory.getInstance(
      InitializationService,
    ) as InitializationService;
    resumeService = ServiceFactory.getInstance(ResumeService) as ResumeService;
    appMonitorService = ServiceFactory.getInstance(
      AppMonitorService,
    ) as AppMonitorService;
    authenticationService = ServiceFactory.getInstance(
      AuthenticationService,
    ) as AuthenticationService;
    configService = ServiceFactory.getInstance(ConfigService) as ConfigService;
    sessionMonitorService = ServiceFactory.getInstance(
      SessionMonitorService,
    ) as SessionMonitorService;
    carouselTileService = ServiceFactory.getInstance(CarouselTileService);
  }

  useEffect(() => {
    if (
      initializationService &&
      resumeService &&
      appMonitorService &&
      authenticationService &&
      configService &&
      sessionMonitorService &&
      carouselTileService
    ) {
      let sessionMessage: any = {};
      const sessionMessageSubscription = sessionMonitorService.sessionMessageSubject$.subscribe(
        value => {
          sessionMessage = value;
        },
      );

      const subscription = combineLatest({
        isResumeComplete: resumeService.resumeIsComplete$,
        initState: initializationService.initState,
        delay: timer(1000),
      })
        .pipe(
          switchMap(({ isResumeComplete, initState }) => {
            if (
              isResumeComplete === true &&
              (initState === InitializationStatusCodes.UNAUTHENTICATED ||
                initState === InitializationStatusCodes.RUNNING)
            ) {
              return of(sessionMessage);
            } else if (
              isResumeComplete === false &&
              initState === InitializationStatusCodes.UNAUTHENTICATED
            ) {
              //Means resume still needs to be done for the first time to determine if OpenAccess is available or not.
              return resumeService.resume();
            }
          }),
          catchError((err, caught) => caught),
        )
        .subscribe((result: any) => {
          if (result && result.message === OA_TRIAL_EXPIRED) {
            history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
          } else {
            const launchpoint = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.LAUNCHPOINT,
            );
            const entityId = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.ENTITY_ID,
            );
            const assetId = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.ASSET_ID,
            );
            const query = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.QUERY,
            );
            const sectionName = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.SECTION_NAME,
            );

            if (!launchpoint || launchpoint === LAUNCHPOINT_VALUES.HOME) {
              history.replace(appRouteConstants.FT_WELCOME);
            } else if (launchpoint === LAUNCHPOINT_VALUES.DETAIL && entityId) {
              //It is assumed that this will be an on-demand episode listing screen
              const subpath = decodeURIComponent(entityId);
              history.replace(
                `${appRouteConstants.ON_DEMAND.EPISODES_LIST}/${subpath}`,
              );
            } else if (launchpoint === LAUNCHPOINT_VALUES.PLAYBACK && assetId) {
              //It is assumed that assetId will include the encoded neritic link data
              const neriticLinkDataString = decodeURIComponent(assetId);
              let neriticLinkData: any = {};
              neriticLinkDataString.split('&').forEach(param => {
                const [key, value] = param.split('=');
                neriticLinkData = {
                  ...neriticLinkData,
                  [key]: value,
                };
              });

              history.replace(appRouteConstants.HOME_FORYOU);
              const neriticLinkService = ServiceFactory.getInstance(
                NeriticLinkService,
              ) as NeriticLinkService;

              const options: any = {
                onSuccess: () => {
                  const storageService = ServiceFactory.getInstance(
                    StorageService,
                  ) as StorageService;
                  storageService.setItem(
                    StorageKeyConstant.IS_DEEPLINKED,
                    true,
                  );
                },
              };
              neriticLinkService.tuneNeriticAction(
                neriticLinkData,
                null,
                options,
              );
            } else if (launchpoint === LAUNCHPOINT_VALUES.SEARCH && query) {
              history.replace(`${appRouteConstants.SEARCH}/${query}`);
            } else if (
              launchpoint === LAUNCHPOINT_VALUES.SECTION &&
              sectionName
            ) {
              const superCategory = zoneCategory.discoverZone.buttons.some(
                button => button.superCategory === sectionName,
              );

              if (superCategory) {
                if (
                  sectionName === 'all_podcasts' ||
                  sectionName == 'all_video'
                ) {
                  history.replace(
                    `${appRouteConstants.CATEGORY_PAGE}/${sectionName}`,
                  );
                } else {
                  history.replace(`${appRouteConstants.HOME}/${sectionName}`);
                }
              } else {
                history.replace(appRouteConstants.HOME_FORYOU);
              }
            } else {
              history.replace(appRouteConstants.FT_WELCOME);
            }
          }

          // $badger.dismissLoadingScreen();
          shouldDismissSplashScreen(true);
        });

      return () => {
        subscription.unsubscribe();
        sessionMessageSubscription.unsubscribe();
      };
    }
  }, [
    initializationService,
    resumeService,
    appMonitorService,
    authenticationService,
    configService,
    carouselTileService,
  ]);
};

export const SplashPageComponent = props => {
  useSplashPageComponent(props.shouldDismissSplashScreen);

  return (
    <div className="splash-page">
      <div className="splash-logo-container">
        <div>
          <SiriusXMLogo />
          <div className="wave-cover" />
        </div>
      </div>
      <span className="gotham-black"> </span>
      <span className="gotham-medium"> </span>
      <span className="gotham-bold"> </span>
      <span className="gotham-book"> </span>
      <span className="gotham-narrow-ssm-book"> </span>
      <span className="gotham-narrow-ssm-medium"> </span>
      <span className="gotham-narrow-ssm-black"> </span>
    </div>
  );
};
