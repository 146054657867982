import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const RelatedTab = props => {
  const { selectedTab, data, ...rest } = props;
  const theme = {
    button: 'related-tab',
    buttonFocused: 'related-tab-focused',
  };

  if (selectedTab === data.__index) {
    theme.button += ' related-tab-selected';
  }

  return (
    <FocusButton theme={theme} {...rest} onFocus={() => ttsPlayback(data.text)}>
      {data.text}
      {selectedTab === data.__index && <span className="underline"></span>}
    </FocusButton>
  );
};

export default RelatedTab;
