import React from 'react';
import './sticky-navbar.component.scss';

type Props = {
  children?: React.ReactNode | string;
  isCoreMenuOpen?: boolean;
};

const StickyNavbar = ({ children, isCoreMenuOpen } : Props) => {
  return (
    <>
      <div className="sticky-navbar-wrapper">
        <div className={`sticky-navbar ${isCoreMenuOpen === false ? 'core-menu-closed' : ''}`}>
          <div className="sticky-navbar-children">{children}</div>
        </div>
      </div>
      <div className="sticky-navbar-placeholder"></div>
    </>
  );
};

export default StickyNavbar;
