import { IProviderDescriptor } from '../service/provider.descriptor.interface';
import { addProvider } from '../service/providerDescriptors';
import { DmcaService } from '../dmca/dmca.service';
import { IMediaItem } from '../tune/tune.interface';
import { MediaUtil } from '../mediaplayer/media.util';
import { MediaPlayerService } from '../mediaplayer/media-player.service';
import { MediaTimestampService } from '../mediaplayer/media-timestamp.service';
import { SeekService } from '../seek/seek.service';
import { IDmcaInfoItem } from '../dmca/dmca.interface';

export class SkipService {
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SkipService, SkipService, [
      MediaTimestampService,
      MediaPlayerService,
      SeekService,
      DmcaService,
    ]);
  })();

  constructor(
    private mediaTimestampService: MediaTimestampService,
    private mediaPlayerService: MediaPlayerService,
    private seekService: SeekService,
    private dmcaService: DmcaService,
  ) {}

  /**
   * Returns a list of markers that come before or equal to the playhead in time.
   * @param items - list of media items
   */
  private getUpcomingItems(items: IMediaItem[]): IMediaItem[] {
    return items.filter(item => {
      return (
        item.times.zuluStartTime >
        this.mediaTimestampService.playhead.currentTime.zuluMilliseconds
      );
    });
  }

  /**
   * Returns a list of markers that come after the playhead in time.
   * @param items - list of media items
   */
  private getPreviousItems(items: IMediaItem[]): IMediaItem[] {
    return items.filter(item => {
      return (
        item.times.zuluStartTime <=
        this.mediaTimestampService.playhead.currentTime.zuluMilliseconds
      );
    });
  }

  /**
   * Seeks to the next marker. If there are no markers in the future and the live timestamp exists
   * play it, otherwise play 1 second from the end of the episode.
   * Note: AIC skip - skips to next track using track id.
   * @param dmcaInfo - DmcaInfoItem
   */
  public skipForward(
    dmcaInfo: IDmcaInfoItem,
    livePointToSeekTo: number = 0,
  ): void {
    if (MediaUtil.isMultiTrackAudioMediaType(dmcaInfo.mediaType)) {
      return this.mediaPlayerService.mediaPlayer.skip();
    }

    let markers: IMediaItem[] = [];
    const isLive = MediaUtil.isLiveMediaType(
      this.mediaPlayerService.mediaPlayer.mediaType,
    );

    if (this.dmcaService.isUnrestricted(dmcaInfo)) {
      markers = this.mediaTimestampService.getSegmentMarkers() || [];
    } else {
      markers = this.mediaTimestampService.getCutMarkers() || [];
    }

    const upcomingMarkers = this.getUpcomingItems(markers);

    // The final time to seek to.
    let seekTime: number = 0;

    if (upcomingMarkers.length) {
      // We cannot seek past the live time, so make the next segment the live time
      // if it's past the live time.
      const marker: IMediaItem = upcomingMarkers[0];
      let isMarkerPastLive: boolean =
        marker.times.zuluStartTime >=
        this.mediaTimestampService.livePointTimestampZulu;

      if (isMarkerPastLive) {
        if (isLive) {
          seekTime = livePointToSeekTo;
        } else {
          seekTime = this.mediaTimestampService.livePointTimestampZulu;
        }
      } else {
        if (isLive) {
          seekTime =
            this.mediaTimestampService.livePointTimestampZulu -
            marker.times.zuluStartTime;
          seekTime = livePointToSeekTo - seekTime / 1000;
        } else {
          seekTime = marker.times.zuluStartTime;
        }
      }
    } else {
      // No upcoming segment markers exist. If we're playing live, then seek to the live point, otherwise
      // seek to end of the episode.
      if (this.mediaTimestampService.livePointTimestampZulu && isLive) {
        seekTime = livePointToSeekTo;
      } else {
        seekTime = this.mediaTimestampService.durationTimestamp;
      }
    }
    this.seekService.seekThenPlay(seekTime, false, isLive);
  }

  /**
   * Handles button clicks for "skip back".
   * Seeks to the previous segment marker, if one exists.
   * Otherwise, seek to the beginning of the audio/video.
   * Note: AIC Skip back - Always seek to the beginning of the track.
   * @param dmcaInfo - DmcaInfoItem
   */
  public skipBack(
    dmcaInfo: IDmcaInfoItem,
    livePointToSeekTo: number = 0,
  ): void {
    if (MediaUtil.isSeededRadioMediaType(dmcaInfo.mediaType)) {
      return;
    }
    if (MediaUtil.isMultiTrackAudioMediaType(dmcaInfo.mediaType)) {
      return this.mediaPlayerService.mediaPlayer.rewind();
    }

    let markers: IMediaItem[] = [];
    const isLive = MediaUtil.isLiveMediaType(
      this.mediaPlayerService.mediaPlayer.mediaType,
    );

    if (this.dmcaService.isUnrestricted(dmcaInfo)) {
      markers = this.mediaTimestampService.getSegmentMarkers() || [];
    } else {
      markers = this.mediaTimestampService.getCutMarkers() || [];
    }

    const previousMarkers = this.getPreviousItems(markers);

    // The final time to seek to...default to zero so the farthest point in time back is the beginning of the episode.
    let seekTime: number = 0;

    if (previousMarkers.length) {
      previousMarkers.sort(
        (a, b) => b.times.zuluStartTime - a.times.zuluStartTime,
      );

      if (
        previousMarkers[1] &&
        this.mediaTimestampService.playhead.currentTime.zuluMilliseconds -
          previousMarkers[0].times.zuluStartTime <=
          10000
      ) {
        if (isLive) {
          seekTime =
            this.mediaTimestampService.livePointTimestampZulu -
            previousMarkers[1].times.zuluStartTime;
          seekTime = livePointToSeekTo - seekTime / 1000;
        } else {
          seekTime = previousMarkers[1].times.zuluStartTime;
        }
      } else {
        if (isLive) {
          seekTime =
            this.mediaTimestampService.livePointTimestampZulu -
            previousMarkers[0].times.zuluStartTime;
          seekTime = livePointToSeekTo - seekTime / 1000;
        } else {
          seekTime = previousMarkers[0].times.zuluStartTime;
        }
      }
    }
    this.seekService.seekThenPlay(seekTime, false, isLive);
  }
}
