import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { Logger } from '../logger/logger';
import { IProviderDescriptor } from '../service/provider.descriptor.interface';
import { addProvider } from '../service/providerDescriptors';
import { TuneService } from '../tune/tune.service';
import {
  IMediaEndPoint,
  IMediaEpisode,
  IMediaSegment,
  IMediaVideo,
  MediaTimeLine,
  IClip,
  TuneResponse,
} from '../tune/tune.interface';
import { LiveTime } from '../livetime/live-time.interface';
import { ContentTypes, PlayerTypes } from '../service/types/content.types';
import { MediaPlayerConstants } from '../mediaplayer/media-player.consts';
import { findMediaItemByTimestamp } from '../util/tune.util';
import { normalizeEpisodeSegments } from '../util/tune.util';
import { ApiLayerTypes } from '../service/consts/api.types';
import { RefreshTracksService } from '../refresh-tracks/refresh-tracks.service';
import { AffinityType } from '../affinity';
import { ConfigService } from '../config/config.service';
import { IRelativeUrlSetting } from '../config/interfaces/settings-item.interface';
import { ApiDelegate, IHttpResponse } from '../http';
import { TuneDelegate } from '../tune';
import { ChromecastModel } from '../chromecast/chromecast.model';
import { DateUtil } from '../util/date.util';
import { AudioPlayerConstants } from '..';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/13/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 */
export class MediaTimeLineService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('MediaTimeLineService');

  /**
   * Player type can be local or remote
   * @type {string}
   */
  private playerType: string = PlayerTypes.LOCAL;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the MediaTimeLine
   * and be notified when the MediaTimeLine data changes.
   * @type {any}
   */
  public mediaTimeLine: Observable<MediaTimeLine>;

  /**
   * subject for delivering media timeLine through the MediaTimeLine observable.
   * @type {any}
   */
  private mediaTimeLineSubject: BehaviorSubject<MediaTimeLine> = null;

  /**
   * The media time line that represents the current now playing timeLine for live/AOD.
   * @type {any}
   */
  private mediaTimeLineData: MediaTimeLine = null;

  /**
   * The media time line that represents the current now playing timeLine for live/AOD.
   * @type {any}
   */
  private tuneMediaTimeLineData: MediaTimeLine = null;

  /**
   * live time data
   * @type {any}
   */
  private liveTime: LiveTime = null;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(MediaTimeLineService, MediaTimeLineService, [
      TuneService,
      RefreshTracksService,
      ChromecastModel,
      ConfigService,
    ]);
  })();

  /**
   * Constructor
   * @param {TuneService} tuneService
   * @param {RefreshTracksService} refreshTracksService
   */
  constructor(
    private tuneService: TuneService,
    private refreshTracksService: RefreshTracksService,
    private chromecastModel: ChromecastModel,
    private configService: ConfigService,
  ) {
    this.mediaTimeLineSubject = new BehaviorSubject(this.mediaTimeLineData);
    this.mediaTimeLine = this.mediaTimeLineSubject.pipe(
      filter(mediaTimeline => mediaTimeline !== null),
    );
    this.setSubscribers();
  }

  /** Allow a switch between live audio playback and live video playback.  For channels that have both audio and
   * video available, this lets us re-trigger the media time line to switch between different types of content
   *
   * @returns {boolean} true if the playback type was switched, false if the switch could not be performed
   */
  public switchPlayback(timestamp: number): void {
    const videoMarker = findMediaItemByTimestamp(
      timestamp,
      this.mediaTimeLineData.videos,
      false,
    ) as IMediaVideo;

    if (
      this.mediaTimeLineData.mediaType === ContentTypes.LIVE_AUDIO &&
      checkForVideoEndpoints(videoMarker) === true
    ) {
      MediaTimeLineService.logger.debug(
        `switchPlayback( From Live Audio to Live Video. )`,
      );
      this.updateMediaTimeLine(ContentTypes.LIVE_VIDEO, timestamp, videoMarker);
    } else if (
      this.mediaTimeLineData.mediaType === ContentTypes.LIVE_VIDEO &&
      checkForAudioEndpoints(this.mediaTimeLineData) === true
    ) {
      MediaTimeLineService.logger.debug(
        `switchPlayback( From Live Video to Live Audio. )`,
      );
      this.updateMediaTimeLine(ContentTypes.LIVE_AUDIO, timestamp);
    } else {
      MediaTimeLineService.logger.warn(
        `switchPlayback( Could not switch from ${this.mediaTimeLineData.mediaType} b/c the media endpoints were invalid. )`,
      );
    }

    function checkForAudioEndpoints(mediaTimeLine: MediaTimeLine): boolean {
      const endPoints = mediaTimeLine.mediaEndPoints;
      const isValid: boolean = !!endPoints.find(
        (endPoint: IMediaEndPoint) =>
          endPoint.name === ApiLayerTypes.PRIMARY_AUDIO_ENDPOINT ||
          endPoint.name === ApiLayerTypes.SECONDARY_AUDIO_ENDPOINT,
      );
      MediaTimeLineService.logger.debug(
        `switchPlayback( Audio Endpoints Valid: ${isValid} )`,
      );
      return isValid;
    }

    function checkForVideoEndpoints(videoMarker: IMediaVideo): boolean {
      const videoUrl =
        videoMarker && videoMarker.liveVideoUrl
          ? videoMarker.liveVideoUrl
          : null;
      return videoUrl !== null;
    }
  }

  /**
   * This method called when casting device connected and disconnected . which set Remote/Local as player type.
   * @param {string} playerType
   */
  public setRemotePlayerType(playerType: string): void {
    this.playerType = playerType;
    this.tuneService.setPlayerType(playerType);

    if (!this.mediaTimeLineData) {
      return null;
    }

    this.mediaTimeLineData.playerType = playerType;

    this.mediaTimeLineSubject.next(this.mediaTimeLineData);
  }

  /**
   * updates mediaTimeLine affinity value for the track using assetGUID.
   */
  public updateTrackAffinity(affinity: AffinityType, assetGUID: string): void {
    if (this.mediaTimeLineData) {
      this.mediaTimeLineData.cuts.some(cut => {
        if (cut.assetGUID === assetGUID) {
          cut.affinity = affinity;
          return true;
        }
      });
      this.mediaTimeLineData.clips.updateTrackAffinity(affinity, assetGUID);
      this.mediaTimeLineSubject.next(this.mediaTimeLineData);
    }
  }

  /**
   * Accessor to the currently playing cut/track.
   * @returns {IClip | null}
   */
  public getCurrentPlayingTrack(): IClip | null {
    const clips = _.get(this, 'mediaTimeLineData.clips', null);
    return clips ? clips.getCurrentTrack() : null;
  }

  /**
   * Accessor to the currently pre loaded cut/track.
   * @returns {IClip | null}
   */
  public getCurrentPreLoadedTrack(): IClip | null {
    const clips = _.get(this, 'mediaTimeLineData.clips', null);
    return clips ? clips.getPreLoadedTrack() : null;
  }

  /**
   * Accessor to the currently playing cut's station factory id.
   * @returns {string}
   */
  public getCurrentPlayingStationFactory(): string {
    return _.get(this, 'mediaTimeLineData.mediaId', '') as string;
  }

  /**
   * Accessor to the currently playing cut's station id.
   * @returns {string}
   */
  public getCurrentPlayingStationId(): string {
    return _.get(this, 'mediaTimeLineData.stationId', '') as string;
  }

  /**
   * get Player type
   */
  public getPlayerType(): string {
    return this.playerType;
  }

  /**
   * Update tuneMediaTimeLineData with any future segments or old segments if it needs to be added
   */
  public updateMediaTimeLineWithLiveTime(liveTime: LiveTime): void {
    this.liveTime = liveTime;
    this.tuneService.setLiveTime(liveTime);

    if (!this.tuneMediaTimeLineData) {
      return;
    }

    if (!liveTime) {
      return;
    }

    const segmentsResult = MediaTimeLineService.cleanFutureSegments(
      this.liveTime,
      this.tuneMediaTimeLineData.originalSegments,
    );
    const episodesResult = MediaTimeLineService.cleanFutureSegmentsFromEpisodes(
      this.liveTime,
      this.mediaTimeLineData.episodes,
    );

    if (segmentsResult.changed === true) {
      this.tuneMediaTimeLineData.segments = segmentsResult.segments;
    }
    if (episodesResult.changed === true) {
      this.tuneMediaTimeLineData.episodes = episodesResult.episodes;
    }

    if (segmentsResult.changed === true || episodesResult.changed === true) {
      this.mediaTimeLineSubject.next(this.mediaTimeLineData);
    }
  }

  /** Allow a switch between live audio playback and live video playback.  For channels that have both audio and
   * video available, this lets us re-trigger the media time line to switch between different types of content
   *
   * @returns {boolean} true if the playback type was switched, false if the switch could not be performed
   */
  private updateMediaTimeLine(
    mediaType: string,
    startTime: number,
    currentlyPlayingMediaMarker?: IMediaVideo,
  ): void {
    this.mediaTimeLineData.mediaType = mediaType;
    if (mediaType === ContentTypes.LIVE_VIDEO) {
      if (!currentlyPlayingMediaMarker) {
        currentlyPlayingMediaMarker = _.findLast(
          this.mediaTimeLineData.videos,
          video => !!video.liveVideoUrl,
        );
      }

      const endPoints = this.mediaTimeLineData.mediaEndPoints;
      let videoEndPoint = endPoints.find(
        (endPoint: IMediaEndPoint) =>
          endPoint.name === MediaPlayerConstants.HLS,
      );

      if (!videoEndPoint) {
        videoEndPoint = {
          name: MediaPlayerConstants.HLS,
          url: null,
          size: MediaPlayerConstants.PLAYLIST_SIZE_LARGE,
          mediaFirstChunks: [],
          manifestFiles: [
            {
              name: MediaPlayerConstants.HLS,
              url: null,
              size: MediaPlayerConstants.PLAYLIST_SIZE_LARGE,
            },
          ],
          position: {
            isoTimestamp: null,
            zuluTimestamp: startTime,
            positionType: 'live',
          },
        };

        this.mediaTimeLineData.mediaEndPoints.push(videoEndPoint);
      }
      videoEndPoint.url = currentlyPlayingMediaMarker.liveVideoUrl
        ? currentlyPlayingMediaMarker.liveVideoUrl
        : videoEndPoint.url;
      videoEndPoint.manifestFiles[0].url = videoEndPoint.url;
    }

    if (startTime && startTime > 0) {
      this.mediaTimeLineData.mediaEndPoints.forEach(
        (endPoint: IMediaEndPoint) => {
          endPoint.position.isoTimestamp = new Date(startTime).toISOString();
          endPoint.position.zuluTimestamp = startTime;
        },
      );
    }
    this.mediaTimeLineSubject.next(this.mediaTimeLineData);

    // Update the tuneMediaTimeLineData when tuneMediaTimeLineData updates
    this.tuneMediaTimeLineData.mediaType = this.mediaTimeLineData.mediaType;
    this.tuneMediaTimeLineData.mediaEndPoints = this.mediaTimeLineData.mediaEndPoints;
  }

  /**
   * subscribes to observables
   */
  private setSubscribers() {
    this.subscribeTuneMediaTimeLine();
    this.subscribeUpdatedClips();
    this.subscribeUpdatedClipsFromCast();
  }

  /**
   * Subscribes to the tune media time and triggers the media time line observable.
   */
  private subscribeTuneMediaTimeLine(): void {
    this.tuneService.tuneMediaTimeLine
      .pipe(filter(tuneMediaTimeLine => !!tuneMediaTimeLine))
      .subscribe(tuneMediaTimeLine => {
        this.tuneMediaTimeLineData = tuneMediaTimeLine;

        this.mediaTimeLineData = tuneMediaTimeLine;
        this.mediaTimeLineData.playerType = this.playerType;

        const segmentResults = MediaTimeLineService.cleanFutureSegments(
          this.liveTime,
          this.mediaTimeLineData.originalSegments,
        );
        const episodeResults = MediaTimeLineService.cleanFutureSegmentsFromEpisodes(
          this.liveTime,
          this.mediaTimeLineData.episodes,
        );
        this.mediaTimeLineData.segments = segmentResults.segments;
        this.mediaTimeLineData.episodes = episodeResults.episodes;

        const endPoints = MediaTimeLineService.normalizeMediaEndpoints(
          this.mediaTimeLineData.mediaEndPoints,
        );
        this.mediaTimeLineData.mediaEndPoints = endPoints;

        if (tuneMediaTimeLine.mediaType === ContentTypes.LIVE_VIDEO) {
          const videoMarker: IMediaVideo = tuneMediaTimeLine.isDataComeFromResume
            ? null
            : (findMediaItemByTimestamp(
                tuneMediaTimeLine.startTime,
                tuneMediaTimeLine.videos,
              ) as IMediaVideo);
          this.updateMediaTimeLine(
            tuneMediaTimeLine.mediaType,
            tuneMediaTimeLine.startTime,
            videoMarker,
          );
        } else {
          this.mediaTimeLineSubject.next(this.mediaTimeLineData);
        }
      });
  }

  /**
   * Subscribes to the updated clips and triggers the media time observable.
   */
  private subscribeUpdatedClips(): void {
    this.refreshTracksService.mediaTimeLine
      .pipe(filter(response => !!response))
      .subscribe((response: TuneResponse) => {
        this.mediaTimeLineData = response;
        this.mediaTimeLineData.playerType = this.playerType;
        this.mediaTimeLineSubject.next(this.mediaTimeLineData);
      });
  }

  /**
   * Subscribes to the updated clips and triggers the media time observable.
   */
  private subscribeUpdatedClipsFromCast(): void {
    this.chromecastModel.updateTracks$
      .pipe(filter(response => !!response))
      .subscribe((apiResponse: any) => {
        const relativeUrls: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();
        const seededRadioFallbackUrl = this.configService.getSeededRadioBackgroundUrl();
        const responseType =
          this.chromecastModel.currentlyPlayingData.mediaType ===
          ContentTypes.SEEDED_RADIO
            ? ApiLayerTypes.RESPONSE_TYPE_SEEDED_RADIO
            : ApiLayerTypes.RESPONSE_TYPE_AIC;
        let response = ApiDelegate.getResponseData({
          data: apiResponse.body,
        } as IHttpResponse);
        if (response.seededRadioData) {
          response.seededRadioData.clipList.clips = response.seededRadioData.clipList.clips.map(
            clip => {
              clip.status = clip.trackStatus;
              return clip;
            },
          );
        }
        if (response.additionalChannelData) {
          response.additionalChannelData.clipList.clips = response.additionalChannelData.clipList.clips.map(
            clip => {
              clip.status = clip.trackStatus;
              return clip;
            },
          );
        }
        let responseData = _.get(response, responseType) as any;

        let currentTracks = this.mediaTimeLineData.clips.toArray();
        let trackIndex = currentTracks[currentTracks.length - 1].index;

        const mediaData =
          this.chromecastModel.currentlyPlayingData.mediaType ===
          ContentTypes.SEEDED_RADIO
            ? TuneDelegate.normalizeSeededRadioData(
                responseData,
                new TuneResponse(),
                relativeUrls,
                seededRadioFallbackUrl,
              )
            : TuneDelegate.normalizeAdditionalChannelData(
                responseData,
                new TuneResponse(),
                relativeUrls,
                trackIndex + 1,
              );
        mediaData.mediaType = response.moduleType.toLowerCase();
        this.mediaTimeLineData = mediaData;
        this.mediaTimeLineData.playerType = this.playerType;
        this.mediaTimeLineSubject.next(this.mediaTimeLineData);
      });
  }

  /**
   * Clears the future segments from the list of segments and returns a new list
   *
   * @param liveTime is the current live time to determine if a segment is inthe future or now
   * @param segments is the list of segments to use to create a new list of segments with no segments in the future
   *
   * @returns a new list of segments that will not contain any segments that start after the live time
   */
  private static cleanFutureSegments(
    liveTime: LiveTime,
    segments: Array<IMediaSegment>,
  ): { changed: boolean; segments: Array<IMediaSegment> } {
    if (!segments) {
      return { changed: false, segments: segments };
    }

    const numSegmentsBefore = segments.length;
    const newSegments = segments.filter(
      segment => segment.times.zuluStartTime <= liveTime.zuluMilliseconds,
    );

    return {
      changed: numSegmentsBefore != segments.length,
      segments: newSegments,
    };
  }

  /**
   * Clears the future segments from the list of episdoes and returns a new list
   *
   * @param liveTime is the current live time to determine if a segment is inthe future or now
   * @param episodes is the list of segments to use to create a new list of with no segments in the future
   *
   * @returns a new list of episodes that will not contain any segments that start after the live time
   */
  private static cleanFutureSegmentsFromEpisodes(
    liveTime: LiveTime,
    episodes: Array<IMediaEpisode>,
  ): { changed: boolean; episodes: Array<IMediaEpisode> } {
    let wasChanged = false;

    const newEpisodes = episodes.map(episode => {
      const result = MediaTimeLineService.cleanFutureSegments(
        liveTime,
        episode.originalSegments,
      );
      episode.segments = result.segments;
      wasChanged = wasChanged === false ? result.changed : wasChanged;
      episode = normalizeEpisodeSegments(episode);
      return episode;
    });

    return { changed: wasChanged, episodes: newEpisodes };
  }
  /**
   * Clears out any media first chunks that do ot have a url properly.  This is unfortunately necessary because the
   * API sometimes delivered arrays of media first chunks that do not actually have a urls for us to pull.  We clear
   * those out here do they do not cause problems later
   *
   * @param endpoints is an array of endpoints that have been given to use.
   * @returns {Array<IMediaEndPoint>} new array of endpoints where any media first chunks that do not have urls are
   *          removed.
   */
  private static normalizeMediaEndpoints(
    endpoints: Array<IMediaEndPoint>,
  ): Array<IMediaEndPoint> {
    if (!endpoints) return [];

    return endpoints.map((endPoint: IMediaEndPoint) => {
      endPoint.mediaFirstChunks = endPoint.mediaFirstChunks
        ? endPoint.mediaFirstChunks.filter((chunk: any) => !!chunk.url)
        : [];
      return endPoint;
    });
  }

  /**
   * Returns the pausepoint video start time.
   * @returns {number}
   */
  public getPausePointVideoStartTime(): number {
    const mediaEndpoint = this.mediaTimeLineData.mediaEndPoints.find(
      (endPoint: IMediaEndPoint) =>
        endPoint.name === MediaPlayerConstants.HLS ||
        endPoint.name === AudioPlayerConstants.PRIMARY_END_POINT,
    );

    const episode = this.mediaTimeLineData.episodes[0];
    const episodeStartTime = episode.times.zuluStartTime;
    const episodeEndTime = episode.times.zuluEndTime;

    let zuluTimestamp: number =
      mediaEndpoint &&
      mediaEndpoint.position &&
      mediaEndpoint.position.zuluTimestamp
        ? mediaEndpoint.position.zuluTimestamp
        : 0;

    //When we get invalid timestamp value in the mediaEndPoints- we will start the player from the episode startTime
    zuluTimestamp =
      !isNaN(zuluTimestamp) && DateUtil.isZulu(zuluTimestamp)
        ? zuluTimestamp
        : episodeStartTime;

    if (zuluTimestamp < episodeStartTime || zuluTimestamp > episodeEndTime) {
      return episodeStartTime;
    }
    return zuluTimestamp;
  }
}
