import * as _ from 'lodash';
import {
  IOnDemandShow,
  IChannel,
  ISubCategory,
  ISuperCategory,
  ContentTypes,
  Logger,
  PerformanceUtil,
} from '../../servicelib';
import { IAction } from '../action/action.interface';
import * as ChannelActions from '../action/channel-list.action';
//import { ChannelListAction } from '../action/channel-list.action';
import {
  IChannelListStore,
  ISelectedCategory,
} from '../selector/channel-list.store';

/**
 * Internal logger.
 */
const logger: Logger = Logger.getLogger('ChannelListReducer');

/**
 * The default filter is set to an empty string so all channels are available.
 * @type {string}
 */
const defaultFilter: string = '';

/**
 * The default sort is set to an empty string so all channels are available.
 * @type {string}
 */
const defaultSort: string = '';

/**
 * Th default or initial store model values.
 * @type {IChannelListStore}
 */
const initialState: IChannelListStore = {
  superCategories: [] as Array<ISuperCategory>,
  selectedSuperCategory: {} as ISuperCategory,
  categories: [] as Array<ISubCategory>,
  selectedCategory: {
    category: {} as ISubCategory,
  } as ISelectedCategory,
  channels: [] as Array<IChannel>,
  liveChannels: [] as Array<IChannel>,
  selectedChannel: {} as IChannel,
  nextChannel: {} as IChannel,
  prevChannel: {} as IChannel,
  filtered: [] as Array<IChannel>,
  filter: defaultFilter,
  sort: defaultSort,
};

/**
 * Filter function that returns all channels.
 * @returns {Function}
 */
const showAll: Function = (): Function => {
  return (channel: IChannel): boolean => true;
};

/**
 * Filter function that returns channels with a matching string in a channel's name.
 * @param {string} filter - The filter input.
 * @returns {Function}
 */
const filterByName: Function = (filter: string = ''): Function => {
  if (!filter) {
    return showAll;
  }
  return (channel: IChannel): boolean => {
    return channel.name.toLowerCase().indexOf(filter) !== -1;
  };
};

/**
 * Filter function that returns channels with a matching number in a channel's number.
 * @param {string} filter - The filter input.
 * @returns {Function}
 */
const filterByNumber: Function = (filter: string = ''): Function => {
  if (!filter) {
    return showAll;
  }
  return (channel: IChannel): boolean => {
    return channel.channelNumber
      ? String(channel.channelNumber).indexOf(filter) !== -1
      : true;
  };
};

/**
 * Performs by the selected filter and sort on the channels list to ensure the expected view of the data represents
 * the current filter and sort.
 * @param {Array<IChannel>} channels - The list of channels to filter and sort.
 * @param {string} filter - The filter input.
 * @param {string} sort - The sort input.
 * @param {boolean} filterForShows - Indicates if the filter is for channels or shows of channels.
 * @returns {Array<IChannel>}
 */
const filterAndSort: Function = (
  channels: Array<IChannel>,
  filter: string | number,
  sort: string,
  filterForShows: boolean = false,
): Array<IChannel> => {
  if (typeof filter === 'string') {
    filter = filter ? filter.toLowerCase() : '';
  }

  const filterFunction: Function = filterForShows
    ? filterAndSortShows
    : filterAndSortChannels;

  return filterFunction(channels, filter);
};

/**
 * Returns a new list of channels where all shows that do *not* have the filter text in them have been
 * removed from the list of shows available on the channel
 * @param channels is the list of channels to process
 * @param filter is the filter text/number to search for
 * @returns {IChannel[]} array of channels that contains shows that have the filter text
 */
const filterAndSortShows: Function = (
  channels: Array<IChannel>,
  filter: string | number,
): Array<IChannel> => {
  return channels
    .map((channel: IChannel) => filterChannelShows(channel, filter))
    .filter((channel: IChannel) => channel.shows !== undefined)
    .sort(sortByNumber);

  /**
   * Get a new channel object that only has shows that match the given filter text
   * @param channel is the channel object in which to find shows that match the filter text
   * @param filter is text/number to search for in the shows for the channel
   * @returns a new object that has a shows array that only contains shows that have the filter text
   */
  function filterChannelShows(
    channel: IChannel,
    filter: string | number,
  ): IChannel {
    const shows = channel.shows.filter((item: IOnDemandShow) =>
      filterByShowLongTitle(item, filter),
    );

    const filteredChannel =
      shows.length > 0
        ? {
            shows: shows,
            channelNumber: channel.channelNumber,
            name: channel.name,
            shortDescription: channel.shortDescription,
            mediumDescription: channel.mediumDescription,
            playingArtist: channel.playingArtist,
            playingTitle: channel.playingTitle,
            imageList: channel.imageList,
          }
        : {};

    return filteredChannel as IChannel;

    /**
     * Return true if the show item's medium description contains the filter text=
     * @param item is the aod sho to find the filter text in
     * @param filter is the text/number to search for
     * @returns {boolean} true if the text is present in the show, false if not present
     */
    function filterByShowLongTitle(
      item: IOnDemandShow,
      filter: string | number,
    ): boolean {
      return item.showDescription
        ? item.showDescription['longTitle']
            .toLowerCase()
            .indexOf(filter.toString()) !== -1
        : false;
    }
  }
};

/**
 * Filter and sort channels using the given filter text.  Returned channel list will be sorted by channel
 * @param channels
 * @param filter
 * @returns {IChannel[]}
 */
const filterAndSortChannels: Function = (
  channels: Array<IChannel>,
  filter: string,
): Array<IChannel> => {
  const filterFn: Function = !isNaN(parseInt(filter, 10))
    ? filterByNumber
    : filterByName;

  return channels.filter(filterFn(filter)).sort(sortByNumber);
};

/**
 * Sort function that currently sorts the channels by ascending number. This could be changed to use a different
 * type of sort (similar to how filtering works), but it was decided that this is the default and until we receive
 * a requirement for something new we're good to go with this.
 * @returns {Function}
 */
const sortByNumber = (channel0: IChannel, channel1: IChannel): number => {
  return (
    parseFloat(String(channel0.channelNumber)) -
    parseFloat(String(channel1.channelNumber))
  );
};

/**
 * Loads and stores a list of super categories on the store.
 * @param {IChannelListStore} state - The current state of the store.
 * @param {Array<ISuperCategory>} superCategories - The list of super categories to save on the store.
 * @returns {IChannelListStore}
 */
const loadSuperCategories: Function = (
  state: IChannelListStore,
  superCategories: Array<ISuperCategory>,
): IChannelListStore => {
  const currentState: IChannelListStore = getCurrentState(state);

  if (
    !currentState.superCategories ||
    currentState.superCategories.length != superCategories.length
  ) {
    logger.debug(
      `loadSuperCategories( ${superCategories.length} super categories. )`,
    );
  }

  currentState.superCategories = superCategories;
  return currentState;
};

/**
 * Selector that gets the supercategories from the store
 * @param state is the current state of the store
 * @returns {Array<ISuperCategory>} array of supercategories held in the store
 */
export const getSuperCategories: Function = (
  state: IChannelListStore,
): Array<ISuperCategory> => state.superCategories;

/**
 * Selector that gets a supercategory from the store using its key
 * @param state is the current state of the store
 * @param key is the key for the given supercategory that we wish to fetch
 * @returns {ISuperCategory} supercategory for the given key, or undefined if no such supercategory exists
 */
export const getSuperCategory: Function = (
  state: IChannelListStore,
  key: string,
): ISuperCategory => {
  return _.find(
    getSuperCategories(state),
    (item: ISuperCategory): boolean =>
      item.key.toLowerCase().indexOf(key) !== -1,
  );
};

/**
 * Sets a super category as the selected one on the store.
 * @param {IChannelListStore} state - The current state of the store.
 * @param {ISuperCategory} superCategory - The super category to select.
 * @returns {IChannelListStore}
 */
const selectSuperCategory: Function = (
  state: IChannelListStore,
  superCategory: ISuperCategory,
): IChannelListStore => {
  const currentState: IChannelListStore = getCurrentState(state);
  const selectedSuperCategory: ISuperCategory = _.find(
    currentState.superCategories,
    { categoryGuid: superCategory.categoryGuid },
  );

  if (selectedSuperCategory) {
    const categories: Array<ISubCategory> = selectedSuperCategory.categoryList;

    currentState.selectedSuperCategory = selectedSuperCategory;
    currentState.categories = categories;

    currentState.selectedCategory = {
      category: {} as ISubCategory,
    } as ISelectedCategory;

    logger.debug(
      `selectSuperCategory( Super Category '${superCategory.name}' has ${categories.length} categories. )`,
    );
  }

  return currentState;
};

/**
 * Selector that gets the currently selected supercategory from the state
 * @param state is the current state of the store
 * @returns {ISuperCategory} the value of the selectedSuperCategory from the store
 */
export const getSelectedSuperCategory: Function = (
  state: IChannelListStore,
): ISuperCategory => state.selectedSuperCategory;

/**
 * Sets a category as the selected one on the store.
 * @param {IChannelListStore} state - The current state of the store.
 * @param {ISubCategory} selectedCategory - The category to select.
 * @returns {IChannelListStore}
 */
const selectCategory: Function = (
  state: IChannelListStore,
  selectedCategory: ISelectedCategory,
): IChannelListStore => {
  const currentState: IChannelListStore = getCurrentState(state);
  const selectedSubCategory: ISubCategory = _.find(currentState.categories, {
    categoryGuid: selectedCategory.category.categoryGuid,
  });
  const channels: Array<IChannel> = selectedSubCategory
    ? selectedSubCategory.channelList
    : [];
  const selectedSubCategoryName = selectedSubCategory
    ? selectedSubCategory.name
    : '';

  // NOTE: Since we are selecting a new category with a new list of channels we also want to reset
  // the filter to the default. This prevents the last known filter from affecting the new list of
  // channels which may not even have a channel(s) that match the filter from the last list of channels.
  currentState.filter = defaultFilter;

  currentState.selectedCategory = selectedCategory;

  currentState.channels = channels;
  currentState.filtered = filterAndSort(
    channels,
    currentState.filter,
    currentState.sort,
  );
  logger.debug(
    `selectCategory( Category '${selectedSubCategoryName}' has ${channels.length} channels. )`,
  );
  return currentState;
};

/**
 * Sets a channel as the selected one on the store. and get the next , previous channel data sets on the store.
 * @param {IChannelListStore} state - The current state of the store.
 * @param {ISubCategory} channel - The channel to select.
 * @returns {IChannelListStore}
 */
const selectChannel: Function = (
  state: IChannelListStore,
  channel: IChannel,
): IChannelListStore => {
  const currentState: IChannelListStore = getCurrentState(state);

  const selectedChannelNumber = channel.channelNumber;
  const maxChannel = _.maxBy(
    state.liveChannels.filter(
      channel => channel.type !== ContentTypes.ADDITIONAL_CHANNELS,
    ),
    'channelNumber',
  );
  const minChannel = _.minBy(
    state.liveChannels.filter(
      channel => channel.type !== ContentTypes.ADDITIONAL_CHANNELS,
    ),
    'channelNumber',
  );

  const surroundingChannels = _.reduce(
    state.liveChannels,
    (
      accumulator: { previous: IChannel; next: IChannel },
      channel: IChannel,
    ) => {
      const prevChannelNumber = accumulator.previous.channelNumber;
      const nextChannelNumber = accumulator.next.channelNumber;
      const currChannelNumber = channel.channelNumber;
      if (
        ((prevChannelNumber === selectedChannelNumber &&
          currChannelNumber < selectedChannelNumber) ||
          (currChannelNumber > prevChannelNumber &&
            currChannelNumber < selectedChannelNumber)) &&
        channel.type !== ContentTypes.ADDITIONAL_CHANNELS
      ) {
        accumulator.previous = channel;
      }

      if (
        ((nextChannelNumber === selectedChannelNumber &&
          currChannelNumber > selectedChannelNumber) ||
          (currChannelNumber < nextChannelNumber &&
            currChannelNumber > selectedChannelNumber)) &&
        channel.type !== ContentTypes.ADDITIONAL_CHANNELS
      ) {
        accumulator.next = channel;
      }
      return accumulator;
    },
    { previous: channel, next: channel },
  );

  currentState.nextChannel =
    selectedChannelNumber === maxChannel.channelNumber
      ? minChannel
      : surroundingChannels.next;
  currentState.prevChannel =
    selectedChannelNumber === minChannel.channelNumber
      ? maxChannel
      : surroundingChannels.previous;
  currentState.selectedChannel = channel;

  logger.debug(`selectChannel( ${channel.name} )`);
  return currentState;
};

/**
 * Filters the list of channels and saves the current filter on the store.
 * @param {IChannelListStore} state - The current state of the store.
 * @param {string} filter - The string filter term to apply.
 * @param {boolean} isForShows - Indicates if the filter is for channels or shows of channels.
 * @returns {IChannelListStore}
 */
const filterChannels: Function = (
  state: IChannelListStore,
  filter: string,
  isForShows: boolean = false,
): IChannelListStore => {
  filter = filter.trim();
  const currentState: IChannelListStore = getCurrentState(state);
  currentState.filter = filter;
  currentState.filtered = filter
    ? filterAndSort(currentState.channels, filter, state.sort, isForShows)
    : currentState.channels;

  return currentState;
};

/**
 *
 * Loads and stores the list of live channels on the store
 * @param {IChannelListStore} state - The current state of the store.
 * @param {Array<IChannel>} channels - The list of live channels that are to be stored in store.
 * @returns {IChannelListStore}
 */
const loadLiveChannels: Function = (
  state: IChannelListStore,
  channels: Array<IChannel>,
): IChannelListStore => {
  const currentState: IChannelListStore = getCurrentState(state);

  if (
    !currentState.liveChannels ||
    currentState.liveChannels.length != channels.length
  ) {
    logger.debug(`loadLiveChannels( ${channels.length} live channels. )`);
  }

  currentState.liveChannels = channels;
  return currentState;
};

/**
 * Gets the current state of the store as a new object.
 * @param {IChannelListStore} state
 * @returns {IChannelListStore}
 */
const getCurrentState: Function = (
  state: IChannelListStore,
): IChannelListStore => {
  return Object.assign({}, state);
};

/**
 * Return the data store depending on the given action.
 *
 * NOTE: There's an issue with exporting fat arrows functions as constants, so this method was changed
 * to the ES5 flavor -- note that we're now using the syntax:
 *
 * `export function channelsReducer(state: IChannelListStore = initialState, action: IAction): IChannelListStore`
 *
 * instead of:
 *
 * `export const channelsReducer: Function = (state: IChannelListStore = initialState, action: IAction): IChannelListStore =>`
 *
 * If we don't do this the TypeScript compiler bombs. Apparently this can be a problem when compiling with AOT as well
 * as documented here: https://github.com/angular/angular-cli/issues/3707
 *
 * @param {IChannelListStore} state - the current state of the store. Defaults to the initial state.
 * @param {IAction} action - The action to take on the data store.
 * @returns {IChannelListStore}
 */
export function channelsReducer(
  state: IChannelListStore = initialState,
  //action: ChannelListAction,
  action: any,
): IChannelListStore {
  const defaultAction: IAction = {
    type: '',
    payload: null,
  };

  state = state || initialState;
  action = action || defaultAction;

  switch (action.type) {
    case ChannelActions.LOAD_SUPER_CATEGORIES:
      return loadSuperCategories(
        state,
        action.payload as Array<ISuperCategory>,
      );

    case ChannelActions.SELECT_SUPER_CATEGORY:
      return selectSuperCategory(state, action.payload as ISuperCategory);

    case ChannelActions.SELECT_CATEGORY:
      return selectCategory(state, action.payload as ISelectedCategory, action);

    case ChannelActions.SELECT_CHANNEL:
      return selectChannel(state, action.payload as IChannel);

    case ChannelActions.FILTER_CHANNELS_BY_NAME: {
      const filter: string = (action.payload as string).toLowerCase();
      return filterChannels(state, filter, false);
    }

    case ChannelActions.FILTER_CHANNELS_BY_NUMBER:
      return filterChannels(state, action.payload, false);

    case ChannelActions.FILTER_CHANNELS_BY_SHOW_NAME:
      return filterChannels(state, action.payload, true);

    case ChannelActions.LOAD_LIVE_CHANNELS:
      return loadLiveChannels(state, action.payload as Array<IChannel>);

    case ChannelActions.NGRX_STORE_INIT:
      PerformanceUtil.markMoment(PerformanceUtil.moments.STORE_INITIALIZED);
      return state;

    default:
      return state;
  }
}
