import { ModuleRequest } from '../http';

export class StandardAlternateAuth extends ModuleRequest {
  public standardAuth: {
    regCode: string;
  };

  constructor(regCode: string) {
    super();

    this.standardAuth = {
      regCode: regCode,
    };
  }
}
