import { first, filter, map, mergeMap, take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { IAppConfig } from '../config';
import { IProviderDescriptor, addProvider } from '../service';
import { ICarouselDataByType } from './carousel.interface';
import {
  ISuperCategory,
  ISubCategory,
  HOWARD_SUPERCATEGORY_KEY,
  mockForYouData,
} from '../channellineup';
import { ContentTypes } from '../service/types';
import { CarouselPageParameter } from './carousel.interface';
import { CarouselTypeConst } from './carousel.const';
import { DmcaService } from '../dmca';
import { Logger } from '../logger';
import { CarouselDelegate } from './carousel.delegate';
import { ChannelLineupService } from '../channellineup';
import { CarouselCacheEntry } from './carousel.cache.entry';
import { ProfileService } from '../profile';
import { FavoriteService } from '../favorite/favorite.service';
import { LiveTimeService } from '../livetime';
import { AlertService } from '../alerts/alert.service';
import { MediaTimeLineService } from '../media-timeline/media.timeline.service';
import { SeededStationsService } from '../seeded-stations';
import { ICurrentlyPlayingMedia } from '../tune/tune.interface';
import { CurrentlyPlayingService } from '../currently-playing/currently.playing.service';
import { FavoriteModel } from '../favorite/favorite.model';
import { IGroupedFavorites } from '../favorite';
import { FavoriteCarouselNormalizer } from './favorites/favorite-carousel.normalizer';
export class CarouselService {
  /**
   * Logger.
   */
  private static logger: Logger = Logger.getLogger('CarouselService');

  private static SUPERCATEGORY_CACHE = 'supercategory';
  private static SUBCATEGORY_CACHE = 'subcategory';
  private static VIEWALL_CACHE = 'viewall';
  private static COLLECTION_CACHE = 'collection_details';
  private static PAGE_CACHE = 'page';

  /**
   * carousel cache
   * @type {Array}
   */
  private carouselCache: Array<Array<CarouselCacheEntry>> = [];

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(CarouselService, CarouselService, [
      CarouselDelegate,
      ChannelLineupService,
      ProfileService,
      FavoriteModel,
      LiveTimeService,
      MediaTimeLineService,
      CurrentlyPlayingService,
      AlertService,
      SeededStationsService,
      DmcaService,
      'IAppConfig',
    ]);
  })();

  constructor(
    private carouselDelegate: CarouselDelegate,
    private channelLineupService: ChannelLineupService,
    private profileService: ProfileService,
    private favoriteModel: FavoriteModel,
    private liveTimeService: LiveTimeService,
    private mediaTimeLineService: MediaTimeLineService,
    private currentlyPlayingService: CurrentlyPlayingService,
    private alertService: AlertService,
    private seededStationsService: SeededStationsService,
    private dmcaService: DmcaService,
    private SERVICE_CONFIG: IAppConfig,
  ) {}

  /**
   * Iterate through an array of supercategories and prefetch all the carousels for the super categories & All
   * ondemand and all viedo carousels
   * @param {Array<ISuperCategory>} supercategories list that we will get the keys for to prefetch
   */
  public preFetchCarousels(
    supercategories: Array<ISuperCategory>,
    keysToCache?: Array<string>,
  ): Observable<any> {
    const observables = [];
    let count = 0;
    const sub = new BehaviorSubject(false);

    supercategories.forEach((category: ISuperCategory) => {
      if (
        !keysToCache ||
        keysToCache.find((key: string): boolean => key === category.key)
      ) {
        observables.push(this.getCarouselsBySuperCategory(category));
      }
    });

    if (observables.length === 0) {
      observables.push(
        this.getCarouselsBySuperCategory(
          this.SERVICE_CONFIG.defaultSuperCategory,
        ),
      );
    }

    observables.push(this.getFavoriteTiles());

    observables.forEach(observable => {
      observable.subscribe(() => {
        if (count++ === observables.length) {
          sub.next(true);
        }
      });
    });

    return sub.pipe(filter((value: boolean) => value !== false));
  }

  /**
   * Used to retrieve a supercategory carousel from the carousel delegate
   *
   * @param {ISuperCategory} superCategory to get from the carousel delegate
   * @param {boolean} hardFetch to avoid cached data and force carousel data refresh
   */
  public getCarouselsBySuperCategory(
    superCategory: ISuperCategory,
    hardFetch: boolean = false,
  ): Observable<ICarouselDataByType> {
    let obs = !hardFetch
      ? this.getCarouselFromCache(
          CarouselService.SUPERCATEGORY_CACHE,
          superCategory.key,
        )
      : null;

    if (!obs) {
      switch (superCategory.key) {
        case mockForYouData.key:
          return this.getCarouselsByPage(CarouselTypeConst.FOR_YOU);

        case HOWARD_SUPERCATEGORY_KEY:
          obs = this.carouselDelegate.getCarouselsBySuperCategory(
            superCategory.categoryList[0].key,
          );
          break;

        default:
          obs = this.carouselDelegate.getCarouselsBySuperCategory(
            superCategory.key,
          );
          break;
      }

      obs = this.cacheCarousel(
        CarouselService.SUPERCATEGORY_CACHE,
        superCategory.key,
        obs,
        hardFetch,
      );
    }

    return obs;
  }

  /**
   * Used to retrieve a subcategory carousel from the carousel delegate
   *
   * @param {ISuperCategory} subCategory to get from the carousel delegate
   */
  public getCarouselsBySubCategory(
    subCategory: ISubCategory,
    hardFetch: boolean = false,
  ): Observable<ICarouselDataByType> {
    if (!subCategory.key || subCategory.key.length === 0) {
      CarouselService.logger.debug(
        `invalid subCategory - key is not provided : (${JSON.stringify(
          subCategory,
        )})`,
      );
      throw {
        message: `invalid subCategory - key is not provided : ${JSON.stringify(
          subCategory,
        )}`,
      };
    }
    let obs = !hardFetch
      ? this.getCarouselFromCache(
          CarouselService.SUBCATEGORY_CACHE,
          subCategory.key,
        )
      : null;

    if (!obs) {
      obs = this.carouselDelegate.getCarouselsBySubCategory(subCategory);
      obs = this.cacheCarousel(
        CarouselService.SUBCATEGORY_CACHE,
        subCategory.key,
        obs,
      );
    }
    return obs;
  }

  /**
   * Get favorite tiles from carousel endpoint
   *
   * @returns {Observable<any>}
   * @memberof CarouselService
   */
  public getFavoriteTiles(): Observable<IGroupedFavorites> {
    const groupedTiles = this.getCarouselsByPage(
      CarouselTypeConst.FAVORITES,
      [],
      null,
      true,
    ).pipe(map(FavoriteCarouselNormalizer.normalizeFavoritesCarousel));
    this.favoriteModel.setFavListFromCarousel(groupedTiles);
    return groupedTiles;
  }

  /**
   * Used to retrieve a view all carousel from the caruosel delegate
   * @param {string} carouselGuid is the guid that identifies which view all we are to display
   *
   * @returns {Observable<ICarouselDataByType>}
   */
  public getViewAllCarousels(
    carouselGuid: string,
  ): Observable<ICarouselDataByType> {
    let obs = this.getCarouselFromCache(
      CarouselService.VIEWALL_CACHE,
      carouselGuid,
    );

    if (!obs) {
      obs = this.carouselDelegate.getCarouselsByPage(carouselGuid);
      this.clearCarouselCache(CarouselService.VIEWALL_CACHE);
      obs = this.cacheCarousel(
        CarouselService.VIEWALL_CACHE,
        carouselGuid,
        obs,
      );
    }

    return obs;
  }

  /**
   * Use to retrieve the now playing carousels by media type and irNavClass (content restriction) type
   *
   * @param data is currentlyPlayingMedia
   * @param params is an (optional) array of parameters to be passed with the page request as url parameters
   */
  public getNowPlayingCarousel(
    data: ICurrentlyPlayingMedia,
    params?: Array<CarouselPageParameter>,
  ): Observable<ICarouselDataByType> {
    const mediaType = data.mediaType;
    const isDisallowed: boolean = this.dmcaService.isDisallowed(data);
    const isRestricted: boolean = this.dmcaService.isRestricted(data);

    let pageName: string = '';

    switch (mediaType) {
      case ContentTypes.LIVE_AUDIO:
        if (isDisallowed) {
          pageName = CarouselTypeConst.NOW_PLAYING_LIVE_DISALLOWED;
        } else if (isRestricted) {
          pageName = CarouselTypeConst.NOW_PLAYING_LIVE_RESTRICTED;
        } else {
          pageName = CarouselTypeConst.NOW_PLAYING_LIVE_UN_RESTRICTED;
        }
        break;

      case ContentTypes.PODCAST:
      case ContentTypes.AOD:
        params = this.cleanFromParams('cutArtistName', params);

        if (isDisallowed) {
          pageName = CarouselTypeConst.NOW_PLAYING_AOD_DISALLOWED;
        } else if (isRestricted) {
          pageName = CarouselTypeConst.NOW_PLAYING_AOD_RESTRICTED;
        } else {
          pageName = CarouselTypeConst.NOW_PLAYING_AOD_UN_RESTRICTED;
        }
        break;

      case ContentTypes.VOD:
        params = this.cleanFromParams('cutArtistName', params);

        if (isDisallowed) {
          pageName = CarouselTypeConst.NOW_PLAYING_VOD_DISALLOWED;
        } else if (isRestricted) {
          pageName = CarouselTypeConst.NOW_PLAYING_VOD_RESTRICTED;
        } else {
          pageName = CarouselTypeConst.NOW_PLAYING_VOD_UN_RESTRICTED;
        }
        break;

      case ContentTypes.ADDITIONAL_CHANNELS:
        if (isDisallowed) {
          pageName = CarouselTypeConst.NOW_PLAYING_AIC_DISALLOWED;
        } else if (isRestricted) {
          pageName = CarouselTypeConst.NOW_PLAYING_AIC_RESTRICTED;
        } else {
          pageName = CarouselTypeConst.NOW_PLAYING_AIC_UN_RESTRICTED;
        }
        break;

      case ContentTypes.SEEDED_RADIO:
        params = this.cleanFromParams('cutGuid', params);

        if (isDisallowed) {
          pageName = CarouselTypeConst.NOW_PLAYING_AR_DISALLOWED;
        } else if (isRestricted) {
          pageName = CarouselTypeConst.NOW_PLAYING_AR_RESTRICTED;
        } else {
          pageName = CarouselTypeConst.NOW_PLAYING_AR_UN_RESTRICTED;
        }
        break;
    }

    return this.getCarouselsByPage(pageName, params);
  }

  /**
   * Used to clean a param from params.
   * @param {string} paramName
   * @param {CarouselPageParameter[]} params
   * @returns {CarouselPageParameter[]}
   */
  public cleanFromParams(
    paramName: string,
    params: CarouselPageParameter[],
  ): CarouselPageParameter[] {
    return params.filter(param => param.paramName !== paramName);
  }

  /**
   * Use to get Episode list of carousel by show guid.
   * @param {string} showGuid
   * @returns {Observable<ICarouselDataByType>}
   */
  public getEpisodeCarouselByShow(
    showGuid: string,
  ): Observable<ICarouselDataByType> {
    const params: Array<CarouselPageParameter> = [];

    params.push({ paramName: 'showGuid', paramValue: showGuid });

    return this.getCarouselsByPage(CarouselTypeConst.EPISODE_EDP, params);
  }

  /**
   * Use to get Episode list of carousel by channel guid.
   * @param {string} showGuid
   * @returns {Observable<ICarouselDataByType>}
   */
  public getShowCarouselByChannel(
    channelGuid: string,
  ): Observable<ICarouselDataByType> {
    const params: Array<CarouselPageParameter> = [];

    params.push({ paramName: 'channelGuid', paramValue: channelGuid });

    return this.getCarouselsByPage(CarouselTypeConst.SHOWS_EDP, params);
  }

  /**
   * Use to retrieve the all on demand carousels.
   * @returns {Observable<ICarouselDataByType>}
   */
  public getAllOnDemandCarousel(): Observable<ICarouselDataByType> {
    return this.getCarouselsByPage(CarouselTypeConst.ONDEMAND_ALL);
  }

  /**
   * Use to retrieve the all video carousels.
   * @returns {Observable<ICarouselDataByType>}
   */
  public getAllVideoCarousel(): Observable<ICarouselDataByType> {
    return this.getCarouselsByPage(CarouselTypeConst.VIDEO_ALL);
  }

  /**
   * Used to retrive the carousel by url.
   * @param pageName
   * @param pageUrl
   */
  public getCarouselsByPageUrl(
    pageName: string,
    pageUrl: string,
  ): Observable<ICarouselDataByType> {
    //ToDo: Due to hot fix make this fix simple and Have to rethink of this logic.
    return this.channelLineupService.channelLineup.channels.pipe(
      filter(channel => !!channel.length),
      take(1),
      mergeMap(() => this.getCarouselsByPage(pageName, [], pageUrl)),
    );
  }

  /**
   * Used to retrieve a page carousel from the carousel delegate
   *
   * @param pageName is the name of the page to get the carousel data for
   * @param params is an (optional) array of parameters to be passed with the page request as url parameters
   */
  public getCarouselsByPage(
    pageName: string,
    params: Array<CarouselPageParameter> = [],
    pageUrl?: string,
    webTemplate: boolean = false,
  ): Observable<ICarouselDataByType> {
    let obs;

    // TODO:
    // Once we hook in the v4 carousel API we will hopefully have a refresh time that we can
    // us for caching search and profile carousel calls
    if (
      pageName !== CarouselTypeConst.PROFILE &&
      pageName !== CarouselTypeConst.SEARCH &&
      pageName !== CarouselTypeConst.FAVORITES &&
      pageName !== CarouselTypeConst.RECENTS &&
      pageName !== CarouselTypeConst.SHOW_REMINDERS &&
      pageName !== CarouselTypeConst.COLLECTION_DETAILS &&
      pageName !== CarouselTypeConst.ENHANCED_EPISODE_EDP &&
      pageName !== CarouselTypeConst.ENHANCED_SHOW_EDP &&
      pageName !== CarouselTypeConst.ENHANCED_CHANNEL_EDP &&
      pageName !== CarouselTypeConst.SEEDED_STATIONS &&
      pageName !== CarouselTypeConst.NOW_PLAYING_VOD_UP_NEXT &&
      pageName !== CarouselTypeConst.NOW_PLAYING_AOD_UP_NEXT &&
      (!params || params.length === 0) &&
      !pageUrl
    ) {
      obs = this.getCarouselFromCache(CarouselService.PAGE_CACHE, pageName);
    }

    if (!obs) {
      // For Zone based pages we should send start/limit params along with others
      // We need to modify this logic to make it generalize once all carousel pages support zones
      if (
        pageName === CarouselTypeConst.FOR_YOU ||
        pageName === CarouselTypeConst.SEARCH_LANDING
      ) {
        const zoneParams: Array<CarouselPageParameter> = [
          {
            paramName: 'start',
            paramValue: 1,
          },
          {
            paramName: 'limit',
            paramValue: 10,
          },
        ];
        params = params.concat(zoneParams);

        if (pageName === CarouselTypeConst.FOR_YOU) {
          //Needed so that we get the Podcasts moreSelector
          params = params.concat([
            {
              paramName: 'result-template',
              paramValue: 'HLSP',
            },
          ]);
        }
      }
      obs = pageUrl
        ? this.carouselDelegate.getCarouselsByPageUrl(pageName, pageUrl)
        : this.carouselDelegate.getCarouselsByPage(
            pageName,
            params,
            webTemplate,
          );
      obs = this.cacheCarousel(CarouselService.PAGE_CACHE, pageName, obs);
    }

    return obs;
  }

  /**
   * Look at the given carousel cache and see if we have a carousel in the cache
   *
   * @param {string} cache is the name of the cache to look for the given carousel in
   * @param {string} cacheKey is the key for the cache entry, will be prepended with "cached" and then used
   * @returns {Observable<ICarouselDataByType>}
   */
  private getCarouselFromCache(
    cache: string,
    cacheKey: string,
  ): Observable<ICarouselDataByType> {
    if (!this.carouselCache[cache]) {
      this.carouselCache[cache] = [];
    }

    const cachedCarousel: CarouselCacheEntry = this.carouselCache[cache][
      `cached_${cacheKey}`
    ];
    const currentDate = Date.now();
    let cachedCarousels;

    if (
      cachedCarousel &&
      (cachedCarousel.responseRecieved === false ||
        cachedCarousel.expiryTime > currentDate)
    ) {
      cachedCarousels = cachedCarousel.carousels;
    }

    return cachedCarousels;
  }

  /**
   * Cache a carousel observable for usage later.  The observable will saved in a specific cache as specified by
   * the cache parameter using the key cacheKey.  The actual key that is used will be `cached_${cacheKey} to avoid
   * namespace collisions with the Javascript array object ("pop" subcategory, I am looking at you ....)
   *
   * @param {string} cache is the cache to save the observable into
   * @param {string} cacheKey is the key to use to store the observable (prepended with "cache_")
   * @param {Observable<ICarouselDataByType>} carousels is the observable to save into the cache
   */
  private cacheCarousel(
    cache: string,
    cacheKey: string,
    carousels: Observable<ICarouselDataByType>,
    updateExisting: boolean = false,
  ): Observable<ICarouselDataByType> {
    if (!this.carouselCache[cache]) {
      this.carouselCache[cache] = [];
    }

    const carouselCacheEntry = new CarouselCacheEntry(
      this.channelLineupService,
      this.profileService,
      this.favoriteModel,
      this.liveTimeService,
      this.mediaTimeLineService,
      this.currentlyPlayingService,
      this.alertService,
      this.seededStationsService,
      Date.now(),
      cacheKey,
      carousels,
    );

    const prevCarouselCacheEntry = this.carouselCache[cache][
      `cached_${cacheKey}`
    ];

    carouselCacheEntry.carousels
      .pipe(
        filter(currentCarousels => {
          return !!currentCarousels && this.validateCarousel(currentCarousels);
        }),
        first(),
      )
      .subscribe(currentCarousels => {
        if (
          !updateExisting ||
          !this.carouselCache[cache][`cached_${cacheKey}`]
        ) {
          if (prevCarouselCacheEntry) {
            prevCarouselCacheEntry.tearDown();
          }
          this.carouselCache[cache][`cached_${cacheKey}`] = carouselCacheEntry;
        } else {
          this.carouselCache[cache][`cached_${cacheKey}`].pushValue(
            currentCarousels,
          );
        }
      });

    return carouselCacheEntry.carousels;
  }

  /**
   * Used to clear the carousel cache
   * @param {string} cache - cache name
   */
  private clearCarouselCache(cache: string): void {
    if (!this.carouselCache[cache]) {
      this.carouselCache[cache] = [];
    }

    this.carouselCache[cache].forEach((entry: CarouselCacheEntry) =>
      entry.tearDown(),
    );

    this.carouselCache[cache] = [];
  }

  /**
   * Validate Carousel response have tiles
   * @returns boolean
   */
  private validateCarousel(carousel: ICarouselDataByType) {
    let hasZoneTiles: boolean = false;
    let hasSelectorTiles: boolean = false;
    let hasCategoryTiles: boolean = false;

    const zones = carousel.zone ? carousel.zone : [];
    const selectors = carousel.selectors ? carousel.selectors : [];

    hasCategoryTiles = !!(
      carousel.category &&
      carousel.category.tiles &&
      carousel.category.tiles.length !== 0
    );

    hasZoneTiles = zones.some(zone => {
      const heroArr = zone.hero ? zone.hero : [];
      const contentArr = zone.content ? zone.content : [];

      return hasTiles(heroArr) || hasTiles(contentArr);
    });

    hasSelectorTiles = selectors.some(selector => {
      const segments = selector.segments ? selector.segments : [];

      return segments.some(segment => {
        const carousels = segment.carousels ? segment.carousels : [];
        return hasTiles(carousels);
      });
    });

    function hasTiles(tilesWrapper) {
      return tilesWrapper.some(val => {
        return val.tiles && val.tiles.length !== 0;
      });
    }

    return hasZoneTiles || hasSelectorTiles || hasCategoryTiles;
  }

  /**
   * Clears the carousel cache to force a fetch on subscription package change
   */

  public clearAllCarouselCache(): void {
    this.carouselCache[CarouselService.PAGE_CACHE] = [];
    this.carouselCache[CarouselService.SUPERCATEGORY_CACHE] = [];
  }
}
