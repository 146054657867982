import React from 'react';
import { ServiceFactory } from '../../servicelib';
import { Marquee } from '../marquee';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useObservable } from '../../hooks';
import { isBackgroundColorLight } from '../../utils/pageUtils';
import fallbackImage from '../../assets/images/no-img.jpeg';
import './HeroTile.scss';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const HeroTile = ({ isFocused, clicked, data, category }) => {
  const carouselTileService = ServiceFactory.getInstance(
    CarouselTileService,
  ) as CarouselTileService;

  useUpdateEffect(() => {
    carouselTileService.onClickNeriticLinkAction(data);
  }, [clicked]);

  const {
    line2$,
    line1$,
    line3$,
    fgImageUrl: logo,
    imageAltText,
    fgImageType,
    iconImageUrl,
    backgroundColor,
    bgImageUrl,
    tileBanner,
    tileAssetInfo,
    tileContentType,
  } = data;

  const line1 = useObservable(line1$);
  const line2 = useObservable(line2$);
  const line3 = useObservable(line3$);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.src = fallbackImage;
  };

  const isIrisShowTile =
    tileAssetInfo.isPandoraPodcast && tileContentType === 'show';
  const isIrisEpisodeTile =
    tileAssetInfo.isPandoraPodcast && tileContentType === 'episode';

  let heroCardLogoClassName = 'hero-card-logo';
  if (isIrisShowTile) {
    heroCardLogoClassName = 'hero-card-logo-centered';
  } else if (isIrisEpisodeTile) {
    heroCardLogoClassName = 'hero-card-logo-squared';
  }

  const herCardLogoResizingParams =
    isIrisShowTile || isIrisEpisodeTile
      ? ''
      : '?width=240&height=192&preserveAspect=true';
  return (
    data && (
      <div
        className={`hero-card ${isFocused ? 'hero-card-focused' : ''}`}
        style={{
          backgroundImage: bgImageUrl
            ? `url("${bgImageUrl}?width=768&height=432&preserveAspect=true")`
            : '',
          backgroundColor: backgroundColor,
        }}
        onFocus={() => {
          ttsPlayback(
            `${data?.line1 || ''} ${data?.line2 || ''} ${data?.line3 ||
              ''} ${data?.text || ''}`,
          );
        }}
      >
        {logo && (
          <img
            className={heroCardLogoClassName}
            src={`${logo}${herCardLogoResizingParams}`}
            alt={imageAltText}
            onError={imageOnErrorHandler}
          />
        )}
        {tileBanner.bannerText && tileBanner.display && (
          <div
            className="hero-card-tag"
            style={{ backgroundColor: tileBanner.bannerColor }}
          >
            {tileBanner.bannerText}
          </div>
        )}
        {(!bgImageUrl || fgImageType !== 'promo') && (
          <div className="hero-card-info-holder">
            <span className="hero-card-info">
              {iconImageUrl && (
                <img className="video-icon" src={iconImageUrl} />
              )}
              {line1 && line2 === '' && line3 === '' ? '' : line1}
              {line3 && <span className="dot-icon" />}
              {line3}
            </span>
            <Marquee
              className="hero-card-title"
              text={line2}
              isFocused={isFocused}
              lightBackground={isBackgroundColorLight(backgroundColor)}
            />
          </div>
        )}
      </div>
    )
  );
};

export default HeroTile;
