import React, { useEffect, useState } from 'react';
import { FocusCarousel } from '@accedo/vdkweb-tv-ui';
import { focusManager, withFocus } from '@accedo/vdkweb-navigation';
import RelatedTab from './related-tab.component';
import { ServiceFactory } from '../../servicelib';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import FocusDiv from '../focus-div/FocusDiv';
import './related-content.component.scss';
import ChannelTile from '../tiles/ChannelTile';
import Swimlane from '../swimlane/Swimlane';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const focusCarouselTabsOptions = {
  arrows: false,
  dots: false,
  speed: 250,
  variableWidth: true,
  slidesToScroll: 1,
  slidesToShow: 4.25,
};

const relatedContentTabsNav = {
  id: 'related-content-tabs',
  nextdown: 'related-content-carousel',
  nextup: 'now-playing',
  useLastFocus: true,
};
const relatedContentNav = {
  id: 'related-content-carousel',
  nextup: 'related-content-tabs',
  nextdown: 'now-playing',
  nextleft: 'related-content-tabs',
  useLastFocus: true,
};

const DRAWER_HEIGHT = '31.5rem';
const OFFSCREEN = '67.6rem';

const RelatedContent = props => {
  const { carouselStore, showRelatedContent, nowPlayingNavId } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [relatedContentData, setRelatedContentData] = useState([]);
  useEffect(() => {
    if (
      carouselStore &&
      carouselStore.nowPlayingCarousel &&
      carouselStore.nowPlayingCarousel.selectors
    ) {
      const { selectors } = carouselStore.nowPlayingCarousel;
      const contentToggleSelector = selectors.find(
        selector => selector['class'] === 'contentToggleSelector',
      );

      if (contentToggleSelector) {
        setRelatedContentData(contentToggleSelector.segments);
      }
    }
  }, [carouselStore && carouselStore.nowPlayingCarousel]);

  const [relatedContentTabs, setRelatedContentTabs] = useState([]);
  useEffect(() => {
    if (relatedContentData.length) {
      const tabs = relatedContentData.slice(0, 3).map(({ title }) => {
        return { text: title };
      });
      setRelatedContentTabs(tabs);
    }
  }, [relatedContentData]);

  useEffect(() => {
    if (showRelatedContent) {
      focusManager.changeFocus(relatedContentTabsNav.id);
    }
  }, [showRelatedContent]);

  const carouselTiles =
    relatedContentData[selectedTab] &&
    relatedContentData[selectedTab].carousels[0] &&
    relatedContentData[selectedTab].carousels[0].tiles;

  const [relatedCarouselKey, resetRelatedCarousel] = useState(0);
  useEffect(() => {
    resetRelatedCarousel(relatedCarouselKey + 1);
  }, [selectedTab]);

  const carouselTileService = ServiceFactory.getInstance(
    CarouselTileService,
  ) as CarouselTileService;

  const onRelatedCarouselClick = data => {
    focusManager.changeFocus(nowPlayingNavId);
    setSelectedTab(0);
    carouselTileService.onClickNeriticLinkAction(data);
  };
  useEffect(() => {
    if (showRelatedContent && (!carouselTiles || carouselTiles.length === 0)) {
      ttsPlayback('We couldn’t find any content related to this');
    }
  }, [showRelatedContent, carouselTiles]);
  return (
    <div
      className="related-content-container"
      style={{ top: showRelatedContent ? DRAWER_HEIGHT : OFFSCREEN }}
    >
      <div className={'related-content-tabs-container'}>
        {showRelatedContent && (
          <FocusCarousel
            nav={relatedContentTabsNav}
            items={relatedContentTabs}
            prefix={'related-tab'}
            displayObject={
              <RelatedTab
                selectedTab={selectedTab}
                onClick={(data, index) => {
                  setSelectedTab(index);
                }}
              />
            }
            options={focusCarouselTabsOptions}
          />
        )}
      </div>
      <div className={'related-content-carousel-container'}>
        {showRelatedContent && carouselTiles && (
          <Swimlane
            key={relatedCarouselKey}
            nav={relatedContentNav}
            itemWrapperClassName={'channel-wrapper'}
            data={carouselTiles || []}
            component={ChannelTile}
            customOnClick={onRelatedCarouselClick}
            shouldScroll={false}
          />
        )}
        {showRelatedContent && (!carouselTiles || carouselTiles.length === 0) && (
          <FocusDiv
            className="related-content-no-content"
            nav={{
              ...relatedContentNav,
              skip: !carouselTiles || carouselTiles.length === 0,
            }}
          >
            <span>We couldn’t find any content related to this</span>
          </FocusDiv>
        )}
      </div>
    </div>
  );
};

export default withFocus(RelatedContent);
