import xdk from '@accedo/xdk-core';
import { tts } from '@accedo/xdk-extensions-contrib';
const { ID: TTS } = tts;


export const ttsPlayback = async (playbackText = '', isCancel = false) => {
    if (playbackText === '') return;
    const tts = await xdk.extensionManager.getExtension(TTS);
    if (tts.isSpeaking() && !isCancel) {
        tts.cancel()
    }
    tts.speak(playbackText);
}

export const cancelTtsPlayback = async () => {
    const tts = await xdk.extensionManager.getExtension(TTS);
    if (tts.isSpeaking()) {
        tts.cancel()
    }
}