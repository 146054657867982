import { BehaviorSubject } from 'rxjs';
import {
  injectAppConfig,
  injectAudioPlayer,
  injectVideoPlayer,
  Logger,
  IProviderDescriptor,
} from '../servicelib';

import { getWebServiceConfig } from './service.config.web';

import AudioPlayer from '../web-audio-player/bin/web-audio-player';
import WebVideoPlayer from '../web-video-player/dist/web-video-player';
class AppConfig {
  constructor() {
    const webServiceConfig = getWebServiceConfig();
    Object.keys(webServiceConfig).forEach(property => {
      this[property] = webServiceConfig[property];
    });
  }
}

const serviceConfigProvider: IProviderDescriptor = {
  provide: AppConfig,
  useClass: AppConfig,
  singleton: true,
  deps: [],
};

const audioPlayer = new AudioPlayer({
  logger: Logger.getLogger('sxm-audio-player'),
});

const audioPlayerProvider: IProviderDescriptor = {
  provide: audioPlayer,
  useClass: audioPlayer,
  singleton: true,
  deps: [],
};

const videoPlayer = new (WebVideoPlayer as any)();

const videoPlayerProvider: IProviderDescriptor = {
  provide: videoPlayer,
  useClass: videoPlayer,
  singleton: true,
  deps: [],
};
export class SXMServiceLayerModule {
  public static proceedToInjection$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  public static injectAppConfig() {
    //Must only be done at app startup
    injectAppConfig(serviceConfigProvider);
    injectAudioPlayer(audioPlayerProvider);
    injectVideoPlayer(videoPlayerProvider);
    SXMServiceLayerModule.proceedToInjection$.next(true);
  }
}
