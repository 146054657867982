import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/provide-auth';
import { WelcomePageComponent } from '../pages/welcome/welcome-page.component';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { appRouteConstants } from './app.route.constants';

const unauhtenticatedPaths = [
  appRouteConstants.FT_WELCOME,
  appRouteConstants.AUTH.LOGIN,
  appRouteConstants.AUTH.LOGIN_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED,
];

let shouldRedirect = true;

export const ProtectedRoute = ({ children, ...rest }) => {
  const auth: any = useAuth();
  const history = useHistory();
  const location = useLocation();

  if (auth.userSession.authenticated && auth.userSession.username) {
    if (location.pathname === appRouteConstants.FT_WELCOME) {
      //Authenticated users shouldn't be able to reach the landing or welcome screens
      if (shouldRedirect) {
        history.replace(appRouteConstants.HOME_FORYOU);
        shouldRedirect = false;
      }

      return (
        <Route {...rest}>
          <HomePageComponent />
        </Route>
      );
    } else {
      return <Route {...rest}>{children}</Route>;
    }
  } else {
    if (unauhtenticatedPaths.some(route => location.pathname.includes(route))) {
      return <Route {...rest}>{children}</Route>;
    } else {
      if (shouldRedirect) {
        history.replace(appRouteConstants.FT_WELCOME);
        shouldRedirect = false;
      }

      return (
        <Route {...rest}>
          <WelcomePageComponent />
        </Route>
      );
    }
  }
};
