import {
  of,
  Subject,
  Observable,
  BehaviorSubject,
  concat,
  merge,
  Subscription,
} from 'rxjs';
import {
  map,
  filter,
  take,
  concatMap,
  switchMap,
  first,
  mergeMap,
  tap,
  share,
  //distinctUntilChanged,
} from 'rxjs/operators';
import {
  IAppConfig,
  IMediaAssetMetadata,
  IMediaEndPoint,
  IMediaPlayer,
  IProviderDescriptor,
} from '../../index';
import moment from 'moment';
import { IStartVideo } from './video-player.interface';
import { addProvider } from '../../service';
import { isSafari11 } from '../../util';
import { msToSeconds, secondsToMs } from '../../util';
import { EError } from '../../error/error.enum';
import { ContentTypes } from '../../service/types';
import { MediaPlayerConstants } from '../media-player.consts';
import { VideoPlayerConstants } from './video-player.consts';
import { PlayerTypes } from '../../service/types';
import { AppMonitorService } from '../../app-monitor';
import { ErrorService } from '../../error';
import { Logger } from '../../logger';
import { MediaTimeLine } from '../../tune';
import { MediaUtil } from '../media.util';
import { MediaPlayer } from '../media-player';
import { NoopService } from '../../noop';
import { TuneService } from '../../tune';
import { IMediaTrigger } from '../media-player.interface';
import { StorageService } from '../../storage';
import { BitrateHistoryLogService } from './bitrate-history/bitrate-history-log.service';
import { VideoPlayerConfigFactory } from './video-player.config.factory';
import { VideoPlayerEventMonitor } from './video-player.event-monitor';
import { VideoPlayerUtil } from './video-player.util';
import { VideoPlayerBufferUnderflowMonitor } from './video-player.buffer-underflow.monitor';
import {
  InitializationService,
  InitializationStatusCodes,
} from '../../initialization';
import { CurrentlyPlayingService } from '../../currently-playing/currently.playing.service';

import { PlayheadTimestampService } from '../playhead-timestamp.service';
import { SessionTransferService } from '../../sessiontransfer/session.transfer.service';
import { MediaTimeLineService } from '../../media-timeline/media.timeline.service';
import { Playhead, IPlayhead } from '../playhead.interface';
import { ChromecastPlayerConsts } from '../chromecastplayer/chromecast-player.consts';
import { ChromecastService } from '../../chromecast/chromecast.service';
//import { IMediaTrigger } from '../media-player.interface';
import WebVideoPlayer from '../../../../web-video-player/dist/web-video-player';
import { AudioPlayerConstants } from '../audioplayer';
import { LiveTimeService } from '../../livetime';
import { LiveTime } from '../../livetime/live-time.interface';
import { SettingsService } from '../../settings';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/11/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Manages video playback.
 */

export class VideoPlayerService extends MediaPlayer implements IMediaPlayer {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('VideoPlayerService');

  /**
   * Indicates the current state of the video player.
   */
  public playbackStateSubject = new BehaviorSubject(this.state);

  /**
   * Stream that emits a HLS URL for video playback indicating it's time to start playing video. This eliminates the
   * previous callback method that was used to kickoff video playback.
   */
  public startVideo$ = new BehaviorSubject(null);

  /**
   * Indicates when the video player has been created.
   */
  public playerCreated$: Observable<any> = null;

  /**
   * Indicates when the video player is ready for playback.
   */
  public playbackReady$: Observable<any> = null;

  /**
   * Indicates when the video player's initial playback for a given stream has started playing.
   */
  public initialPlay$: Observable<any> = null;

  /**
   * Indicates when the video player's initial playback for a given stream has started playing.
   */
  public initialPlayStarting$: Observable<any> = null;

  /**
   * Indicates when the video player is buffering video.
   */
  public isBuffering$: Observable<boolean> = null;

  /**
   * Indicates if the video player is in Idle state.
   */
  public isIdle$: Observable<boolean> = null;

  /**
   * Indicates when the video player is seeked video.
   */
  public seeked$: Observable<string> = null;

  /**
   * Indicates when a streaming asset is changed.
   */
  public assetChanged$: Subject<boolean> = new Subject();

  /**
   * Indicates when a streaming asset has started to stall.
   */
  public stalled$: Subject<string> = new Subject();

  /**
   * Stream indicating the volume was changed.
   */
  public volumeChanged$: Observable<number> = null;

  // TODO: BMR: 10/12/2017: We may not need this but in refactoring the playhead controls to be reusable it's necessary for video right now.
  private mediaAssetMetadata: IMediaAssetMetadata = {} as IMediaAssetMetadata;

  /**
   * Current state of the player.
   */
  private _state: string = VideoPlayerConstants.PRELOADING;

  /**
   * Setter for _state, will set backing store and trigger the playback state observable with the new state
   * @param state is the new state to set
   */
  private set state(state: string) {
    VideoPlayerService.logger.debug(`state( ${state} )`);
    this._state = state;
    this.playbackStateSubject.next(state);
  }

  /**
   * Getter for _state, only used internally, outside we should subscribe to playbackState
   * @returns the state for playback
   */
  private get state(): string {
    return this._state;
  }

  /**
   * Flag indicating video was paused by Safari when it was supposed to auto-play.
   */
  private wasAutoPausedBySafari: boolean = false;

  public videoAsset: any = {} as any;

  public playerParams: any = {} as any;

  private playerTypeLocal: string = '';

  /**
   * This Value used for casting to detect the playing/pause state.
   * Reason: Before CAF connects the R , HLS sending event as PAUSED ,
   * which is causing casting starts pause state instead of PLAYING.
   */
  private playerStatusLocal: string = '';

  private setVolumeSubscription: Subscription;

  public playhead$: Observable<IPlayhead>;

  /**
   * The last known (hopefully current) playhead value.
   */
  private playhead: IPlayhead = new Playhead();

  /**
   * caching the video zulu start time
   */
  public videoZuluStartTime: number = 0;

  /* For Free Tier Playback */
  public ftPlaybackComplete$: Observable<boolean> = null;
  private isFreeTier: boolean = false;

  /* Live time playpoint as indicated by the Discovery channels wallClock property*/
  public liveTime: LiveTime;

  /* To handle the playback errors */
  public playbackError$: Subject<any> = null;

  /* Flag that keeps track if the tune start difference with the current live playhead is greater than the 5 hours buffer */
  public isTuneStartOutOfBounds = false;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(VideoPlayerService, VideoPlayerService, [
      'IVideoPlayer',
      TuneService,
      NoopService,
      BitrateHistoryLogService,
      ErrorService,
      AppMonitorService,
      InitializationService,
      CurrentlyPlayingService,
      PlayheadTimestampService,
      StorageService,
      'IAppConfig',
      ChromecastService,
      SessionTransferService,
      MediaTimeLineService,
      VideoPlayerEventMonitor,
      VideoPlayerConfigFactory,
      VideoPlayerBufferUnderflowMonitor,
      LiveTimeService,
      SettingsService,
    ]);
  })();

  /**
   * Constructor.
   *
   * Sets up subscribers to observables.
   *
   * @param videoPlayer
   * @param {TuneService} tuneService
   * @param {NoopService} noopService
   * @param {BitrateHistoryLogService} bitrateHistoryLogService
   * @param {ErrorService} errorService
   * @param {AppMonitorService} appMonitorService
   * @param {InitializationService} initializationService
   * @param {CurrentlyPlayingService} currentlyPlayingService
   * @param {PlayheadTimestampService} playheadTimestampService
   * @param {StorageService} storageService
   * @param {IAppConfig} SERVICE_CONFIG
   * @param {SessionTransferService} sessionTransferService
   * @param {MediaTimeLineService} mediaTimeLineService
   * @param {ChromecastService} chromecastService
   * @param {VideoPlayerEventMonitor} videoPlayerEventMonitor
   * @param {VideoPlayerConfigFactory} videoPlayerConfigFactory
   * @param {VideoPlayerBufferUnderflowMonitor} videoPlayerBufferUnderflowMonitor
   * @param {LiveTimeService} liveTimeService
   * @param {SettingsService} settingsService
   */
  constructor(
    public videoPlayer,
    protected tuneService: TuneService,
    private noopService: NoopService,
    protected bitrateHistoryLogService: BitrateHistoryLogService,
    private errorService: ErrorService,
    private appMonitorService: AppMonitorService,
    private initializationService: InitializationService,
    protected currentlyPlayingService: CurrentlyPlayingService,
    protected playheadTimestampService: PlayheadTimestampService,
    private storageService: StorageService,
    private SERVICE_CONFIG: IAppConfig,
    private chromecastService: ChromecastService,
    protected sessionTransferService: SessionTransferService,
    protected mediaTimeLineService: MediaTimeLineService,
    protected videoPlayerEventMonitor: VideoPlayerEventMonitor,
    protected videoPlayerConfigFactory: VideoPlayerConfigFactory,
    protected videoPlayerBufferUnderflowMonitor: VideoPlayerBufferUnderflowMonitor,
    private liveTimeService: LiveTimeService,
    private settingsService: SettingsService,
  ) {
    super(
      tuneService,
      currentlyPlayingService,
      sessionTransferService,
      mediaTimeLineService,
    );

    this.playbackTypes = [
      ContentTypes.LIVE_AUDIO,
      ContentTypes.AOD,
      //ContentTypes.PODCAST,
      //ContentTypes.ADDITIONAL_CHANNELS,
      //ContentTypes.SEEDED_RADIO,
      ContentTypes.VOD,
      ContentTypes.LIVE_VIDEO,
    ];

    this.liveTimeService.liveTime$.pipe(take(1)).subscribe(liveTime => {
      this.liveTime = liveTime;
    });

    // Create an observable for the state of the video player.
    this.playbackState = this.playbackStateSubject;

    this.listenToMonitor();
    this.videoPlayerBufferUnderflowMonitor.setupUnderflowMonitor(this);

    //this.subscribeToStartVideo();
    this.subscribeToAutoplayBlocked();

    // Note: Do this last, because it could cause observables to trigger that may be dependent on the object
    // being completely set up
    this.setSubscribers();
  }

  /**
   * Forward observables from the event monitor to the outside world
   */
  private listenToMonitor() {
    this.playhead$ = this.videoPlayerEventMonitor.playhead$.pipe(
      map(this.updatePlayhead.bind(this)),
      filter((playhead: IPlayhead) => {
        return playhead.currentTime.zuluMilliseconds !== 0;
      }),
    );

    this.playerCreated$ = this.videoPlayerEventMonitor.playerCreated$;
    this.playbackReady$ = this.videoPlayerEventMonitor.playbackReady$;
    this.initialPlay$ = this.videoPlayerEventMonitor.initialPlay$;
    this.initialPlayStarting$ = this.videoPlayerEventMonitor.initialPlayStarting$;

    this.isBuffering$ = this.playbackStateSubject.pipe(
      map(
        state =>
          state === VideoPlayerConstants.SEEKING ||
          state === VideoPlayerConstants.PRELOADING,
      ),
    );

    this.isIdle$ = this.playbackStateSubject.pipe(
      map(() => this.state === VideoPlayerConstants.IDLE),
    );

    this.seeked$ = this.videoPlayerEventMonitor.seeked$;
    this.playbackComplete$ = this.videoPlayerEventMonitor.playbackComplete$.pipe(
      filter(() => !this.isFreeTier),
    ) as BehaviorSubject<boolean>;
    this.ftPlaybackComplete$ = this.videoPlayerEventMonitor.playbackComplete$.pipe(
      filter(() => this.isFreeTier),
    );
    this.volumeChanged$ = this.videoPlayerEventMonitor.volumeChanged$;
    this.assetChanged$ = this.videoPlayerEventMonitor.assetChanged$;
    this.stalled$ = this.videoPlayerEventMonitor.stalled$;

    merge(
      this.videoPlayerEventMonitor.playing$,
      this.videoPlayerEventMonitor.paused$,
    ).subscribe((event: string) => {
      this.setPlaybackStateFromEvent(event);
    });

    this.playhead$
      .pipe(
        filter(
          (/*val*/) =>
            this.playbackStateSubject.getValue() !==
              VideoPlayerConstants.PLAYING &&
            this.state !== VideoPlayerConstants.IDLE,
        ),
      )
      .subscribe((/*playhead: IPlayhead*/) => {
        this.setPlaybackStateFromEvent(WebVideoPlayer.EVENTS.PLAYING);
      });

    this.playhead$.subscribe(
      (playhead: IPlayhead) => {
        if (
          this.mediaTimeLine &&
          !MediaUtil.isLiveMediaType(this.mediaTimeLine.mediaType)
        ) {
          //If playing live audio, the playhead needs a different treatment, which is done on the updatePlayhead() method
          this.playheadTimestampService.playhead.next(playhead);
        }

        if (this.mediaType !== 'additionalchannels') {
          this.currentlyPlayingService.setCurrentPlayingData(
            playhead,
            VideoPlayerConstants.TYPE,
          );
        }
      },
      err => {
        console.log(err);
      },
    );

    this.playbackComplete$
      .pipe(filter((/*val*/) => !this.isPaused()))
      .subscribe(() => {
        this.setPlaybackStateFromEvent(WebVideoPlayer.EVENTS.PAUSED);
      });

    this.playbackError$ = this.videoPlayerEventMonitor.playbackError$;
    this.playbackError$.subscribe(this.handleError.bind(this));
  }

  /**
   * Initializes the service by setting the internal video player reference that's used
   * to control video playback.
   */
  public init(videoWrapperElement: any, flashId?: string): void {
    this.videoPlayer.init(
      videoWrapperElement,
      {
        ...this.videoPlayerConfigFactory.getConfig(),
      },
      flashId,
    );
    this.videoPlayerEventMonitor.startMonitor(this.videoPlayer);
  }

  /**
   * Handles failed playback and creates a stream indicating such.
   *
   * NOTE: We put the player into a paused state instead of stopped so we can leave the
   * video player up in the UI while we try to recover. This keeps the video player from
   * hiding and showing again after recovery.
   */
  public playbackFailed(): void {
    VideoPlayerService.logger.debug('playbackFailed()');

    this.pause();
    this.state = VideoPlayerConstants.ERROR;
  }

  /**
   * Updates an existing video player with a new URL to play and provides and optional zero-based seconds start time.
   */
  public subscribeToStartVideo(): void {
    VideoPlayerService.logger.debug(
      'subscribeToStartVideo( Waiting for video config and valid HLS video URL. )',
    );

    const obs = this.startVideo$.pipe(
      filter((startVideo: IStartVideo) => !!startVideo && !!startVideo.url),
      switchMap((startVideo: IStartVideo) => {
        return this.loadAsset(startVideo).pipe(
          switchMap(autoPlayPolicy => {
            if (startVideo.autoPlay) {
              if (autoPlayPolicy.willAutoplay) {
                return of(true);
              }
              return concat(
                this.warmUp().pipe(take(1)),
                this.play().pipe(take(1)),
              );
            } else {
              this.setVolume(this.initialVolume);
              this.state = VideoPlayerConstants.PAUSED;
              return of(this.state);
            }
          }),
        );
      }),
    );

    obs.subscribe(
      () => {},
      error => {
        console.error('main obs errored out. this is a problem');
        console.log(error);
      },
    );
  }

  private subscribeToAutoplayBlocked(): void {
    this.videoPlayerEventMonitor.autoplayBlocked$.subscribe(() => {
      if (isSafari11(this.SERVICE_CONFIG.deviceInfo)) {
        this.errorService.handleError({ type: EError.SAFARI_AUTO_PLAY_PAUSE });
      } else {
        // all other browsers
        this.errorService.handleError({ type: EError.AUTOPLAY_DENIED });
      }
      /**
       * TODO: Need to consider to move this logic to video player sdk.
       */
      this.state = VideoPlayerConstants.PAUSED;
    });
  }

  public loadAsset(startVideo: IStartVideo, freeTier = false): Observable<any> {
    if (this.videoPlayer) {
      this.videoPlayer.loadAsset(startVideo, {
        ...this.videoPlayerConfigFactory.getConfig(),
        autoStartLoad: false,
        debug: false,
        nudgeOffset: 1,
      });

      //We hear for playback errors here since we know for sure that the HLS instance has been created.
      this.videoPlayerEventMonitor.listeForPlaybackErrors();

      this.videoPlayer.player.hls.on('hlsManifestParsed', () => {
        if (
          this.mediaType &&
          MediaUtil.isLiveMediaType(this.mediaTimeLine.mediaType)
        ) {
          const nowInMs = moment().valueOf();
          let liveDeltaInSeconds =
            (nowInMs - this.liveTime.zuluMilliseconds) / 1000;
          //LARGE manifest file will have a duration always of 18450 when first loading. This can be considered the position of the livepoint;
          const livePositionInSeconds = 18450;

          if (
            startVideo.startTime !== 0 &&
            this.settingsService.isTuneStartOn()
          ) {
            //Logic that handles the Tune Start initialization point from the settings
            const tuneStartInMs =
              this.liveTime.zuluMilliseconds - startVideo.startTime;
            liveDeltaInSeconds += tuneStartInMs / 1000;

            //Updates the playhead that wil be emitted to the consumeService.
            this.playhead.currentTime.zuluMilliseconds = startVideo.startTime;
            this.playhead.currentTime.zuluSeconds = startVideo.startTime / 1000;
            this.playhead.startTime.zuluMilliseconds = startVideo.startTime;
            this.playhead.isTuningToTuneStart = true;

            //At this point, no fragment has loaded. Therefore, we need to assume that the current playback
            //timestamp is the TUNE START position of the stream (which will always be behind the real time and
            //the livepoint time).
            this.playheadTimestampService.playhead.next(this.playhead);
            this.videoPlayerEventMonitor.programDateTime = this.playhead.currentTime.zuluMilliseconds;
            this.videoPlayerEventMonitor.playhead$.next(this.playhead);

            if (liveDeltaInSeconds > livePositionInSeconds) {
              liveDeltaInSeconds = livePositionInSeconds;
              this.isTuneStartOutOfBounds = true;
            } else {
              this.isTuneStartOutOfBounds = false;
            }
          } else {
            this.isTuneStartOutOfBounds = false;

            //Updates the playhead that wil be emitted to the consumeService.
            this.playhead.currentTime.zuluMilliseconds =
              nowInMs - (nowInMs - this.liveTime.zuluMilliseconds);
            this.playhead.currentTime.zuluSeconds =
              (nowInMs - (nowInMs - this.liveTime.zuluMilliseconds)) / 1000;
            this.playhead.startTime.zuluMilliseconds = this.playhead.currentTime.zuluMilliseconds;
            this.playhead.isTuningIn = true;

            //At this point, no fragment has loaded. Therefore, we need to assume that the current playback
            //timestamp is the live position of the stream. This also updates the progress bar and metadata
            this.currentlyPlayingService.setCurrentPlayingData(
              this.playhead,
              AudioPlayerConstants.TYPE, //TODO: Pass video when content type is Video
            );
            this.playheadTimestampService.playhead.next(this.playhead);
            this.videoPlayerEventMonitor.programDateTime = this.playhead.currentTime.zuluMilliseconds;
            this.videoPlayerEventMonitor.playhead$.next(this.playhead);
          }

          this.videoPlayer.player.hls.startLoad(
            livePositionInSeconds - liveDeltaInSeconds,
          );
        } else {
          const startTime = startVideo.startTime || -1;
          this.videoPlayer.player.hls.startLoad(startTime);
        }
      });
    }

    this.isFreeTier = freeTier;
    return freeTier
      ? concat(this.warmUp().pipe(take(1)), this.play().pipe(take(1)))
      : this.playbackReady$.pipe(
          filter(val => !!val),
          take(1),
        );
  }

  /**
   * Watch for playback complete for On Demand content so we can perform the consume "TuneOut"
   * API call.
   *
   * Also disable consumes for this player until the user seeks or starts playback for this
   * video again. This is done to ensure errant consume calls aren't made after we perform
   * the all important last one consume "TuneOut".
   */
  protected monitorPlaybackComplete(): void {
    // The super method is used to allow replays of the same content.
    super.monitorPlaybackComplete();

    this.playbackComplete$
      .pipe(
        filter(
          (bool: boolean) =>
            bool === true && !this.isLive() && !this.isFreeTier,
        ),
      )
      .subscribe(() => {
        VideoPlayerService.logger.debug('monitorPlaybackComplete( Complete )');
        this.state = VideoPlayerConstants.FINISHED;
      });
  }

  /**
   * Sets the metadata required for video playback.
   * @param {IMediaAssetMetadata} mediaAssetMetadata
   */
  public setMediaAssetMetadata(mediaAssetMetadata: IMediaAssetMetadata): void {
    this.mediaAssetMetadata = mediaAssetMetadata;
  }

  /**
   * Starts video playback and returns the current state as "playing" when complete.
   * @returns {Observable<any>}
   */
  public play(): Observable<any> {
    const obs = this.sessionTransfer().pipe(
      switchMap(() => {
        if (this.videoPlayer) {
          this.videoPlayer.play();
        }
        return this.playbackStateSubject.pipe(
          filter(state => {
            this.playerStatusLocal = state;
            return state === VideoPlayerConstants.PLAYING;
          }),
        );
      }),
      take(1),
    );
    return obs;
  }

  /**
   * Pauses video playback and returns the current state as "paused" when complete.
   * initial playback due to auto play being off, then we do not want to report this consume.
   * @returns {Observable<string>}
   */
  public pause(): Observable<string> {
    const obs = this.sessionTransfer().pipe(
      mergeMap(() => {
        if (this.videoPlayer) {
          this.videoPlayer.pause();
        }
        return this.playbackStateSubject.pipe(
          filter(state => {
            this.playerStatusLocal = state;
            return (
              state === VideoPlayerConstants.PAUSED ||
              state === VideoPlayerConstants.IDLE ||
              state === VideoPlayerConstants.FINISHED
            );
          }),
        );
      }),
      take(1),
    );
    return obs;
  }

  /**
   * Resumes video playback and returns the current state as "playing" when complete.
   * @returns {Observable<any>}
   */
  public resume(): Observable<any> {
    return this.sessionTransfer().pipe(
      take(1),
      switchMap(() => {
        this.videoPlayer.resume();

        return this.playbackStateSubject.pipe(
          filter(state => {
            this.playerStatusLocal = state;
            return state === VideoPlayerConstants.PLAYING;
          }),
        );
      }),
    );
  }

  /**
   * Pauses or resumes video playback based on the payer's current state. Returns the current state.
   */
  public togglePausePlay(): Observable<string> {
    const playbackState = this.state;

    //IF we ever togglePausePlay, we will set the wasAutoPausedBySafari to true, so that it we will never trigger the
    // AutoPlay modal for the Safari users
    this.wasAutoPausedBySafari = true;

    switch (playbackState) {
      case VideoPlayerConstants.FINISHED:
      case VideoPlayerConstants.ERROR: {
        VideoPlayerService.logger.debug(
          'togglePausePlay( Finished or Failure >> Retune )',
        );
        return this.retune();
      }
      case VideoPlayerConstants.PLAYING: {
        VideoPlayerService.logger.debug('togglePausePlay( Playing >> Pause )');
        return this.pause();
      }
      case VideoPlayerConstants.PAUSED:
      case VideoPlayerConstants.PRELOADING:
      case VideoPlayerConstants.PAUSED_BY_SAFARI: {
        VideoPlayerService.logger.debug('togglePausePlay( Paused >> Play )');
        return this.warmUp().pipe(
          concatMap(() => this.resume()),
          take(1),
        );
      }
      case VideoPlayerConstants.IDLE: {
        const seekTime = this.playheadTimestampService.playhead.getValue()
          .currentTime.seconds;
        return this.sessionTransfer().pipe(
          take(1),
          switchMap(() => {
            this.startVideo(true, seekTime);
            return this.state;
          }),
        );
      }
      default: {
        VideoPlayerService.logger.warn(
          `togglePausePlay( Unhandled state: ${this.state} )`,
        );
      }
    }
  }

  /**
   * Seeks video playback and returns the current state as "playing" when complete.
   * @param {number} timestamp - The place in time to seek in seconds.
   * @returns {Observable<any>}
   */
  public seek(timestamp: number, isLive: boolean = false): Observable<any> {
    const obs = this.sessionTransfer().pipe(
      take(1),
      switchMap(() => {
        let livePlayheadInSeconds = 0;
        let livePlayheadInMs = 0;
        if (isLive) {
          livePlayheadInSeconds =
            Math.abs(this.videoPlayerEventMonitor.start - timestamp) +
            this.videoPlayerEventMonitor.programDateTime / 1000;
          livePlayheadInMs = livePlayheadInSeconds * 1000;
        }

        let zeroBasedSeconds = isLive
          ? timestamp
          : this.convertZuluToZeroBasedSeconds(timestamp);

        this.lastMediaPlayerPlayheadZulu = isLive
          ? livePlayheadInMs
          : timestamp;
        const playhead = new Playhead();
        playhead.currentTime.zuluMilliseconds = isLive
          ? livePlayheadInMs
          : timestamp;
        this.currentlyPlayingService.setCurrentPlayingData(
          playhead,
          VideoPlayerConstants.TYPE,
        );

        if (this.videoPlayer) {
          VideoPlayerService.logger.debug(
            `seek( zuluTime = ${
              isLive ? livePlayheadInMs : timestamp
            } zeroTime = ${zeroBasedSeconds})`,
          );

          // Save the seek time for BI consume purposes.
          this.lastSeekTime = isLive ? livePlayheadInMs : timestamp;

          const oldState = this.state;

          this.state = VideoPlayerConstants.SEEKING;

          if (zeroBasedSeconds < 0 && isLive) {
            //This value can't be hardcoded to 0 as we always need to consider the stream's live edge.
            //Removing 900 seconds just to be sure that we're seeking to an existing fragment. This is mostly
            //for the Howard Stern channels whose shows starting point go beyond 5 hours.
            zeroBasedSeconds =
              this.videoPlayerEventMonitor.currentLiveDuration - 17550;
          }

          this.videoPlayer.seek(zeroBasedSeconds);

          return this.seeked$.pipe(
            map(() => {
              this.playerStatusLocal = oldState;
              return oldState;
            }),
          );
        } else {
          VideoPlayerService.logger.error(
            `Videoplayer service:seek Cannot find Video marker for the ${timestamp}. )`,
          );
          return of(VideoPlayerConstants.ERROR);
        }
      }),
      take(1),
      switchMap((state: string) => {
        this.state = state;
        return of(this.state);
      }),
    );

    return obs;
  }

  /**
   *
   * @param {string} id
   * @returns {Observable<any>}
   */
  public stop(/*id?: string | number*/): Observable<string> {
    VideoPlayerService.logger.debug('stop()');

    if (
      !this.videoPlayer ||
      this.mediaAssetMetadata.mediaId ===
        VideoPlayerConstants.RESET_MP4_VIDEO_ID
    ) {
      return of(this.state);
    }

    this.videoPlayer.stop();

    return this.playbackStateSubject.pipe(
      filter(state => {
        return (
          state === VideoPlayerConstants.IDLE ||
          state === VideoPlayerConstants.FINISHED
        );
      }),
    );
  }

  /**
   * Accessor to the player's ID.
   * @returns {boolean}
   */
  public getId(): string {
    // return this.videoPlayer ? this.videoPlayer.getPlayerId() : "";
    // TODO: BMR: 11/08/2017: Need to have a valid ID on the player.
    return this.videoPlayer ? this.videoPlayer.id : 'Unknown ID';
  }

  /**
   * Gets the duration for the currently playing video content in milliseconds.
   * @returns {number}
   */
  public getDuration(): number {
    return secondsToMs(this.getDurationInSeconds());
  }

  /**
   * Getter for the zero-based playhead timestamp in milliseconds.
   * @returns {number}
   */
  public getPlayheadTime(): number {
    return this.playhead.currentTime.milliseconds;
  }

  /**
   * Getter for the zero-based playhead timestamp in seconds.
   * @returns {number}
   */
  public getPlayheadTimeInSeconds(): number {
    return this.playhead.currentTime.seconds;
  }

  public getPlayheadZuluTime(): number {
    return this.playhead.currentTime.zuluMilliseconds;
  }

  public convertZeroBasedSecondsToZulu(seconds: number): number {
    const playHeadTime = secondsToMs(seconds);

    if (this.videoZuluStartTime === 0) {
      return 0;
    }
    return this.videoZuluStartTime + playHeadTime;
  }

  /**
   * Getter for the initial playhead value of the current video in seconds.
   * @returns {number}
   *
   * TODO: BMR: 10/25:2017: Right now there is no `getPlayheadStartTime()` on the player so we'll need a way to save
   *     it here or other.
   */
  public getPlayheadStartTime(): number {
    try {
      return this.videoPlayer ? this.videoPlayer.getPlayheadStartTime() : 0;
    } catch (error) {
      VideoPlayerService.logger.warn(`getPlayheadStartTime( ${error} )`);
    }
  }

  /**
   * Getter for the UNIX-based/Epoch initial playhead timestamp in milliseconds.
   * @returns {number}
   */
  public getPlayheadStartZuluTime(): number {
    return MediaUtil.isLiveMediaType(this.mediaType)
      ? this.playhead.startTime.zuluMilliseconds
      : this.getEpisodeStartTimeZulu();
  }

  /**
   * Used to get playback type based on Live or VOD.
   * @returns {string}
   */
  public getPlaybackType(): string {
    if (this.isLive()) {
      return VideoPlayerConstants.LIVE;
    } else if (
      MediaUtil.isVideoMediaTypeOnDemand(this.mediaType) ||
      MediaUtil.isAudioMediaTypeOnDemand(this.mediaType)
    ) {
      return VideoPlayerConstants.VOD;
    }

    return '';
  }

  /**
   * Getter for the state of the player.
   * @returns {string}
   */
  public getState(): string {
    return this.state;
  }

  /**
   * Getter for the type of player.
   * @returns {string}
   */
  public getType(): string {
    return VideoPlayerConstants.TYPE;
  }

  /**
   * Getter for the media asset metadata.
   *
   * TODO: BMR: 11/02/2017: This may not be necessary.
   *
   * @returns {IMediaAssetMetadata}
   */
  public getMediaAssetMetadata(): IMediaAssetMetadata {
    return this.mediaAssetMetadata;
  }

  /**
   * Destroys the player.
   */
  public destroy(): Observable<string> {
    VideoPlayerService.logger.debug('destroy()');

    this.videoPlayer.destroy();
    return of('destroyed');
  }

  /**
   * Indicates if the player is playing.
   * @returns {boolean}
   */
  public isPlaying(): boolean {
    return this.state === VideoPlayerConstants.PLAYING;
  }

  /**
   * Indicates if the player is paused.
   * @returns {boolean}
   */
  public isPaused(): boolean {
    return this.state === VideoPlayerConstants.PAUSED;
  }

  /**
   * Indicates if the player was paused by Safari.
   * @returns {boolean}
   */
  public isPausedBySafari(): boolean {
    return this.state === VideoPlayerConstants.PAUSED_BY_SAFARI;
  }

  /**
   * Indicates if the player is stopped.
   * @returns {boolean}
   */
  public isStopped(): boolean {
    return (
      this.state === VideoPlayerConstants.FINISHED ||
      this.state === VideoPlayerConstants.IDLE
    );
  }

  /**
   * Indicates if the player is stopped.
   * @returns {boolean}
   */
  public isPreLoading(): boolean {
    return this.state === VideoPlayerConstants.PRELOADING;
  }

  /**
   * Indicates if the player is finished.
   * @returns {boolean}
   */
  public isFinished(): boolean {
    return this.state === VideoPlayerConstants.FINISHED;
  }

  /**
   * Indicates the player volume
   * @returns {number}
   */
  public getVolume(): number {
    const volume = this.videoPlayer ? this.videoPlayer.getVolume() : 0;

    return MediaUtil.isVolumeValid(volume, 1)
      ? volume * MediaPlayerConstants.MAX_VOLUME_VALUE
      : NaN;
  }

  /**
   * Sets the volume on video player
   * Setting the volume is an asynchronous
   * operation so we need to return an observable.
   * @param {number} volume - volume between 0 and 100.
   */
  public setVolume(volume: number): void {
    if (isNaN(this.initialVolume)) {
      volume = !isNaN(volume) ? volume : MediaPlayerConstants.MID_VOLUME_VALUE;
    }
    // Make sure the requested volume is: 0 <= volume <= 100;
    let adjustedVolume =
      volume < MediaPlayerConstants.MIN_VOLUME_VALUE
        ? MediaPlayerConstants.MIN_VOLUME_VALUE
        : volume > MediaPlayerConstants.MAX_VOLUME_VALUE
        ? MediaPlayerConstants.MAX_VOLUME_VALUE
        : volume;

    // Make the volume between 0 and 1.
    adjustedVolume =
      adjustedVolume === MediaPlayerConstants.MIN_VOLUME_VALUE
        ? MediaPlayerConstants.MIN_VOLUME_VALUE
        : adjustedVolume / MediaPlayerConstants.MAX_VOLUME_VALUE;

    const doneObs = this.volumeChanged$.pipe(
      filter(vol => {
        return vol === adjustedVolume;
      }),
    );

    if (this.setVolumeSubscription) {
      this.setVolumeSubscription.unsubscribe();
    }

    this.setVolumeSubscription = this.playbackReady$
      .pipe(
        filter(playbackReady => !!playbackReady && !this.isFreeTier),
        tap(() => {
          this.videoPlayer.setVolume(adjustedVolume);
        }),
        concatMap(() => doneObs),
        take(1),
      )
      .subscribe();
  }

  /**
   * Needed to override parent method.
   * Prefer never to call this. Muting is a client "idea," not a video player idea.
   * Video player should only know about setting volumes.
   * this.isMuted and this.mutedVolume cause a lot of confusion and bugs
   * So I am just not calling this method if I can help it.
   * instead setting volume to 0 where appropriate.
   * TODO: Jordan D. Nelson 092018 - remove these methods off the mediaplayer
   * and into the volume service.
   */
  public mute(): void {
    VideoPlayerService.logger.debug(
      `mute( Last known volume: ${this.mutedVolume / 100} )`,
    );
    this.isMuted = true;
    this.setVolume(0);
  }
  /**
   * Needed to override method.
   * Prefer never to call this. Muting is a client "idea" not a video player idea.
   * Video player should only know about setting volumes.
   * this.isMuted and this.mutedVolume cause a lot of confusion and bugs.
   * (For more see description for mute().)
   */
  public unmute(): void {
    VideoPlayerService.logger.debug(
      `unmute( Last known volume: ${this.mutedVolume / 100} )`,
    );
    this.isMuted = false;
    this.setVolume(this.mutedVolume);
  }

  /**
   * Used to determine if the player is live.
   * @returns {boolean}
   */
  public isLive(): boolean {
    return (
      MediaUtil.isVideoMediaTypeLive(this.mediaType) ||
      MediaUtil.isAudioMediaTypeLive(this.mediaType)
    );
  }

  /**
   * Set up subscribers to observables.
   */
  protected setSubscribers(): void {
    super.setSubscribers();
    VideoPlayerService.logger.debug('setSubscribers()');
  }

  /**
   * Once tuneMediaTimeLine available, updates the Media end point urls and starts the video.
   * @param {MediaTimeLine} mediaTimeLine
   * @param {IMediaTrigger} mediaTrigger
   */
  protected onNewMedia(
    mediaTimeLine: MediaTimeLine,
    mediaTrigger: IMediaTrigger,
  ) {
    if (this.isCurrentPlayer(mediaTimeLine.mediaType)) {
      if (mediaTrigger.isNewMediaId) {
        this.videoPlayerEventMonitor.failOverToSecondaryStream = false;
      }

      this.mediaAssetMetadata.mediaId = mediaTimeLine.mediaId
        ? mediaTimeLine.mediaId
        : '';

      if (mediaTimeLine.playerType === PlayerTypes.REMOTE) {
        if (this.state === VideoPlayerConstants.IDLE) {
          return;
        }
        this.chromecastService.autoplay$.next(
          this.playerStatusLocal === VideoPlayerConstants.PLAYING,
        );
        if (this.videoPlayer) {
          this.videoPlayer.stop();
        }
        this.state = VideoPlayerConstants.IDLE;
        this.playerTypeLocal = mediaTimeLine.playerType;
      } else if (
        this.playerTypeLocal === PlayerTypes.REMOTE &&
        mediaTimeLine.playerType === PlayerTypes.LOCAL
      ) {
        this.playheadTimestampService.playhead
          .pipe(first())
          .subscribe((/*playhead*/) => {
            this.playerTypeLocal = '';
          });
      } else {
        VideoPlayerService.logger.debug(
          `onMediaTimeLineSuccess( ID: ${mediaTimeLine.mediaId} )`,
        );

        const currentMediaType: string = this.mediaType;
        const isNewMediaType: boolean =
          currentMediaType !== mediaTimeLine.mediaType;

        const startVideo: boolean = true; //Hardcoding it to true as on Comcast devices this is the only player type we can use
        const stopVideo: boolean = isNewMediaType && this.isPlaying();
        const autoPlay: boolean =
          !mediaTimeLine.isDataComeFromResume ||
          mediaTimeLine.isDataComeFromResumeWithDeepLink;

        const nowInMs = moment().valueOf();
        const liveDeltaInMs = this.liveTime?.zuluMilliseconds
          ? nowInMs - this.liveTime?.zuluMilliseconds
          : 140000;

        let startTime: number = 0;

        if (
          (mediaTimeLine.mediaType === ContentTypes.AOD ||
            mediaTimeLine.mediaType === ContentTypes.PODCAST) &&
          mediaTimeLine.startTime !== null
        ) {
          //Start time is provided in ms, but we need it in seconds
          startTime = mediaTimeLine.startTime / 1000;
        } else if (MediaUtil.isLiveMediaType(mediaTimeLine.mediaType)) {
          startTime = nowInMs - liveDeltaInMs;
        } else {
          startTime = this.mediaTimeLineService.getPausePointVideoStartTime();
        }

        if (stopVideo) {
          /*
          concat(this.stop(), this.destroy())
            .pipe(take(1))
            .subscribe();
            */
          concat(this.stop())
            .pipe(take(1))
            .subscribe();
        }

        if (startVideo) {
          this.liveTimeService.liveTime$.pipe(take(1)).subscribe(liveTime => {
            this.liveTime = liveTime;
          });

          const playhead = new Playhead();
          playhead.currentTime.zuluMilliseconds = startTime;
          const isVideo: boolean = MediaUtil.isVideoMediaType(
            mediaTimeLine.mediaType,
          );

          this.currentlyPlayingService.setCurrentPlayingData(
            playhead,
            isVideo ? VideoPlayerConstants.TYPE : AudioPlayerConstants.TYPE,
          );
          this.videoZuluStartTime = isVideo
            ? this.getVideoStartTimeZulu()
            : this.getEpisodeStartTimeZulu();

          console.info(this.mediaTimeLine.hlsConsumptionInfo);

          this.startVideo(autoPlay, startTime);
        } else {
          this.mediaTimeLine = null;
        }
      }
    } else if (mediaTimeLine.mediaType !== 'additionalchannels') {
      /*
      concat(this.stop(), this.destroy())
        .pipe(take(1))
        .subscribe();
        */
      concat(this.stop())
        .pipe(take(1))
        .subscribe();
    }
  }

  /**
   * Used to start video playback.
   */
  private startVideo(autoPlay: boolean, startTime: number = 0): void {
    this.initializationService.initState
      .pipe(
        // wait for app to be RUNNING
        filter(
          (initState: string) =>
            initState === InitializationStatusCodes.RUNNING,
        ),
        mergeMap(() => this.sessionTransferService.sessionClaimed),
        // wait for app to own the user session
        filter((sessionClaimed: boolean) => sessionClaimed === true),
        mergeMap(() => this.chromecastService.state$),
        // wait until Chromecast is NOT connected
        filter(
          (castState: string) =>
            castState !== ChromecastPlayerConsts.STATE.CONNECTED,
        ),
        mergeMap(() => this.playerCreated$),
        filter(playerCreated => {
          return !!playerCreated;
        }),
        take(1),
      )
      .subscribe(() => {
        let url: string = '';

        /**
         * Search through the urls that we have on the media time line to find one that is https and HLS.  This is
         * what we will use for playback.  If nothing is found then the video above will be played
         */
        this.mediaTimeLine.mediaEndPoints.forEach(
          (endpoint: IMediaEndPoint) => {
            if (endpoint.name === MediaPlayerConstants.HLS) {
              if (!this.videoPlayerConfigFactory.livePrimaryHostname) {
                VideoPlayerService.logger.error(
                  `startVideo( The HLS domain from the API's config settings for video is invalid:
                                ${this.videoPlayerConfigFactory.livePrimaryHostname} )`,
                );

                // TODO: REMOVE: BMR: 04/04/2018: Used during live video development to alert all that the API didn't send video URLs.
                alert(`Can't play video because the HLS domain from the API's config settings for video is invalid:
                                   ${this.videoPlayerConfigFactory.livePrimaryHostname}`);
              }
              url = VideoPlayerUtil.mapUrl(
                endpoint.url,
                this.videoPlayerConfigFactory.livePrimaryHostname,
                this.SERVICE_CONFIG.deviceInfo.clientDeviceId,
              );
            } else if (
              endpoint.name === AudioPlayerConstants.PRIMARY_END_POINT &&
              !this.videoPlayerEventMonitor.failOverToSecondaryStream
            ) {
              if (
                endpoint.position &&
                endpoint.position.positionType === 'TUNE_START'
              ) {
                startTime = endpoint.position.zuluTimestamp;
              }

              url = VideoPlayerUtil.mapUrlForAudio(
                endpoint.url,
                this.videoPlayerConfigFactory.mediaHostnames,
                this.SERVICE_CONFIG.deviceInfo.clientDeviceId,
              );
            } else if (
              endpoint.name === AudioPlayerConstants.SECONDARY_END_POINT &&
              this.videoPlayerEventMonitor.failOverToSecondaryStream
            ) {
              //Setting this flag to null to differentiate when we need to change back to the primary url but also allow the primary to play again
              //if for any reason a change in channel or media is made.
              this.videoPlayerEventMonitor.failOverToSecondaryStream = null;
              autoPlay = true;

              url = VideoPlayerUtil.mapUrlForAudio(
                endpoint.url,
                this.videoPlayerConfigFactory.mediaHostnames,
                this.SERVICE_CONFIG.deviceInfo.clientDeviceId,
              );
            }
          },
        );

        if (!url) {
          VideoPlayerService.logger.error(
            'startVideo( There are no matching video URLs. )',
          );
        }

        VideoPlayerService.logger.debug(
          `startVideo( autoPlay = ${autoPlay}, startTime = ${startTime}, URL = ${url} )`,
        );

        // Before starting the new video asset - setting the state to PRELOADING.
        // This is a beautiful line of code.  Gets our state initialized.
        this.state = VideoPlayerConstants.PRELOADING;

        // Because we use BehaviorSubject for this event,
        // when tuning to a new asset, please
        // emit falsey :) so that when
        // filters() fire in the starting process,
        // it's because the behavior subject is ACTUALLY
        // going from falsey -> truthy, rather than just
        // the filters subscribing
        // to a truthy BehaviorSubject
        // which would be baloney.
        this.videoPlayerEventMonitor.playbackReady$.next(null);

        const startVideo: IStartVideo = {
          url: url,
          autoPlay: autoPlay,
          startTime: startTime,
          type: 'application/x-mpegURL',
        };

        this.startVideo$.next(startVideo);
      });
  }

  /**
   * Warms up the video player by informing the video player
   * that a user action was initiated.
   * True if the unmute was from a user click, false otherwise
   */
  public warmUp(): Observable<string> {
    this.videoPlayer.warmUp();
    return of('warmed up');
  }

  /**
   * Pass a web video player event
   * and set the state based on that.
   * @param event
   */
  public setPlaybackStateFromEvent(event: string): void {
    if (this.state === VideoPlayerConstants.FINISHED) {
      return;
    }
    let newState = this.state;
    if (this.playerType === PlayerTypes.REMOTE) {
      newState = VideoPlayerConstants.IDLE;
    }
    switch (event) {
      case WebVideoPlayer.EVENTS.PAUSED:
        newState = VideoPlayerConstants.PAUSED;
        break;
      case WebVideoPlayer.EVENTS.PLAYING:
        newState = VideoPlayerConstants.PLAYING;
        break;
    }
    this.state = newState;
  }

  /**
   * Updates the playhead value for the player and broadcasts this change to any listening modules.
   */
  private updatePlayhead(playhead: IPlayhead): IPlayhead {
    const zuluMilliseconds = this.convertZeroBasedSecondsToZulu(
      playhead.currentTime.seconds,
    );

    if (
      this.mediaTimeLine &&
      MediaUtil.isLiveMediaType(this.mediaTimeLine.mediaType)
    ) {
      //Adds the live offset so metadata can display correctly
      let livePlayhead =
        Math.abs(
          this.videoPlayerEventMonitor.start - playhead.currentTime.seconds,
        ) +
        this.videoPlayerEventMonitor.programDateTime / 1000;

      if (playhead.isTuningToTuneStart || playhead.isTuningIn) {
        //When tuning to a channel when the TUNE START option is enabled, we want to ensure that the timestamp registered
        //in the consumeStreamDate always reflects the TUNE_START timestamp provided by the api. This condition will only execute once
        //every time the user is tuning to a new live channel.
        //The same goes for tuning in a channel in with the TUNE START option disabled. In this case, the playhead.startTime and playhead.currentTime
        //should be the same ones, so that the maerkerStart + tuneIn event fires. The only way to guarantee this is by having the same timestamp at
        //the beginning by enforcing this. This replicates the behavior from the original audio player implementation, where playhead.startTime would
        //represent the star time position of the playback when tuning on a channel. In the opriginal implementation, both currentTime and startTime would
        //would always match for live initially.
        livePlayhead = this.videoPlayerEventMonitor.programDateTime / 1000;
      }

      playhead.currentTime.zuluMilliseconds = secondsToMs(livePlayhead);
      playhead.currentTime.zuluSeconds = livePlayhead;

      this.playheadTimestampService.playhead.next(playhead);
    } else {
      playhead.currentTime.zuluMilliseconds = zuluMilliseconds;
      playhead.currentTime.zuluSeconds = msToSeconds(zuluMilliseconds);
    }

    this.playhead = playhead;

    return playhead;
  }

  private handleError(errorData) {
    const { type, details } = errorData;
    const currentLevel = this.videoPlayer.player.hls.currentLevel;
    const nextLoadLevel = this.videoPlayer.player.hls.nextLoadLevel;
    const loadLevel = this.videoPlayer.player.hls.loadLevel;
    const LEVEL_32_KBPS = 0; //
    const LEVEL_64_KBPS = 1;
    const LEVEL_256_KBPS = 2;

    if (
      details === 'manifestLoadError' ||
      details === 'manifestLoadTimeOut' ||
      details === 'manifestParsingError'
    ) {
      //If the manifest can't be read, HLS won't retry parsing the file. In that case we need to immediately trigger a failover.
      this.videoPlayerEventMonitor.fragmentErrorRetries = 3;
    }

    if (
      (type === 'networkError' || type === 'level' || type === 'otherError') &&
      (details === 'fragLoadError' ||
        details === 'fragLoadTimeOut' ||
        details === 'levelLoadError' ||
        details === 'levelLoadTimeOut' ||
        details === 'levelSwitchError' ||
        details === 'manifestLoadError' ||
        details === 'manifestLoadTimeOut' ||
        details === 'manifestParsingError')
    ) {
      this.videoPlayerEventMonitor.fragmentErrorRetries += 1;

      if (this.videoPlayerEventMonitor.fragmentErrorRetries > 3) {
        if (
          nextLoadLevel === LEVEL_32_KBPS ||
          details === 'manifestLoadError' ||
          details === 'manifestLoadTimeOut' ||
          details === 'manifestParsingError'
        ) {
          if (this.videoPlayerEventMonitor.failOverToSecondaryStream === null) {
            //If we reach this point, that means that that failover to the 32k stream also failed. We need to change to the primary URL again
            this.videoPlayerEventMonitor.failOverToSecondaryStream = false;
          } else {
            //If we reach this point, that means that that failover to the 32k stream also failed. We need to change to the secondary URL
            this.videoPlayerEventMonitor.failOverToSecondaryStream = true;
          }

          this.videoPlayerEventMonitor.lastLoadedFragmentLevel = -1;
          this.videoPlayerEventMonitor.fragmentErrorRetries = 0;
          this.onNewMedia(this.mediaTimeLine, {
            isNewMediaId: false,
            isNewMediaType: false,
            isNewPlayerType: false,
          });
        } else {
          //Can't use 64k for failover due to test script failing. Need to force it to 32k
          this.videoPlayerEventMonitor.lastLoadedFragmentLevel = LEVEL_32_KBPS;
          this.videoPlayer.player.hls.loadLevel = LEVEL_32_KBPS;
          this.videoPlayerEventMonitor.fragmentErrorRetries = 0;
        }
      } else {
        //Retry again with the same level as the last successfully loaded fragment if retry count <= 3
        this.videoPlayer.player.hls.loadLevel = this.videoPlayerEventMonitor.lastLoadedFragmentLevel;
      }
    }
  }
}
