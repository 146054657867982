import React, { useEffect } from 'react';
import { ServiceFactory, SeekService } from '../../servicelib';
import { focusManager } from '@accedo/vdkweb-navigation';
import SegmentTile from '../tiles/SegmentTile';
import Grid from '../grid/Grid';
import './segment-viewer.component.scss';

const segmentViewerCarouselNav = { id: 'segment-viewer-carousel' };

// declare const $badger: any;

const SegmentViewer = ({
  showTitle,
  episode,
  nowPlayingNavId,
  showLogo,
  CONTENT_STATES,
}) => {
  const { segments = [], originalSegments = [] } = episode;
  const seekService = ServiceFactory.getInstance(SeekService) as SeekService;

  const onItemClick = time => {
    // $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
    //   contentState: CONTENT_STATES.SKIP_TO_SEGMENT,
    // });
    seekService.seekThenPlay(time, false);
    focusManager.changeFocus(nowPlayingNavId);
  };

  useEffect(() => {
    focusManager.changeFocus(segmentViewerCarouselNav.id);
  }, []);

  return (
    <div className="segment-viewer-container">
      <div className="segment-viewer-show-container">
        <div
          className="segment-viewer-show-logo"
          style={{
            backgroundImage: showLogo ? `url("${showLogo}")` : '',
          }}
        />
        <div className="segment-viewer-show-title">{showTitle || ''}</div>
      </div>
      <div className="segment-viewer-carousel-container">
        <Grid
          nav={segmentViewerCarouselNav}
          data={segments.length ? segments : originalSegments}
          classNameItemWrapper={''}
          component={SegmentTile}
          maxItemsRow={
            segments.length ? segments.length : originalSegments.length
          }
          customOnItemClick={onItemClick}
          isVertical
          isSpecialVertical
          wrapperTitle={showTitle || ''}
        />
      </div>
    </div>
  );
};

export default SegmentViewer;
