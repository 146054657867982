import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './edp.component.scss';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const EDPActionButton = props => {
  const { children, className, text, onCustomFocus, ...rest } = props;
  const theme = {
    button: `edp-action-button ${className || ''}`,
    buttonFocused: 'edp-action-button-focused',
  };
  return (
    <FocusButton
      theme={theme}
      {...rest}
      onFocus={() => {
        ttsPlayback(text);
        if (onCustomFocus) {
          onCustomFocus();
        }
      }}
    >
      {children}
    </FocusButton>
  );
};

export default EDPActionButton;
