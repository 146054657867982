import { XdkActions } from '../action/xdk.action';
import { pageRedux } from '@accedo/vdkweb-tv-ui';

const { actions, reducer } = pageRedux;
const initialState = {
  backId: 0,
  exitId: 0,
  focusId: 0,
  rightId: 0,
  leftId: 0,
  keyPressedId: 0,
  lastFocusedTile: {},
};
const defaultAction = { type: '' };

export function xdkReducer(state, action) {
  state = state || initialState;
  action = action || defaultAction;

  let currentState = { ...state };

  switch (action.type) {
    case XdkActions.SAVE_LAST_FOCUSED_TILE_ID:
      currentState.lastFocusedTile = action.lastFocusedTile;
      break;

    case XdkActions.SHOW_PODCASTS_MENU_OPTION:
      currentState.shouldShowPodcastsMenuOption =
        action.shouldShowPodcastsMenuOption;
      break;

    case XdkActions.PAGE_RIGHT:
      currentState.rightId += 1;
      break;

    case XdkActions.PAGE_LEFT:
      currentState.leftId += 1;
      break;

    case XdkActions.KEY_PRESSED:
      currentState.keyPressedId += 1;
      break;

    case actions.PAGE_BACK:
    case actions.PAGE_EXIT:
    case actions.PAGE_FOCUS_CURRENT:
      currentState = reducer(currentState, action);
      break;
  }

  return currentState;
}
