import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  ServiceFactory,
  InactivityService,
  MediaPlayerService,
} from '../../servicelib';
import { getKeyPressedId } from '../../redux/selector/xdk.store';
import { appRouteConstants } from '../../routing/app.route.constants';

const dismissTimeout = {
  id: null,
  DEFAULT_WAIT_TIME: 3600000,
};

const InactivityTracker = ({
  setModalData,
  auth,
  setHasError,
  setIsCoreMenuOpen,
}) => {
  const history = useHistory();
  const [isMonitoringPaused, setIsMonitoringPaused] = useState(false);
  const keyPressedId = useSelector(getKeyPressedId);
  const inactivityService = ServiceFactory.getInstance(
    InactivityService,
  ) as InactivityService;
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;

  useEffect(() => {
    if (
      auth &&
      auth.userSession &&
      auth.userSession.authenticated &&
      auth.userSession.username
    ) {
      setIsMonitoringPaused(false);
    } else {
      setIsMonitoringPaused(true);
    }
  }, [
    auth && auth.userSession && auth.userSession.authenticated,
    auth && auth.userSession && auth.userSession.username,
  ]);

  const INACTIVITY_TIMEOUT_MODAL_DATA = {
    title: 'Are you still listening?',
    text: '',
    actions: [
      {
        label: 'Keep listening',
        action: () => {
          mediaPlayerService.mediaPlayer.resume().subscribe();
          setIsMonitoringPaused(false);
          setHasError(false);
          clearTimeout(dismissTimeout.id);
        },
      },

      {
        label: 'Cancel',
        action: () => {
          setIsMonitoringPaused(false);
          setHasError(false);
          clearTimeout(dismissTimeout.id);
        },
      },
    ],
  };

  const pausePlayback = () => {
    if (mediaPlayerService?.mediaPlayer?.isPlaying()) {
      mediaPlayerService.mediaPlayer.pause().subscribe();
      setModalData(INACTIVITY_TIMEOUT_MODAL_DATA);
      setHasError(true);

      //If the modal appears and the user hasn't selected anything for more than an hour, automatically dismiss it and return user to For You/Home screen
      dismissTimeout.id = setTimeout(() => {
        setIsMonitoringPaused(false);
        history.replace(appRouteConstants.HOME_FORYOU);
        setIsCoreMenuOpen(true);
        setHasError(false);
        focusManager.changeFocus('id-now-playing-bar');
      }, dismissTimeout.DEFAULT_WAIT_TIME);
    }

    setIsMonitoringPaused(true);
  };

  useEffect(() => {
    inactivityService.addInactivityCallback(pausePlayback);
  }, []);

  useEffect(() => {
    if (InactivityService.USER_INACTIVITY_INTERVAL > 0 && !isMonitoringPaused) {
      inactivityService.throttledInactivityTimer();
    }
  }, [keyPressedId]);

  return <div></div>;
};

export default InactivityTracker;
