import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './rounded-button.component.scss';
import { ReactComponent as Spinner } from '../../assets/images/spinner.svg';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const RoundedButton = props => {
  const {
    children,
    type = 'primary',
    isActive = true,
    isLoading = false,
    text = '',
    ...rest
  } = props;
  const theme = {
    button: `rounded-button rounded-button-${type}`,
    buttonFocused: 'rounded-button-focused',
    buttonActive: 'rounded-button-active',
  };
  const onFocusPlayback = text => {
    ttsPlayback(`${text}`);
  };
  return (
    <FocusButton
      theme={theme}
      isActive={isActive}
      {...rest}
      onFocus={() => {
        onFocusPlayback(text);
      }}
    >
      {isLoading ? <Spinner /> : children}
    </FocusButton>
  );
};

export default RoundedButton;
