import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { FAVORITES, MENU } from '../../utils/navigationMap';
import { useHistory } from 'react-router';
import ChannelTile from '../../components/tiles/ChannelTile';
import { verticalScroll } from '../../utils/pageUtils';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { ServiceFactory } from '../../servicelib';
import { FavoriteListStoreService } from '../../sxmservicelayer/favorite-list/favorite-list.store.service';
import { getFavorites } from '../../redux/selector/favorite-list.store';
import { useParams } from 'react-router-dom';
import Grid from '../../components/grid/Grid';
import FocusDiv from '../../components/focus-div/FocusDiv';
import styles from './favorites-page.module.scss';
import { ttsPlayback, cancelTtsPlayback } from '../../utils/ttsPlayback.util';

const { PAGE, GRID } = FAVORITES;
const { ZONE_MENU } = MENU;

const navMap = {
  PAGE: {
    id: PAGE,
  },
  GRID: {
    id: GRID,
    nextup: ZONE_MENU,
    parent: PAGE,
  },
};

type Params = {
  superCategory: string;
};

let lastInternalFocusedTile = null;

const NO_CONTENT = {
  channels: {
    title: 'My Favorite Channels',
    description: 'Set the channels you love here.',
  },
  shows: {
    title: 'My Favorite Shows',
    description: 'Follow live and On Demand shows here.',
  },
  episodes: {
    title: 'My Favorite Episodes',
    description: 'Save episodes for later.',
  },
};

//TODO: Check if pressing back while on this screen on the X1 returns to the previous page
export const FavoritesPageComponent = () => {
  const { superCategory } = useParams<Params>();
  const favoritesCarousel = useSelector(getFavorites);
  const [data, setData] = useState(null);
  const [animation, setAnimation] = useState(true);
  const forwardFocus = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const favoriteListStoreService = ServiceFactory.getInstance(
    FavoriteListStoreService,
  ) as FavoriteListStoreService;

  useEffect(() => {
    favoriteListStoreService.getFavoriteCarousels();
  }, []);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  const onPageFocus = () => {
    const text = location.pathname.split('/').join(' ');
    ttsPlayback(`You are currently at:${text}`);
  };

  useEffect(() => {
    onPageFocus();
  }, [location.pathname]);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[navMap.PAGE.id]) {
      forwardFocus.current = lastFocusedTile[navMap.PAGE.id];
      focusManager.changeFocus(navMap.GRID.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [navMap.PAGE.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
      forwardFocus.current = null;
    }, 500);
  }, [history.location]);

  useEffect(() => {
    lastInternalFocusedTile = null;
    window.scroll(0, 0);
  }, [location.pathname]);

  /* Handles the press of the BACK button only when the carousels are focused, */
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (isMounted.current && focusManager.isChildFocused(navMap.PAGE.id)) {
      focusManager.changeFocus(ZONE_MENU);
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  /* Generates the nav objects for the carousels */
  useEffect(() => {
    if (
      favoritesCarousel &&
      !favoritesCarousel[superCategory]?.[0]?.['defaultFav'] &&
      favoritesCarousel[superCategory]?.length > 0
    ) {
      setData(favoritesCarousel[superCategory]);
    } else {
      setData(null);
    }
  }, [favoritesCarousel, location.pathname]);

  useEffect(() => {
    if (!data) {
      ttsPlayback(
        `${NO_CONTENT[superCategory].title} ${NO_CONTENT[superCategory]?.description}`,
        true,
      );
    } else {
      cancelTtsPlayback();
      onPageFocus();
    }
  }, [data]);

  return (
    <FocusDiv
      className="all-channels-page"
      nav={{ ...navMap.PAGE }}
      onFocus={() => {
        if (!data || !(data.length > 0)) {
          focusManager.changeFocus(ZONE_MENU);
        } else {
          if (lastInternalFocusedTile) {
            focusManager.changeFocus(lastInternalFocusedTile);
          } else {
            focusManager.changeFocus(navMap.GRID.id);
          }
        }
      }}
    >
      {data && data.length > 0 ? (
        <Grid
          pageId={navMap.PAGE.id}
          nav={{
            ...navMap.GRID,
          }}
          data={data}
          component={ChannelTile}
          className="content-grid grid-general-style"
          classNameItemWrapper={''}
          maxItemsRow={5}
          forwardFocus={forwardFocus.current}
          onFocus={id => {
            if (!animation) {
              verticalScroll(id);
            }
            lastInternalFocusedTile = id;
          }}
        />
      ) : (
        <div className={styles.noContentWrapper}>
          <div className={styles.title}>{NO_CONTENT[superCategory].title}</div>
          <div className={styles.description}>
            {NO_CONTENT[superCategory].description}
          </div>
        </div>
      )}
    </FocusDiv>
  );
};
