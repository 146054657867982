import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import { selectRecentsCarousel } from '../../redux/selector/carousel.store';
import { MENU, RECENT } from '../../utils/navigationMap';
import { useHistory } from 'react-router';
import ChannelTile from '../../components/tiles/ChannelTile';
import { verticalScroll } from '../../utils/pageUtils';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import FocusDiv from '../../components/focus-div/FocusDiv';
import Grid from '../../components/grid/Grid';
import styles from './recent-page.module.scss';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { ttsPlayback, cancelTtsPlayback } from '../../utils/ttsPlayback.util';

const { PAGE, GRID } = RECENT;
const { CORE_MENU } = MENU;

const navMap = {
  PAGE: {
    id: PAGE,
  },
  GRID: {
    id: GRID,
    parent: PAGE,
    internal: {
      nextup: () => {},
    },
  },
};

let lastInternalFocusedTile = null;

//TODO: Check if pressing back while on this screen on the X1 returns to the previous page
export const RecentPageComponent = ({ isCoreMenuOpen, setIsCoreMenuOpen }) => {
  const recentCarousel = useSelector(selectRecentsCarousel);
  const [data, setData] = useState(null);
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const onPageFocus = () => {
    const text = location.pathname.split(/[/-]/g).join(' ');
    ttsPlayback(`You are currently at:${text}`);
  };
  useEffect(() => {
    onPageFocus();
  }, [location.pathname]);

  navMap.GRID.internal.nextup = () => {
    setIsCoreMenuOpen(true);
    focusManager.changeFocus(CORE_MENU);
  };

  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    lastInternalFocusedTile = focusManager.getCurrentFocus();
    setIsCoreMenuOpen(true);
  }, [backId]);

  useUpdateEffect(() => {
    focusManager.changeFocus(CORE_MENU);
  }, [isCoreMenuOpen]);

  useEffect(() => {
    carouselStoreService.selectRecents();
  }, []);

  useEffect(() => {
    lastInternalFocusedTile = null;
    window.scroll(0, 0);
  }, [location.pathname]);

  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[navMap.PAGE.id]) {
      /* Restore focus to the Grid on Back, do not restore the focus to the last item 
        since the API is returning less items on every onClick */
      focusManager.changeFocus(navMap.GRID.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [navMap.PAGE.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 500);
  }, [history.location]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (
      recentCarousel?.zone?.[0]?.content?.[0]?.tiles &&
      recentCarousel?.zone?.[0]?.content?.[0]?.tiles?.length > 0
    ) {
      setData(recentCarousel?.zone?.[0]?.content?.[0]?.tiles);
    }

    if (recentCarousel?.zone?.length === 0 && history.action === 'POP') {
      setIsCoreMenuOpen(true);
      setTimeout(() => {
        focusManager.changeFocus(CORE_MENU);
      }, 200);
    }
  }, [recentCarousel]);

  useEffect(() => {
    if (!data) {
      ttsPlayback(
        'Recently Played. Jump back in to watch and listen again.',
        true,
      );
    } else {
      cancelTtsPlayback();
      onPageFocus();
    }
  }, [data]);

  return (
    <FocusDiv
      className="all-channels-page"
      nav={{ ...navMap.PAGE }}
      onFocus={() => {
        setIsCoreMenuOpen(false);

        if (!data || !(data.length > 0)) {
          setIsCoreMenuOpen(true);
          focusManager.changeFocus(CORE_MENU);
        } else if (lastInternalFocusedTile) {
          focusManager.changeFocus(lastInternalFocusedTile);
          lastInternalFocusedTile = null;
        } else {
          focusManager.changeFocus(navMap.GRID.id);
        }
      }}
    >
      <Grid
        pageId={navMap.PAGE.id}
        nav={{
          ...navMap.GRID,
        }}
        data={data}
        component={ChannelTile}
        className="content-grid grid-general-style"
        classNameItemWrapper={''}
        maxItemsRow={5}
        onFocus={id => {
          if (!animation) {
            verticalScroll(id);
          }
        }}
        wrapperTitle="Recent"
      />
      {!data && (
        <div className={styles.noContentWrapper}>
          <div className={styles.title}>Recently Played</div>
          <div className={styles.description}>
            Jump back in to watch and listen again.
          </div>
        </div>
      )}
    </FocusDiv>
  );
};
