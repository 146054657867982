/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  ServiceEndpointConstants are API end points which can be reused in application
 */
export class ServiceEndpointConstants {
  public static context = {
    REST_CONTEXT: '/rest/',
    ROOT_CONTEXT: '/rest/experience/',
    V1_ROOT_CONTEXT: 'rest/v1/experience/',
    V2_ROOT_CONTEXT: 'rest/v2/experience/',
    V3_ROOT_CONTEXT: 'rest/v3/experience/',
    V3_ROOT_FEEDBACK_CONTEXT: 'rest/v3/',
    V4_ROOT_CONTEXT: 'rest/v4/experience/',
    V4_ROOT_FEEDBACK_CONTEXT: 'rest/v4/',
    V5_ROOT_CONTEXT: 'rest/v5/experience/',
  };

  public static endpoints = {
    CONFIG: {
      V2_GET:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/configuration',
    },
    // includes videoPlayback settings
    CONFIG_INFO: {
      V4_GET:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'getConfigInfo',
    },
    AUTHENTICATION: {
      V1_LOGIN:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/modify/authentication',
      V2_LOGIN:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/modify/authentication',
      V2_LOGIN_PARTNER:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/modify/authentication-partner',
      V1_LOGOUT:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/pause',
      V2_LOGOUT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/pause',
      V4_CREATE_ALTERNATE_LOGIN:
        ServiceEndpointConstants.context.V4_ROOT_FEEDBACK_CONTEXT +
        'auth/registration/create',
      V4_COMPLETE_ALTERNATE_LOGIN:
        ServiceEndpointConstants.context.V4_ROOT_FEEDBACK_CONTEXT +
        'auth/registration/complete',
    },
    CHANNEL: {
      V1_COMMON_GET:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/get',
      V2_COMMON_GET:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/get',
      V4_COMMON_GET:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'modules/get',
      V1_GET_LIST:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/discover-channel-list',
      V2_GET_LIST:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/discover-channel-list',
      V4_GET_LIST:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/get/discover-channel-list',
      V2_NOOP:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/get/noop',
    },
    DEEPLINK: {
      V2_DEEPLINK:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/deeplink',
    },
    NOW_PLAYING: {
      V1_LIVE:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/tune/now-playing-live',
      V2_LIVE:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/tune/now-playing-live',
      V4_LIVE:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/tune/now-playing-live',
      V2_AOD:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/tune/now-playing-aod',
      V4_PANDORA_PODCAST:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/tune/now-playing-podcast',
      V4_VOD:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/tune/now-playing-vod',
    },
    ON_DEMAND: {
      V1_GET_EPISODE:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/tune/now-playing-aod',
      V2_GET_EPISODE:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/tune/now-playing-aod',
      V1_GET_SHOWS_FOR_GENRE:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/discover-aod',
      V2_GET_SHOWS_EPISODES:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/discover-aod',
      V4_GET_SHOWS_EPISODES:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/get/discover-od',
    },
    FAVORITES: {
      V2_GET_PRESETS:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V4_GET_PRESETS:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V2_UPDATE_PRESETS: `${ServiceEndpointConstants.context.V2_ROOT_CONTEXT}modules/modify`,
      V4_UPDATE_PRESETS: `${ServiceEndpointConstants.context.V4_ROOT_CONTEXT}modules/modify`,
    },
    RECENTLY_PLAYED: {
      V1_GET_RECENTLY_PLAYED:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V2_GET_RECENTLY_PLAYED:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V4_GET_RECENTLY_PLAYED:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V1_REMOVE_RECENTLY_PLAYED:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/modify',
      V2_REMOVE_RECENTLY_PLAYED:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/modify',
      V4_REMOVE_RECENTLY_PLAYED:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'modules/modify',
    },
    RESUME: {
      V1_GET_RESUME:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/resume',
      V2_GET_RESUME:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/resume',
      V4_GET_RESUME:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'modules/resume',
    },
    MY_SXM: {
      V2_TUNE:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/tune/now-playing-mysxm',
      V1_GET_SETTINGS:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/now-playing-mysxm',
      V1_UPDATE_SETTINGS:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/modify',
      V2_UPDATE_SETTINGS:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/modify',
      V1_CONSUME:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/update-tracks?channelID={0}',
      V2_CONSUME:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/update-tracks?channelID={0}',
      V1_DISCOVER:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/discover-personalized-channels',
      V2_DISCOVER:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/discover-personalized-channels',
    },
    CONTENT_ALERT: {
      MUTE_ALERTS: 'util/muteAlerts',
      CREATE_ALERT: 'module/create',
      V1_CREATE_ALERT:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/create',
      V2_CREATE_ALERT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/create',
      V1_GET_ALERTS:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V2_GET_ALERTS:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V1_REMOVE_MANAGE_NOTIFICATION:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/modify',
      V2_REMOVE_MANAGE_NOTIFICATION:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/modify',
      V2_REMOVE_SHOW_NOTIFICATION:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/modify/profile-gup-notification-consume',
    },
    CONSUME: {
      V1_ALL:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/consumed',
      V2_ALL:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/consumed',
      V4_ALL:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/events/consume',
    },
    RECOMMENDER: {
      V1_CREATE:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/modify',
      V2_CREATE:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/modify',
    },
    SEARCH: {
      V2_GET_AUTO_POPULATE:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'search',
      V2_GET_RECENT_SEARCH_LIST:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/get',
      V2_GET_SEARCH_RESULT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'search',
    },
    ME_SETTINGS: {
      V1_GET_SETTINGS_RESULT:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/get',
      V2_GET_SETTINGS_RESULT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/get',
      V2_UPDATE_SETTINGS_RESULT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/modify',
    },
    SOCIAL: {
      LINK: 'module/create/link',
    },
    EDP: {
      V1_GET_EDP_DETAILS:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/discover-assets',
      V2_GET_EDP_DETAILS:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/discover-assets',
      V4_GET_EDP_DETAILS:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/get/discover-assets',
    },
    GEOLOCATION: {
      V1_CHECK:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT +
        'modules/get/geolocation',
      V2_CHECK:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/geolocation',
    },
    CAROUSEL: {
      V3_CAROUSEL_BY_PAGE:
        ServiceEndpointConstants.context.V3_ROOT_CONTEXT + 'carousels',
      V4_CAROUSEL_BY_PAGE:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'carousels',
    },
    PROFILE: {
      V2_GET_PROFILE_GUP_COLLATION:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V3_GET_PROFILE_GUP_COLLATION:
        ServiceEndpointConstants.context.V3_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V4_GET_PROFILE_GUP_COLLATION:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/get/profile-gup-collation',
      V3_GET_PROFILE_AVATARS:
        ServiceEndpointConstants.context.V3_ROOT_CONTEXT +
        'profile/available-avatars',
      V3_UPDATE_PROFILE:
        ServiceEndpointConstants.context.V3_ROOT_CONTEXT +
        'modify/update-profile',
      V2_CREATE_ALERT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/create',
      V4_CREATE_ALERT:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'modules/create',
      V4_MUTE_ALERTS:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'util/muteAlerts',
      V2_REMOVE_ALERT:
        ServiceEndpointConstants.context.V2_ROOT_CONTEXT + 'modules/modify',
      V4_REMOVE_ALERT:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT + 'modules/modify',
    },
    NOTIFICATION: {
      V3_GET_NOTIFICATION:
        ServiceEndpointConstants.context.V3_ROOT_CONTEXT + 'notification/get',
      V3_FEEDBACK_NOTIFICATION:
        ServiceEndpointConstants.context.V3_ROOT_FEEDBACK_CONTEXT +
        'notification/feedback',
    },
    CHROMECAST: {
      V4_CAST_STATUS:
        ServiceEndpointConstants.context.V4_ROOT_FEEDBACK_CONTEXT +
        'auth/cast/status',
      V4_TRANSFER_SESSION:
        ServiceEndpointConstants.context.V4_ROOT_FEEDBACK_CONTEXT +
        'auth/cast/initiate',
    },
    SEEDED_RADIO: {
      V4_AFFINITIES:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/seeded-radio/affinities',
      V4_TUNE:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/seeded-radio/tune',
      V4_REFRESH:
        ServiceEndpointConstants.context.V4_ROOT_CONTEXT +
        'modules/seeded-radio/refresh-tracks',
      V4_REMOVE_SEEDED_STATION: `${ServiceEndpointConstants.context.V4_ROOT_CONTEXT}modules/modify`,
    },
    ADDITIONAL_CHANNELS: {
      V4_AIC: 'rest/v4/aic/tune',
      V4_REFRESH: 'rest/v4/aic/refresh-tracks',
    },
    ANALYTICS: {
      V1_ANALYTICS_REPORT:
        ServiceEndpointConstants.context.V1_ROOT_CONTEXT + 'modules/events/ana',
    },
    LOG: {
      V1_LOG_UPLOAD:
        ServiceEndpointConstants.context.REST_CONTEXT + 'log/upload',
    },
    FREE_TIER: {
      V5_SCREEN_INFO:
        ServiceEndpointConstants.context.V5_ROOT_CONTEXT + 'screenInfo',
    },
  };

  public static NETWORK_CONNECTIVITY = '/assets/data/connectivity.json';
  public static APP_VERSION = '/assets/data/version.json';
}
