import React, { useRef } from 'react';
import FocusDiv from '../focus-div/FocusDiv';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { getLastFocusedTileId } from '../../redux/selector/xdk.store';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

type props = {
  nav: XDKNav;
  pageId: string;
  data: any;
  component: React.ComponentType<any>;
  classNameItemWrapper: string;
  onWrapperClick: any;
  onItemBlur: any;
  onItemFocus: any;
  customOnItemClick: any;
  customOnItemFocus: any;
  rowIndex: number;
  itemIndex: number;
  gridWrapperFocus?: any;
  wrapperTitle?: string;
  wrapperSubTitle?: string;
};

const GridItem = ({
  nav,
  pageId,
  data,
  component: Component,
  classNameItemWrapper,
  onWrapperClick,
  onItemBlur,
  onItemFocus,
  customOnItemClick,
  customOnItemFocus,
  rowIndex,
  itemIndex,
  gridWrapperFocus,
  wrapperTitle,
  wrapperSubTitle,
}: props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [clicked, setClicked] = useState(0);
  const lastFocusedTile = useSelector(getLastFocusedTileId);
  const dispatch = useDispatch();
  const elementRef = useRef<any>();

  const onClick = useCallback(() => {
    if (onWrapperClick) {
      onWrapperClick(nav.id);
    } else {
      dispatch(
        saveLastFocusedTileId({
          ...lastFocusedTile,
          [pageId]: nav.id,
        }),
      );
    }
    setClicked(clicked + 1);
  }, [clicked]);
  return (
    <FocusDiv
      nav={nav}
      className={classNameItemWrapper}
      domRef={elementRef}
      onFocus={() => {
        onItemFocus();
        setIsFocused(true);
        ttsPlayback(
          `${
            gridWrapperFocus.current && wrapperTitle ? wrapperTitle + ' ' : ''
          }${
            wrapperSubTitle && data?.type !== 'CLEAR_ITEM'
              ? wrapperSubTitle + ' '
              : ''
          }${data?.line1 || ''} ${data?.line2 || ''} ${data?.line3 ||
            ''} ${data?.text || ''} ${
            data?.title
              ? elementRef?.current?.children?.[0]?.children?.[1]?.textContent
                ? 'Time: ' +
                  elementRef?.current?.children?.[0]?.children?.[1]?.textContent
                : ''
              : ''
          } ${data?.title || ''}`,
        );
        gridWrapperFocus.current = 0;
      }}
      onBlur={() => {
        onItemBlur();
        setIsFocused(false);
      }}
      onClick={onClick}
    >
      <Component
        data={data}
        clicked={clicked}
        isFocused={isFocused}
        customOnItemClick={customOnItemClick}
        customOnItemFocus={customOnItemFocus}
        rowIndex={rowIndex}
        itemIndex={itemIndex}
      />
    </FocusDiv>
  );
};

export default GridItem;
