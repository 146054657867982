import React, { useRef, useEffect } from 'react';
import { withFocus } from '@accedo/vdkweb-navigation';
import './fake-text-input.component.scss';

const FakeTextInput = props => {
  const {
    className,
    isPassword,
    nav,
    value,
    isFocused,
    placeholder,
    hasError,
    //isEditing,
    ...rest
  } = props;
  const theme = {
    input: `fake-text-input ${className} ${hasError ? 'has-error' : ''}`,
    inputFocused: 'fake-text-input-focused',
  };

  const inputEl = useRef(null);

  useEffect(() => {
    // @ts-ignore: Object is possibly 'null'.
    inputEl.current && inputEl.current.focus();
  }, [isFocused]);

  const { input: themeInput, inputFocused } = {
    ...theme,
  };

  const klassName = `${themeInput} ${nav.isFocused && inputFocused}`.trim();

  return (
    <div className={klassName} style={props.style}>
      <div className="input" ref={inputEl} {...rest}>
        <span>
          {isPassword ? new Array((value || '').length).fill('•') : value}
        </span>
        <span className="caret" />
      </div>
      <div
        className="placeholder"
        style={value ? { top: '-0.5rem', fontSize: '1.25rem' } : null}
      >
        <label>{placeholder}</label>
      </div>
    </div>
  );
};

export default withFocus(FakeTextInput);
