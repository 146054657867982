import React, { useRef } from 'react';
import FocusDiv from '../focus-div/FocusDiv';
import { useEffect, useState, memo, useCallback } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';

import {
  createColumns,
  createColumnId,
  createVerticalGridNavigation,
  createRows,
  createRowId,
  createGridNavigation,
} from './gridUtils';
import Row from './Row';
import styles from './grid.module.scss';
import useLatest from '../../hooks/useLatest';
import { getRelativeVWPixel } from '../../utils/relativePxValues';
import { verticalScroll, verticalSmoothScroll } from '../../utils/pageUtils';

type props = {
  nav: XDKNav;
  pageId?: string;
  isVertical?: boolean;
  data: any[];
  component: React.ComponentType<any>;
  className?: string;
  classNameItemWrapper: string;
  maxItemsRow?: number;
  isSpecialVertical?: boolean;
  animation?: boolean;
  forwardFocus?: string | undefined;
  onFocus?: (id: string) => void;
  onWrapperClick?: (id: string) => void;
  customOnItemClick?: (id: string) => void;
  customOnItemFocus?: (id: string) => void;
  wrapperTitle?: string;
  wrapperSubTitle?: string;
};

const getNewXAxis = (elementId: string) => {
  if (!elementId) {
    return 0;
  }

  const curElement = document.getElementById(elementId);

  return -(curElement?.offsetLeft - getRelativeVWPixel(350) || 0);
};

const getNewYAxis = (elementId: string) => {
  if (!elementId) {
    return 0;
  }

  const curElement = document.getElementById(elementId);

  return -(curElement?.offsetTop - getRelativeVWPixel(350) || 0);
};

const Grid = ({
  nav,
  pageId,
  isVertical = false,
  data,
  component,
  className = '',
  classNameItemWrapper,
  maxItemsRow,
  isSpecialVertical = false,
  forwardFocus = undefined,
  animation = true,
  onFocus,
  onWrapperClick = null,
  customOnItemClick,
  customOnItemFocus,
  wrapperTitle,
  wrapperSubTitle,
}: props) => {
  if (!data || data?.length === 0 || !nav) {
    return null;
  }

  const [rows, setRows] = useState<any[] | null>(null);
  const [gridNavigation, setGridNavigation] = useState<any>(null);
  const [axis, setNewAxis] = useState(0);
  const latestAxisRef = useLatest(axis);
  const latestOnFocusRef = useLatest(onFocus);
  const [focusTrail, setFocusTrail] = useState<any>({
    lastFocusedEl: nav.id,
    curFocusedEl: '',
  });
  const gridWrapperFocus = useRef(0);

  const onItemBlur = useCallback(
    currentId => {
      if (focusTrail.lastFocusedEl !== nav.id) {
        return;
      }

      setFocusTrail({
        ...focusTrail,
        lastFocusedEl: currentId,
        curFocusedEl: focusManager.getCurrentFocus(),
      });
    },
    [focusTrail],
  );

  const onItemFocus = useCallback(() => {
    (document.activeElement as any).blur();
    const currentItem = focusManager.getCurrentFocus();

    const currentAxis = latestAxisRef.current;
    if (latestOnFocusRef.current) {
      latestOnFocusRef.current(currentItem);
    }

    if (!currentItem) {
      return;
    }

    let newAxis = currentAxis;

    if (isVertical) {
      newAxis = !isSpecialVertical
        ? getNewXAxis(currentItem)
        : getNewYAxis(currentItem);
      if (currentAxis !== newAxis) {
        setNewAxis(Math.min(newAxis, 0));
      }
    } else {
      if (animation) {
        verticalSmoothScroll(currentItem);
      } else {
        verticalScroll(currentItem);
      }
    }
  }, []);

  const restoreFocusTrail = useCallback(() => {
    setFocusTrail({
      ...focusTrail,
      lastFocusedEl: nav.id,
      curFocusedEl: '',
    });
  }, []);

  useEffect(() => {
    if (isVertical) {
      //setNewAxis(0);
    }

    const rowsArray = isVertical
      ? createColumns(data, maxItemsRow)
      : createRows(data, maxItemsRow);

    setRows(rowsArray);
    setGridNavigation(
      isVertical
        ? createVerticalGridNavigation(rowsArray, nav, restoreFocusTrail)
        : createGridNavigation(rowsArray, nav, restoreFocusTrail),
    );
  }, [data]);

  useEffect(() => {
    if (gridNavigation && forwardFocus) {
      if (
        !gridNavigation.some(row => {
          return row.some(el => {
            return el.id === forwardFocus;
          });
        })
      ) {
        if (gridNavigation[0][0].id) {
          focusManager.changeFocus(gridNavigation[0][0].id);
        }
      }
    }
  }, [gridNavigation]);
  useEffect(() => {
    gridWrapperFocus.current = 1;
  }, []);
  return (
    <>
      {gridNavigation && rows && (
        <FocusDiv
          nav={{
            ...nav,
            forwardFocus: forwardFocus || gridNavigation[0][0].id,
          }}
          className={`${
            isSpecialVertical
              ? styles['special-vertical-wrapper']
              : styles.wrapper
          } ${className}`}
        >
          <div
            className={isVertical ? styles['vertical-wrapper'] : ''}
            style={{
              transform: `${
                isSpecialVertical
                  ? `translateY(${axis}px)`
                  : `translateX(${axis}px)`
              }`,
              transition: `${animation ? 'transform 280ms' : 'none'}`,
            }}
          >
            {rows.map((_, idx) => {
              return (
                <Row
                  pageId={pageId}
                  key={isVertical ? createColumnId(idx) : createRowId(idx)}
                  nav={{
                    id: isVertical ? createColumnId(idx) : createRowId(idx),
                    parent: nav.id,
                  }}
                  isVertical={isVertical}
                  itemsNav={gridNavigation[idx]}
                  data={rows[idx]}
                  component={component}
                  classNameItemWrapper={classNameItemWrapper}
                  onWrapperClick={onWrapperClick}
                  onItemBlur={onItemBlur}
                  onItemFocus={onItemFocus}
                  customOnItemClick={customOnItemClick}
                  customOnItemFocus={customOnItemFocus}
                  rowIndex={idx}
                  gridWrapperFocus={gridWrapperFocus}
                  wrapperTitle={wrapperTitle}
                  wrapperSubTitle={wrapperSubTitle}
                />
              );
            })}
          </div>
        </FocusDiv>
      )}
    </>
  );
};

export default memo(Grid);
