import React from 'react';
import classNames from 'classnames';
import Button from '../button/Button';
import styles from './result-item.module.scss';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

type props = {
  onClick: (id: string) => void;
  item: any;
  nav: XDKNav;
  className: string;
  classNameFocused: string;
  classNameIcon: string;
  classNameText: string;
  classNameOnFocus?: string;
};

const ResultItem = ({
  onClick,
  item: { text, src, value },
  nav,
  className,
  classNameFocused,
  classNameIcon,
  classNameText,
  classNameOnFocus = '',
}: props) => {
  return (
    <Button
      nav={nav}
      className={classNames(className)}
      classNameFocused={classNameFocused}
      onClick={() => {
        onClick(value);
      }}
      onFocus={() => ttsPlayback(`Text To Speech: ${text}`)}
    >
      <div className={styles['item-centered']}>
        <img src={src} className={classNameIcon} />
      </div>
      <div className={styles['item-centered']}>
        <span className={classNameText}>{text}</span>
      </div>
    </Button>
  );
};

export default ResultItem;
