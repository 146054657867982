export class XdkActions {
  static SAVE_LAST_FOCUSED_TILE_ID = 'SAVE_LAST_FOCUSED_TILE_ID';
  static PAGE_RIGHT = 'vdk/page/RIGHT';
  static PAGE_LEFT = 'vdk/page/LEFT';
  static SHOW_PODCASTS_MENU_OPTION = 'SHOW_PODCASTS_MENU_OPTION';
  static KEY_PRESSED = 'KEY_PRESSED';
}

export const saveLastFocusedTileId = lastFocusedTile => {
  return {
    type: XdkActions.SAVE_LAST_FOCUSED_TILE_ID,
    lastFocusedTile,
  };
};

export const showPodcastsMenuOption = shouldShowPodcastsMenuOption => {
  return {
    type: XdkActions.SHOW_PODCASTS_MENU_OPTION,
    shouldShowPodcastsMenuOption,
  };
};

export const pageRight = () => {
  return {
    type: XdkActions.PAGE_RIGHT,
  };
};

export const pageLeft = () => {
  return {
    type: XdkActions.PAGE_LEFT,
  };
};

export const keyPressed = () => {
  return {
    type: XdkActions.KEY_PRESSED,
  };
};
