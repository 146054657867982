/* eslint-disable */
import * as Sniffr from 'sniffr';
import * as _ from 'lodash';
import { ApiLayerTypes } from '../servicelib';

const sniffr = new Sniffr();
sniffr.sniff(navigator.userAgent);

/**
 * @MODULE:     client
 * @CREATED:    01/16/18
 * @COPYRIGHT:  2018 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Wrapper class to the 3rd party library for browser and OS detection: Sniffer:
 *
 *      https://www.npmjs.com/package/sniffr
 *
 * Static class containing browser and OS detection methods.
 */
export class BrowserUtil {
  public static hostname: string =
    'https://streamingapi2.mountain.siriusxm.com'; //prod
  //'https://mobileeverest1.mountain.siriusxm.com'; //dev

  /**
   * Reference to the 3rd-party library for browser and OS detection. It's stored as a static property simply
   * to use a more "says what it does" variable name.
   */
  private static _browserAndOSDetector: any = sniffr;

  /**
   * The current browser's details, like name and version.
   */
  private static _browser: IBrowser = null;

  /**
   * The current operating system's details, like name and version.
   */
  private static _os: IOS = null;

  public static BROWSERS = {
    CHROME: 'Chrome',
    SAFARI: 'Safari',
    FIREFOX: 'Firefox',
    IE: 'Internet Explorer',
    OPERA: 'Opera',
    EDGE: 'Edge',
    NATIVE: 'Electron',
  };

  /**
   * Mapping list of raw browser names as output from the 3rd-party library to the expected
   * browser names for the web client and API.
   */
  private static _browserList = {
    chrome: BrowserUtil.BROWSERS.CHROME,
    safari: BrowserUtil.BROWSERS.SAFARI,
    firefox: BrowserUtil.BROWSERS.FIREFOX,
    ie: BrowserUtil.BROWSERS.IE,
    opera: BrowserUtil.BROWSERS.OPERA,
    edge: BrowserUtil.BROWSERS.EDGE,
  };

  /**
   * Mapping list of raw operating system names as output from the 3rd-party library to the expected
   * operating system names for the web client and API.
   */
  private static _osList = {
    macos: 'Mac',
    windows: 'Windows',
    linux: 'Linux',
  };

  /**
   * Determine if the client is running on a mobile or tablet touch device
   * @returns boolean
   */
  public static isMobile(): boolean {
    let isMobile = false;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      isMobile = true;
    }

    return isMobile;
  }

  /**
   * Returns a browser detail object that contains the name and version info. It also changes the
   * 3rd party library's output property of `name` and stores it as `rawName` and then maps the `name`
   * field to one that is know from the `_browserList`.
   *
   * This is only runs detection once as it's cached for the client thereafter.
   * @returns {IBrowser}
   */
  public static getBrowser(): IBrowser {
    if (!BrowserUtil._browser) {
      BrowserUtil._browser = BrowserUtil._browserAndOSDetector.browser;
      BrowserUtil._browser.rawName = BrowserUtil._browser.name;
      BrowserUtil._browser.name = BrowserUtil.getExpectedBrowserName(
        BrowserUtil._browser.name,
      );
    }
    return BrowserUtil._browser;
  }

  /**
   * Accessor to the browser's name.
   * @returns {string}
   */
  public static getBrowserName(): string {
    return BrowserUtil.getBrowser().name;
  }

  /**
   * Accessor to the browser's version.
   * @returns {string}
   */
  public static getBrowserVersion(): string {
    return BrowserUtil.getBrowser().versionString;
  }

  /**
   * Returns an operating system detail object that contains the name and version info. It also changes the
   * 3rd party library's output property of `name` and stores it as `rawName` and then maps the `name`
   * field to one that is know from the `_osList`.
   *
   * This is only runs detection once as it's cached for the client thereafter.
   * @returns {IOS}
   */
  public static getOS(): IOS {
    if (!BrowserUtil._os) {
      BrowserUtil._os = BrowserUtil._browserAndOSDetector.os;
      BrowserUtil._os.rawName = BrowserUtil._os.name;
      BrowserUtil._os.name = BrowserUtil.getExpectedOSName(
        BrowserUtil._os.name,
      );
    }
    return BrowserUtil._os;
  }

  /**
   * Accessor to the operating system's name.
   * @returns {string}
   */
  public static getOSName(): string {
    return BrowserUtil.getOS().name;
  }

  /**
   * Accessor to the operating system's version.
   * @returns {string}
   */
  public static getOSVersion(): string {
    return BrowserUtil.getOS().versionString;
  }

  /**
   * Accessor indicating if the app is loaded under native app.
   * @returns {boolean}
   */
  public static isNative(): boolean {
    const userAgent = _.get(window, 'navigator.userAgent', '').toLowerCase();
    // userAgent has `Electron` keyword, we will use this to see if we are loading under Desktop App or web app
    return (
      userAgent &&
      userAgent.indexOf(BrowserUtil.BROWSERS.NATIVE.toLowerCase()) > -1
    );
  }

  /**
   * Accessor indicating if the browser is Chrome.
   * @returns {boolean}
   */
  public static isChrome(): boolean {
    //window.chrome is doesn't exist on the Chromium and also on other browsers --> https://stackoverflow.com/a/9851769
    //so we will use this property to make sure that, the browser is really chrome and not chromium
    const isChrome =
      _.get(window, 'chrome', null) && _.get(window, 'chrome.runtime', null);

    return BrowserUtil.isBrowser(BrowserUtil.BROWSERS.CHROME) && !!isChrome;
  }

  /**
   * Accessor indicating if the browser is Safari.
   * @returns {boolean}
   */
  public static isSafari(): boolean {
    return BrowserUtil.isBrowser(BrowserUtil.BROWSERS.SAFARI);
  }

  /**
   * Accessor indicating if the browser is Firefox.
   * @returns {boolean}
   */
  public static isFirefox(): boolean {
    return BrowserUtil.isBrowser(BrowserUtil.BROWSERS.FIREFOX);
  }

  /**
   * Accessor indicating if the browser is Internet Explorer.
   * @returns {boolean}
   */
  public static isInternetExplorer(): boolean {
    return BrowserUtil.isBrowser(BrowserUtil.BROWSERS.IE);
  }

  /**
   * Accessor indicating if the browser is Opera.
   * @returns {boolean}
   */
  public static isOpera(): boolean {
    return BrowserUtil.isBrowser(BrowserUtil.BROWSERS.OPERA);
  }

  /**
   * Accessor indicating if the browser is Edge.
   * @returns {boolean}
   */
  public static isEdge(): boolean {
    return BrowserUtil.isBrowser(BrowserUtil.BROWSERS.EDGE);
  }

  /**
   * Accessor indicating if the browser is WPE (Comcast).
   * @returns {boolean}
   */
  public static isWPE(): boolean {
    if (!!navigator && !!navigator.userAgent) {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf('wpe') !== -1) {
        return true;
      }
    }

    return false;
  }

  /**
   * Method that will reload the app
   */
  public static reload() {
    if (location && location.reload) {
      location.reload();
    }
  }

  /**
   * Get the value of a given query parameter
   *
   * @param {string} parameterName is the name of the parameter for which to get the value
   * @returns {string} the value of the given query parameter
   */
  public static getQueryParameterValue(parameterName: string): string {
    const vars = window.location.search.substring(1).split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === parameterName) {
        return pair[1];
      }
    }

    return '';
  }

  public static getApiEndpoint() {
    return BrowserUtil.hostname;
  }

  public static getDomainName() {
    return BrowserUtil.hostname;
  }

  /**
   * Get the protocol from the window location
   *
   * @returns {string}
   */
  public static getProtocol() {
    return window.location.protocol;
  }

  /**
   * Gets the region for the app.  "US" for .com locations and "CA" for *.ca locations
   * @returns {string}
   */
  public static getAppRegion() {
    let urlArr = window.location.host.split('.');
    let appRegion = urlArr[urlArr.length - 1];
    appRegion = appRegion.toUpperCase();

    return appRegion == ApiLayerTypes.REGION_CA
      ? appRegion
      : ApiLayerTypes.REGION_US;
  }

  public static getBrowserPathname() {
    return window.location.pathname;
  }

  /**
   * Indicates if the browser is the requested browser by name.
   * @returns {boolean}
   */
  private static isBrowser(browserName: string): boolean {
    return (
      BrowserUtil.getBrowserName().toLowerCase() === browserName.toLowerCase()
    );
  }

  /**
   * Accessor that returns the expected browser name for the raw one.
   * @param {string} name
   * @returns {string}
   */
  private static getExpectedBrowserName(name: string): string {
    return BrowserUtil._browserList[name] || 'Unknown Browser';
  }

  /**
   * Accessor that returns the expected operating system name for the raw one.
   * @param {string} name
   * @returns {string}
   */
  private static getExpectedOSName(name: string): string {
    return BrowserUtil._osList[name] || 'Unknown OS';
  }

  public static triggerVerticalScroll(): void {
    /* used useragent check to avoid needed event constructor polyfill for Internet Explorer */
    if (BrowserUtil.isInternetExplorer()) {
      window.scrollTo(window.pageXOffset, window.pageYOffset - 1);
      window.scrollTo(window.pageXOffset, window.pageYOffset + 1);
    } else {
      window.dispatchEvent(new Event('scroll'));
    }
  }
}

export interface IBrowser {
  rawName: string;
  name: string;
  version: number[];
  versionString: string;
}

export interface IOS {
  rawName: string;
  name: string;
  version: number[];
  versionString: string;
}
