import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEnhancedEdpCarousel } from '../../redux/selector/carousel.store';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { useHistory } from 'react-router';
import { ENHANCEDEDP } from '../../utils/navigationMap';
import '../episode-listing-page/episode-listing.component.scss';
import Grid from '../../components/grid/Grid';
import EpisodeTile from '../../components/tiles/EpisodeTile';
import { verticalScroll } from '../../utils/pageUtils';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const { PAGE } = ENHANCEDEDP;

const pageNav = {
  id: PAGE,
};

export const EnhancedEdpPage = props => {
  const { setIsCoreMenuOpen } = props;

  const enhancedEdpCarousel = useSelector(selectEnhancedEdpCarousel);
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const [navIds, setNavIds] = useState({});
  const [focusedTile, setFocusedTile] = useState(null);
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();
  const forwardFocus = useRef(null);

  const onItemFocus = (data: {}) => {
    setFocusedTile(data);
  };

  useEffect(() => {
    const text = location.pathname.split(/[/-]/g).join(' ');
    ttsPlayback(`You are currently at:${text}`);
  }, [location.pathname]);

  useEffect(() => {
    setIsCoreMenuOpen(false);
    const pathArray = location.pathname.split('/');
    const queryParameters = pathArray[pathArray.length - 1];
    carouselStoreService.selectEnhancedEdpCarousel(queryParameters);
  }, []);

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.id]) {
      forwardFocus.current = lastFocusedTile[pageNav.id];
      focusManager.changeFocus(pageNav.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    if (enhancedEdpCarousel && enhancedEdpCarousel.selectors) {
      const ids = [];
      enhancedEdpCarousel.selectors.forEach(selector => {
        selector.segments.forEach(segment => {
          if (segment.title === 'EPISODES') {
            segment.carousels.forEach(carousel => {
              ids.push(carousel.guid);
            });
          }
        });
      });

      const navs = {};
      ids.forEach((id, index) => {
        navs[id] = {
          id: id,
          nextup: ids[index - 1] || undefined,
          nextdown: ids[index + 1] || undefined,
        };
      });

      navs['page'] = {
        ...pageNav,
        forwardFocus: ids[0],
      };

      setNavIds(navs);
    }
  }, [enhancedEdpCarousel]);

  useEffect(() => {
    focusManager.changeFocus(PAGE);
    window.scrollTo(0, 0);
  }, [navIds['page']]);

  useEffect(() => {
    const text = enhancedEdpCarousel?.pageTextLine1
      ? enhancedEdpCarousel.pageTextLine1
      : '';
    ttsPlayback(text);
  }, [enhancedEdpCarousel]);

  return (
    <Page
      className="episode-listing-page"
      nav={navIds['page']}
      style={{
        backgroundImage: enhancedEdpCarousel?.pageBackground?.imageLink
          ? `url("${enhancedEdpCarousel.pageBackground.imageLink}?width=768&height=432&preserveAspect=true")`
          : '',
        backgroundSize: enhancedEdpCarousel?.pageBackground?.imageLink
          ? 'cover'
          : '',
        backgroundPosition: enhancedEdpCarousel?.pageBackground?.imageLink
          ? 'center'
          : '',
      }}
    >
      <div className="episode-listing-navbar">
        {enhancedEdpCarousel?.pageLogo?.imageLink && (
          <img
            className="episode-listing-navbar-logo for-podcast"
            src={enhancedEdpCarousel.pageLogo.imageLink}
            alt={enhancedEdpCarousel.pageLogo.imageAltText}
          />
        )}
        <div className="episode-listing-navbar-name">
          {enhancedEdpCarousel?.pageTextLine1
            ? enhancedEdpCarousel.pageTextLine1
            : ''}
        </div>
      </div>
      <div className="episode-listing-content-container">
        {enhancedEdpCarousel &&
          enhancedEdpCarousel.selectors &&
          enhancedEdpCarousel.selectors.map((selector, selectorIndex) => {
            return (
              <Fragment key={`enhanced-edp-selector-${selectorIndex}`}>
                {selector.segments &&
                  selector.segments.map((segment, segmentIndex) => {
                    return (
                      <Fragment key={`enhanced-edp-segment-${segmentIndex}`}>
                        {segment.title === 'EPISODES' &&
                          segment.carousels &&
                          segment.carousels.map((carousel, index) => {
                            return (
                              <div key={index}>
                                <Grid
                                  pageId={pageNav.id}
                                  key={carousel.guid}
                                  nav={navIds[carousel.guid]}
                                  classNameItemWrapper={
                                    'episode-listing-carousel episode-listing-general-style'
                                  }
                                  data={carousel.tiles}
                                  component={EpisodeTile}
                                  maxItemsRow={carousel.tiles.length}
                                  customOnItemFocus={onItemFocus}
                                  forwardFocus={forwardFocus.current}
                                  animation={animation}
                                  onFocus={id => {
                                    if (!animation) {
                                      verticalScroll(carousel.guid);
                                    }
                                  }}
                                  isVertical
                                  isSpecialVertical
                                />
                                {focusedTile && (
                                  <div className="episode-listing-right-container">
                                    {focusedTile.line2 && (
                                      <div className="episode-listing-right-container-title">
                                        {focusedTile.line2}
                                      </div>
                                    )}
                                    <span className="episode-listing-right-container-details transparent">
                                      {focusedTile.iconImageUrl && (
                                        <img
                                          className="episode-listing-right-container-icon"
                                          src={focusedTile.iconImageUrl}
                                        />
                                      )}
                                      {focusedTile.line1 && (
                                        <h3>{focusedTile.line1}</h3>
                                      )}
                                      {focusedTile.line3 && (
                                        <h3>{focusedTile.line3}</h3>
                                      )}
                                    </span>
                                    {(focusedTile.longDescription ||
                                      focusedTile.line2) && (
                                      <div className="episode-listing-right-container-description">
                                        {focusedTile.longDescription
                                          ? focusedTile.longDescription
                                          : focusedTile.line2}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </Fragment>
                    );
                  })}
              </Fragment>
            );
          })}
      </div>
    </Page>
  );
};
