import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceFactory } from '../../servicelib';
import { selectSearchLandingCarousel } from '../../redux/selector/carousel.store';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { Keyboard } from '../../components/keyboard';
import { MENU, SEARCH } from '../../utils/navigationMap';
import { focusManager } from '@accedo/vdkweb-navigation';
import { ReactComponent as SearchIcon } from '../../assets/images/srch-on-blue.svg';
import SearchIconResult from '../../assets/images/srch-off-nav-white.svg';
import Input from '../../components/input/input';
import styles from './search-page.module.scss';
import './search-page.scss';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { SearchService } from '../../servicelib';
import { useObservable } from '../../hooks';
import ResultItem from '../../components/result-item/result-item';
import SearchResultTile from '../../components/tiles/SearchResultTile';
import Grid from '../../components/grid/Grid';
import {
  itemType,
  parseSearchResults,
  parseTuneResults,
} from './search-page-utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Swimlane from '../../components/swimlane/Swimlane';
import CategoryTile from '../../components/tiles/CategoryTile';
import {
  selectSearchCarousel,
  selectSubCategoryCarousel,
} from '../../redux/action/carousel.action';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';
import { combineLatest } from 'rxjs';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const { PAGE, KEYBOARD, LANDING_CAROUSEL, KEYWORD_BOX, RESULT_LIST } = SEARCH;
const { CORE_MENU } = MENU;

const navMap = {
  PAGE: {
    id: PAGE,
    internal: {
      nextup: () => {},
    },
  },
  KEYBOARD: {
    id: KEYBOARD,
    useLastFocus: true,
    parent: PAGE,
  },
  LANDING_CAROUSEL: {
    id: LANDING_CAROUSEL,
    nextup: KEYBOARD,
    parent: PAGE,
    useLastFocus: true,
  },
  KEYWORD_BOX: {
    id: KEYWORD_BOX,
    nextup: KEYBOARD,
    nextdown: '',
    parent: PAGE,
  },
  RESULT_LIST: {
    id: RESULT_LIST,
    nextup: KEYWORD_BOX,
    parent: PAGE,
  },
};

let lastInternalFocusedTile = null;

export const SearchPageComponent = ({ isCoreMenuOpen, setIsCoreMenuOpen }) => {
  const onSearchPageFocus = () => {
    setIsCoreMenuOpen(false);

    if (lastInternalFocusedTile) {
      focusManager.changeFocus(lastInternalFocusedTile);
      lastInternalFocusedTile = null;
    } else {
      focusManager.changeFocus(KEYBOARD);
    }
  };

  navMap.PAGE.internal.nextup = () => {
    setIsCoreMenuOpen(true);
    focusManager.changeFocus(CORE_MENU);
  };

  const searchCarousel = useSelector(selectSearchLandingCarousel);

  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const searchService = ServiceFactory.getInstance(
    SearchService,
  ) as SearchService;

  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchData, setSearchData] = useState<any>([]);
  const [recentData, setRecentData] = useState<any>([]);
  const [animation, setAnimation] = useState(true);
  const forwardFocus = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const text = location.pathname
      .split('/')
      .join(' ')
      .replace('query', 'search');
    ttsPlayback(
      `You are currently at:${text}. Search Artist, Channel or Show.`,
    );
  }, [location.pathname]);

  const onClearClick = async () => {
    await searchService.clearSearchResults().subscribe(value => {
      if (value) {
        setRecentData([]);
        focusManager.changeFocus(KEYWORD_BOX);
      }
    });
  };

  const onResultClick = () => {
    dispatch(
      saveLastFocusedTileId({
        ...lastFocusedTile,
        [navMap.PAGE.id]: `${navMap.KEYWORD_BOX.id}-${inputValue}`,
      }),
    );
    history.push(`/search-result/${encodeURIComponent(inputValue)}`);
  };

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[navMap.PAGE.id] && history.action === 'POP') {
      if (lastFocusedTile[navMap.PAGE.id].includes(navMap.KEYWORD_BOX.id)) {
        const inputValue = lastFocusedTile[navMap.PAGE.id].split('-');
        setInputValue(inputValue[1]);
        focusManager.changeFocus(navMap.KEYWORD_BOX.id);
        dispatch(
          saveLastFocusedTileId({ ...lastFocusedTile, [navMap.PAGE.id]: null }),
        );
      } else if (lastFocusedTile[navMap.PAGE.id].includes('GRID')) {
        const inputValue = lastFocusedTile[navMap.PAGE.id].split('/');
        setInputValue(inputValue[1]);
        focusManager.changeFocus(navMap.RESULT_LIST.id);
        dispatch(
          saveLastFocusedTileId({ ...lastFocusedTile, [navMap.PAGE.id]: null }),
        );
      } else {
        focusManager.changeFocus(lastFocusedTile[navMap.PAGE.id]);
        dispatch(
          saveLastFocusedTileId({ ...lastFocusedTile, [navMap.PAGE.id]: null }),
        );
      }
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  const onVirtualKeyClick = (text: string) => {
    let currInput = inputValue;

    if (text === 'delete') {
      setInputValue(currInput.substring(0, currInput.length - 1));
    } else {
      if (text === 'space') {
        setInputValue((currInput += ' '));
      } else if (!(currInput.length > 20)) {
        setInputValue((currInput += text));
      }
    }
  };

  useEffect(() => {
    carouselStoreService.selectSearchLanding();
    dispatch(selectSearchCarousel(null));
    dispatch(selectSubCategoryCarousel(null));
  }, []);

  useEffect(() => {
    if (searchCarousel) {
      const landingCarouselData = searchCarousel.category?.tiles?.map(
        (el, i) => {
          return {
            ...el.subCategoryInfo,
            ...searchCarousel.category.tiles[i],
          };
        },
      );

      setData({
        carouselName: searchCarousel.category?.title?.textValue,
        ...{ landingCarouselData },
      });
    }
  }, [searchCarousel]);

  useEffect(() => {
    if (inputValue.length >= 3) {
      searchService.updateSearchKeyword(inputValue);
      searchService
        .getAutoCompleteSearchResults(inputValue)
        .subscribe(() => {});
    } else {
      if (searchData.length > 0) {
        setSearchData([]);
      }
    }
  }, [inputValue]);

  const recentSearchResults = useObservable(
    searchService.recentSearchResults,
  ) as any;

  useEffect(() => {
    if (recentSearchResults && recentSearchResults.length > 0) {
      const rawSearchData = parseSearchResults(recentSearchResults);

      rawSearchData.push({
        text: 'Clear Search History',
        type: itemType.CLEAR_ITEM,
        onClick: async () => onClearClick(),
      });

      setRecentData(rawSearchData);
    }
  }, [recentSearchResults]);

  useEffect(() => {
    combineLatest([
      searchService.directTuneSearchResults,
      searchService.autoCompleteSearchResults,
    ]).subscribe({
      next: ([directTuneSearchResults, autoCompleteSearchResults]) => {
        let tuneData = null;
        let searchData = null;

        if (directTuneSearchResults && directTuneSearchResults.length > 0) {
          tuneData = parseTuneResults(directTuneSearchResults);
        }

        if (autoCompleteSearchResults && autoCompleteSearchResults.length > 0) {
          searchData = parseSearchResults(autoCompleteSearchResults);
        }

        if (tuneData && searchData) {
          setSearchData([...tuneData, ...searchData]);
        } else if (tuneData) {
          setSearchData(tuneData);
        } else if (searchData) {
          setSearchData(searchData);
        } else {
          setSearchData([]);
        }
      },
    });
  }, []);

  const match = useRouteMatch();

  useEffect(() => {
    if (match && match.params) {
      const { query } = match.params as any;
      if (query) {
        setInputValue(query);
      }
    }
  }, []);

  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    lastInternalFocusedTile = focusManager.getCurrentFocus();
    setIsCoreMenuOpen(true);
  }, [backId]);

  useUpdateEffect(() => {
    focusManager.changeFocus(CORE_MENU);
  }, [isCoreMenuOpen]);

  useEffect(() => {
    lastInternalFocusedTile = null;
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (inputValue) {
      ttsPlayback(`${inputValue}`);
    }
  }, [inputValue]);

  if (!data) {
    return null;
  }

  return (
    <div className={styles['search-container']}>
      <div
        className={`${styles['zone-menu']} ${
          !isCoreMenuOpen ? styles['core-menu-closed'] : ''
        }`}
      >
        <>
          <div className={styles['zone-menu-icon-container']}>
            <SearchIcon className={styles['zone-menu-icon']} />
          </div>
          <Input
            className={styles['zone-menu-label']}
            value={inputValue}
            placeholder={'Search Artist, Channel or Show'}
          />
        </>
      </div>

      <FocusDiv
        nav={{ ...navMap.PAGE }}
        className={styles['main-container']}
        onFocus={() => {
          onSearchPageFocus();
        }}
      >
        <div className={styles['keyboard']}>
          <Keyboard
            nav={{
              ...navMap.KEYBOARD,
              nextdown: inputValue.length > 0 ? KEYWORD_BOX : LANDING_CAROUSEL,
            }}
            onVirtualKeyClick={onVirtualKeyClick}
            isEmail={false}
          />
        </div>

        {data &&
          data.landingCarouselData?.length > 0 &&
          inputValue.length === 0 && (
            <div
              className={`${
                isCoreMenuOpen
                  ? styles['carousel-upper']
                  : styles['carousel-lower']
              }`}
            >
              <Swimlane
                pageId={navMap.PAGE.id}
                key={navMap.LANDING_CAROUSEL.id}
                nav={{
                  ...navMap.LANDING_CAROUSEL,
                  parent: navMap.PAGE.id,
                }}
                className={styles['carousel-wrapper']}
                headerClassName={styles['carousel-categories-header']}
                itemWrapperClassName={'category-wrapper'}
                displayText={data.carouselName}
                data={data.landingCarouselData}
                component={CategoryTile}
                shouldScroll={false}
                animation={animation}
                onFocus={() => {
                  if (!animation) {
                    verticalScroll(navMap.LANDING_CAROUSEL.id);
                  }
                }}
              />
            </div>
          )}

        {inputValue.length > 0 && (
          <>
            <ResultItem
              onClick={() => onResultClick()}
              nav={{
                ...navMap.KEYWORD_BOX,
                nextdown:
                  (searchData.length > 0 && inputValue.length >= 3) ||
                  (recentData.length > 0 && inputValue.length < 3) ||
                  (inputValue.length < 3 && searchData.length > 0)
                    ? RESULT_LIST
                    : '',
              }}
              className={styles['keyword-box']}
              classNameFocused={styles['keyword-item-focused']}
              classNameIcon={styles['result-icon']}
              classNameText={styles['keyword']}
              item={{
                text: inputValue,
                src: SearchIconResult,
                value: '',
              }}
            />

            {((searchData.length > 0 && inputValue.length >= 3) ||
              (recentData.length > 0 && inputValue.length < 3)) && (
              <>
                <span className={styles['result-list-title']}>
                  {inputValue.length >= 3
                    ? 'Suggested Searches'
                    : 'Recent searches'}
                </span>

                <Grid
                  pageId={navMap.PAGE.id}
                  nav={{
                    ...navMap.RESULT_LIST,
                    useLastFocus: true,
                  }}
                  isVertical={true}
                  data={inputValue.length >= 3 ? searchData : recentData}
                  component={SearchResultTile}
                  classNameItemWrapper={''}
                  maxItemsRow={4}
                  forwardFocus={forwardFocus.current}
                  animation={animation}
                  onWrapperClick={id => {
                    dispatch(
                      saveLastFocusedTileId({
                        ...lastFocusedTile,
                        [navMap.PAGE.id]: `${id}/${inputValue}`,
                      }),
                    );
                  }}
                  onFocus={() => {
                    forwardFocus.current = null;
                  }}
                  wrapperTitle={
                    inputValue.length >= 3
                      ? 'Suggested Searches'
                      : 'Recent searches'
                  }
                  wrapperSubTitle="Text To Speech:"
                />
              </>
            )}

            {inputValue.length >= 3 && searchData.length === 0 && (
              <div className={styles['search-error']}>
                <span className={styles['search-error-title']}>
                  No Results found for “{inputValue}”
                </span>
                <span className={styles['search-error-body']}>
                  Try searching again by channel, show, topics, hosts, or
                  guests.
                </span>
              </div>
            )}
          </>
        )}
      </FocusDiv>
    </div>
  );
};
