import { tts } from '@accedo/xdk-extensions-contrib';
import workstation, { ID as WORKSTATION } from '@accedo/xdk-device-workstation';
import lgWebos from '@accedo/xdk-device-lg-webos';
import tizen, { ID as TIZEN } from '@accedo/xdk-device-samsung-tizen';
import vizio, { ID as VIZIO } from '@accedo/xdk-device-vizio-smartcast-contrib';
const { html5: html5TtsConfig, vizio: vizioTtsConfig } = tts;

const CONFIG = {
  devices: {
    packages: [workstation, lgWebos, tizen, vizio],
    details: {
      [WORKSTATION]: {
        extensions: [html5TtsConfig],
      },
      [VIZIO]: {
        extensions: [vizioTtsConfig],
      },
      [TIZEN]: {
        extensions: [html5TtsConfig],
      },
    },
  },
  storages: {
    cookie: {
      name: 'sirius-xm-x1-cloud-cookie',
    },
  },
  logging: {
    level: -1,
  },
};

export default CONFIG;
